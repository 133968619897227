import React, { useContext, useEffect, useState } from 'react';
import { useError } from '@avon/component-library';
import SkeletonLoader from '../SkeletonLoader';
import LmaContext from '../../LmaContext';
import Profile from '../Profile';
import { getAllData } from '../../../utils/api';

const ProfileContainer = () => {
  const [profile, setProfile] = useState();
  const [{
    apptId, callApi, editAppointment, market,
  }] = useContext(LmaContext);
  const { setError, cleanToastBanner } = useError();
  useEffect(() => {
    async function fetchData() {
      try {
        const data = await callApi(getAllData(apptId));

        const {
          sl, rep, resendSMSBySL, resendVerifyEmail, link, reversible, currentUser,
        } = data;

        const actionFunctions = { resendSMSBySL, resendVerifyEmail };
        const { name, surName, mddlNm } = data.rep.contactDetails;

        let fullName = '';

        const nameArray = market === 'RU'
          ? [surName, name, mddlNm].filter(exists => exists)
          : [name, mddlNm, surName].filter(exists => exists);
        // if (nameArray.length > 2) {
        //   nameArray.splice(1, 1);
        // }
        if (reversible) {
          fullName = nameArray.reverse().join(' ');
        } else {
          fullName = nameArray.join(' ');
        }

        setProfile({
          rep, actionFunctions, fullName, link, name, surName, mddlNm, currentUser,
        });

        cleanToastBanner();

        // hanlde the redirect when the appt has not accept legal cert yet
        if (sl) {
          if (!sl.acceptLegalCert) {
            editAppointment(apptId, 'acceptLegalCert');
          }
        }
      } catch ({ error }) {
        if (error) {
          error.reCall = fetchData;
          error.args = [];
          setError(error);
        }
      }
    }
    fetchData();
  }, [apptId, callApi, setError]);

  return (
    <>
      {profile ? (<Profile {...profile} />) : <SkeletonLoader />}
    </>
  );
};

export default ProfileContainer;
