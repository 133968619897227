import React, { useCallback, useContext, useState } from 'react';
import {
  Button, Typography, useError, StyledRadio,
} from '@avon/component-library';
import {
  arrayOf, bool, func, object, shape, string,
} from 'prop-types';
import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/styles/makeStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';

import LmaContext from '../../LmaContext';
import { isMobile } from '../../../utils/getDeviceType';

const useStyles = makeStyles(() => ({
  radioGroups: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    fontWeight: '700',
    marginLeft: styleProps => (styleProps.language === 'ar' ? 'unset' : '3px'),
    marginRight: styleProps => (styleProps.language === 'ar' ? '3px' : 'unset'),
  },
  dialogContent: {
    overflowX: 'hidden',
  },
}));

const RejectModalBody = ({
  heading,
  description,
  saveButton,
  cancelButton: { label: cancelLabel, priority: cancelPriority },
  closeModal,
  reasonInfo,
  handleSubmit,
}) => {
  const isWebApp = sessionStorage.getItem('isWebApp') && !isMobile();
  const [reason, setReason] = useState(reasonInfo.find(({ checked }) => checked).value);
  const [isSubmitting, setSubmitting] = useState(false);
  const { setError, cleanToastBanner } = useError();

  const changeReason = useCallback(({ target: { value } }) => {
    setReason(value);
  }, [setReason]);

  const [{
    callApi, apptId, language,
  }] = useContext(LmaContext);

  const classes = useStyles({ language });

  const rejectAppointment = useCallback(async () => {
    if (!isSubmitting) {
      setSubmitting(true);
      const { uri, method } = saveButton.api;
      try {
        const values = {
          apptId,
          rep: {
            subStusCd: reason,
          },
        };
        await callApi({
          endpoint: uri,
          method,
          data: values,
        });
        cleanToastBanner();
        handleSubmit();
      } catch ({ error }) {
        setSubmitting(false);
        error.reCall = rejectAppointment;
        error.args = [];
        setError(error);
      }
    }
    // eslint-disable-next-line
  }, [reason, isSubmitting]);

  return (
    <>
      <DialogTitle id="form-dialog-title">
        <Box textAlign="center" m={3}>
          <Typography style={{ fontSize: 22, fontFamily: 'Montserrat-Medium' }}>
            {heading}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box textAlign="center">{description}</Box>
        <FormControl component="fieldset" fullWidth>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            id="gender1"
            value={reason}
            onChange={changeReason}
          >
            {
              reasonInfo.map(({ label, value }) => (
                <FormControlLabel
                  value={value}
                  control={<StyledRadio iconSize="20px" />}
                  label={label}
                  labelPlacement="start"
                  className={classes.radioGroups}
                  key={value}
                />
              ))
            }
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <Box style={isWebApp ? {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 20,
      } : {}}
      >
        {
          saveButton && (
            <DialogActions style={isWebApp ? { flex: 1 } : {}}>
              <Button name="lma-rej-modal-save" id="lma-rej-modal-save" onClick={rejectAppointment} loading={isSubmitting} priority={saveButton.priority} fullwidth>
                {saveButton.label}
              </Button>
            </DialogActions>
          )
        }
        <DialogActions style={isWebApp ? { flex: 1 } : {}}>
          <Button name="lma-rej-modal-close" id="lma-rej-modal-close" onClick={closeModal} priority={cancelPriority} fullwidth>
            {cancelLabel}
          </Button>
        </DialogActions>
      </Box>
    </>
  );
};

RejectModalBody.propTypes = {
  heading: string,
  description: string,
  saveButton: object,
  cancelButton: object,
  closeModal: func,
  handleSubmit: func,
  reasonInfo: arrayOf(
    shape({
      label: string,
      value: string,
      checked: bool,
    }),
  ),
};

export default RejectModalBody;
