import React, {
  useContext, useState, useEffect, useMemo,
} from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Typography, AvonIcon } from '@avon/component-library';
import {
  object, string, func, bool,
} from 'prop-types';
import dayjs from 'dayjs';
import Timer from '../Timer';
import LmaContext from '../../LmaContext';
import { isMobile, isDesktop } from '../../../utils/getDeviceType';

const MAX_SAFE = 1000000000;
const EXPIRING_TIMEOUT = 60000;

const msRemaining = stusEndDt => dayjs(stusEndDt) - dayjs();

const checkTime = (stusEndDt, targetTime) => (
  !stusEndDt ? false : (msRemaining(stusEndDt) <= targetTime)
);

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    display: 'flex',
    border: `1px solid ${theme.palette.colors.zircon}`,
    borderRadius: '5px',
    boxShadow: 'none',
    alignItems: 'stretch',
    backgroundColor: '#ffffff',
    flexDirection: e => (e.language === 'ar' ? 'row-reverse' : 'row'),
    '&:hover': {
      cursor: styleProps => (styleProps.isExpired ? 'default' : 'pointer'),
    },
  },
  webCard: {
    width: '100%',
    display: 'flex',
    border: `1px solid ${theme.palette.colors.zircon}`,
    borderRadius: '6px',
    boxShadow: 'none',
    alignItems: 'stretch',
    backgroundColor: '#ffffff',
    padding: 18,
    fontFamily: 'Montserrat',
    height: isDesktop() ? 160 : 154,
  },
  nameContainer: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: `${theme.spacing(4)}px`,
    overflowWrap: 'break-word',
  },
  contactDetails: {
    paddingBottom: `${theme.spacing(1)}px`,
    cursor: 'pointer',
    textAlign: e => (e.language === 'ar' ? 'right' : 'left'),
  },
  timerContainer: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderLeft: styleProps => (styleProps.language === 'ar' ? 'unset' : `1px solid ${theme.palette.colors.zircon}`),
    borderRight: styleProps => (styleProps.language === 'ar' ? `1px solid ${theme.palette.colors.zircon}` : 'unset'),
  },
  name: {
    fontSize: 16,
  },
}));


const RepCard = ({
  apptId,
  rep: {
    contactDetails: { name, mddlNm, surName },
    stusCd,
    stusEndDtStr,
    stusLabel,
    leadTypeLabel: leadType,
    leadSourceLabel: leadSource,
    remainingTimeLabel,
  },
  reversible,
  setFadeGrey,
}) => {
  const [{
    statusColors,
    language,
    market,
    leadsContent: {
      seeLead,
      repCard: {
        timerLabelMap,
        statusLabel,
        expiredLabel,
        leadTypeLabel,
        leadSourceLabel,
      },
    },
  }] = useContext(LmaContext);
  const isWebApp = sessionStorage.getItem('isWebApp') && !isMobile();
  const [isExpired, setIsExpired] = useState(checkTime(stusEndDtStr, 0));
  const [isExpiring, setIsExpiring] = useState(checkTime(stusEndDtStr, EXPIRING_TIMEOUT));
  const classes = useStyles({ language, isExpired });
  const statusColor = useMemo(() => statusColors[stusCd], [isExpired, stusCd, statusColors]);
  const theme = useTheme();
  const setTimer = () => {
    const remaining = msRemaining(stusEndDtStr);
    return setTimeout(() => {
      if (isExpiring) {
        setFadeGrey(true);
        setIsExpired(true);
      } else if (remaining < EXPIRING_TIMEOUT) {
        setIsExpiring(true);
      } else {
        setTimer();
      }
    }, (Math.min(MAX_SAFE, remaining - (isExpiring ? 0 : EXPIRING_TIMEOUT))));
  };

  useEffect(() => {
    if (stusEndDtStr) {
      const t = setTimer();
      return () => clearTimeout(t);
    }
    return () => { };
  }, [stusEndDtStr, isExpiring]);


  const contactDetails = [
    {
      label: [statusLabel, { color: isExpired ? '#A2ACAE' : '#181818' }],
      value: [isExpired ? expiredLabel : stusLabel, { color: statusColor, fontFamily: 'Montserrat', fontWeight: 700 }],
    },
    {
      label: [leadTypeLabel, { color: isExpired ? '#A2ACAE' : '#181818' }],
      value: [leadType, { color: isExpired ? '#A2ACAE' : '#181818' }],
    },

    {
      label: [leadSourceLabel, { color: isExpired ? '#A2ACAE' : '#181818' }],
      value: [leadSource, { color: isExpired ? '#A2ACAE' : '#181818' }],
    },
  ];

  const expiringMessage = useMemo(() => (
    <React.Fragment>
      <Typography component="span" variant="h4" weight="regular" style={{ color: statusColor }}>{'<1'}</Typography>
      <Typography component="span" variant="body2" weight="regular" style={{ color: statusColor }}>{timerLabelMap.minute}</Typography>
    </React.Fragment>
  ), [timerLabelMap.minute]);

  return (
    <React.Fragment>
      <Card
        pb={2}
        className={isWebApp ? classes.webCard : classes.card}
        data-testid="rep-card"
        id={`rep-card-${apptId}`}
      >
        {
          !isWebApp ? (
            <>
              <Box className={classes.nameContainer}>
                <Typography
                  style={{ color: isExpired ? '#A2ACAE' : '#181818' }}
                  weight="bold"
                  classes={{ root: classes.contactDetails }}
                >
                  {
                    reversible ? (
                      <>
                        <span className={classes.name}>{[surName, mddlNm].join(' ')}</span>
                        <span className={classes.name} style={{ display: 'block' }}>{name}</span>
                        {/* <span className={classes.name}>{
                          // eslint-disable-next-line
                          market === 'RU' ?
                            [mddlNm, name, surName].join(' ') :
                            [surName, mddlNm, name].join(' ')
                        }</span> */}
                      </>
                    ) : (
                      <>
                        <span className={classes.name} style={{ display: 'block' }}>{name}</span>
                        <span className={classes.name}>{[mddlNm, surName].join(' ')}</span>
                        {/* <span className={classes.name}>{
                          // eslint-disable-next-line
                          market === 'RU' ?
                            [surName, name, mddlNm].join(' ') :
                            [name, mddlNm, surName].join(' ')
                        }</span> */}
                      </>
                    )
                  }
                </Typography>
                {/* eslint-disable-next-line */}
                {contactDetails.map(({ label: [label, labelStyle], value: [value, valueStyle] }) => (
                  <Typography
                    key={`${label}-details-block`}
                    variant="body1"
                    classes={{ root: classes.contactDetails }}
                  >
                    <span style={labelStyle}>{`${label}: `}</span>
                    <span style={valueStyle}>
                      {value}
                    </span>
                  </Typography>
                ))}

              </Box>
              <Box flex="1" px={1} className={classes.timerContainer}>
                <Box mb={2}>
                  <AvonIcon
                    color={statusColor}
                    type="clock"
                    alt="clock-icon"
                    height="36px"
                    width="36px"
                  />
                </Box>
                {!isExpired && (
                  <Box mb={1} data-testid="timer" style={{ color: statusColor }}>
                    {!isExpiring ? (
                      <Timer
                        formatArray={['day', 'hour', 'minute']}
                        timerLabelMap={timerLabelMap}
                        countdown={msRemaining(stusEndDtStr)}
                        statusColor={statusColor}
                      />
                    ) : expiringMessage
                    }
                  </Box>
                )}
                <Box px={3} color={statusColors.expired}>
                  <Typography style={{ fontFamily: 'Montserrat', fontSize: 14, color: isExpired ? '#A2ACAE' : '#9cacb2' }} align="center">
                    {isExpired ? expiredLabel : remainingTimeLabel}
                  </Typography>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box flex={1} width="calc(100% - 161px)">
                <Box style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: 24,
                }}
                >
                  <Box style={{
                    padding: '4px 10px',
                    background: ['in_progress', 'awaiting_acceptance'].indexOf(stusLabel) !== -1
                      ? '#E4EDED'
                      : '#BAD9D9',
                    borderRadius: 10,
                    fontSize: 12,
                    lineHeight: '12px',
                    color: ['in_progress', 'awaiting_acceptance'].indexOf(stusLabel) !== -1
                      ? '#293132'
                      : '#2A3132',
                  }}
                  >
                    {isExpired ? expiredLabel : stusLabel}
                  </Box>
                  <Box style={{
                    fontFamily: 'Montserrat-Bold',
                    fontWeight: 700,
                    fontSize: 16,
                    lineHeight: '24px',
                    color: theme.palette.colors.corePink,
                    cursor: 'pointer',
                  }}
                  >
                    {seeLead}
                  </Box>
                </Box>
                <Box style={{
                  margin: '12px 0',
                  fontFamily: 'Montserrat',
                  fontWeight: 900,
                  fontSize: 20,
                  lineHeight: '28px',
                  color: '#181818',
                  cursor: 'pointer',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  height: 28,
                  whiteSpace: 'nowrap',
                }}
                >
                  {
                    // eslint-disable-next-line
                    (['RU', 'PH', 'HU'].indexOf(market) !== -1) ? (
                      market === 'RU' ? [surName, name, mddlNm].join(' ') : [surName, name].join(' ')
                    ) : [name, surName].join(' ')
                  }
                </Box>
                <Box style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  fontSize: 16,
                  // lineHeight: '24px',
                  color: '#707677',
                  cursor: 'pointer',
                  height: '48px',
                }}
                >
                  <Box flex={1}>
                    {leadTypeLabel}
                    :
                    {' '}
                    {leadType}
                  </Box>
                  <Box flex={1}>
                    {leadSourceLabel}
                    :
                    {' '}
                    {leadSource}
                  </Box>
                  <Box flex={1} visibility="hidden">Address: 2 King’s Bench walk, London, WC1N 2HY</Box>
                </Box>
              </Box>
              <Box style={{
                marginLeft: 18,
                padding: '8px 0 8px 18px',
                borderLeft: '1px solid #E4EDED',
                width: 124,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                boxSizing: 'content-box',
                textAlign: 'center',
                cursor: 'pointer',
              }}
              >
                <Box style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 36,
                }}
                >
                  <AvonIcon
                    color="#181818"
                    type="clock"
                    alt="clock-icon"
                    height="36px"
                    width="36px"
                  />
                </Box>
                {!isExpired && (
                  <Box mb="5px" mt="5px" data-testid="timer" style={{ color: '#181818' }}>
                    {!isExpiring ? (
                      <Timer
                        formatArray={['day', 'hour', 'minute']}
                        timerLabelMap={timerLabelMap}
                        countdown={msRemaining(stusEndDtStr)}
                        statusColor="#181818"
                      />
                    ) : expiringMessage
                    }
                  </Box>
                )}
                <Box style={{
                  fontSize: 14,
                  lineHeight: '20px',
                  textAlign: 'center',
                  color: '#A2ACAE',
                }}
                >
                  {isExpired ? expiredLabel : remainingTimeLabel}
                </Box>
              </Box>
            </>
          )
        }

      </Card>
    </React.Fragment>
  );
};

export default RepCard;

RepCard.propTypes = {
  apptId: string,
  rep: object,
  reversible: bool,
  setFadeGrey: func,
};
