import {
  string, shape,
} from 'prop-types';
import Lma from './Lma';
import getUrlParams from '../utils/getUrlParams';
import withCallApi from '../utils/withCallApi';

const LmaPage = ({
  location: { search },

}) => {
  const {
    apptId, status,
  } = getUrlParams(search);
  // eslint-disable-next-line
  let singleApptId = apptId;
  if (typeof apptId === 'object' && apptId && apptId.length > 1) {
    // eslint-disable-next-line
    singleApptId = apptId[0];
  }
  return withCallApi(Lma, search, { apptId: singleApptId, status });
};

LmaPage.propTypes = {
  location: shape({
    search: string.isRequired,
  }).isRequired,
};

export default LmaPage;
