import React, { Fragment } from 'react';
import { InputField, Typography, AvonIcon } from '@avon/component-library';
import makeStyles from '@material-ui/styles/makeStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import useTheme from '@material-ui/styles/useTheme';
import {
  func, object, shape, string, arrayOf,
} from 'prop-types';
import { isMobile } from '../../../../utils/getDeviceType';


const get = (data, location) => location.split('.').reduce((previous = {}, key) => previous[key], data);

const useStyles = makeStyles(() => ({
  list: e => (e.isWebApp ? {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    boxShadow: 'none',
  } : {
    boxShadow: 'none',
    borderRadius: '4px',
    border: '1px solid #a2acae',
    padding: 0,
    marginTop: '18px',
  }),
  listItem: e => (e.isWebApp ? {
    border: '1px solid #a2acae',
    borderBottom: 'none',
    '&:nth-child(1), &:nth-child(2)': {
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
    },
    '&:nth-last-child(1), &:nth-last-child(2)': {
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
      borderBottom: '1px solid #a2acae',
    },
    '&:nth-child(odd)': {
      marginRight: e.language === 'ar' ? 'unset' : 12,
      marginLeft: e.language === 'ar' ? 12 : 'unset',
      flexBasis: 'calc(50% - 12px)',
    },
    '&:nth-child(even)': {
      marginLeft: e.language === 'ar' ? 'unset' : 12,
      marginRight: e.language === 'ar' ? 12 : 'unset',
      flexBasis: 'calc(50% - 12px)',
    },

  } : {
    padding: '12px 20px',
    flexDirection: 'column',
    '& input': {
      fontSize: '16px',
    },
  }),
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  divider: {
    color: '#a2acae',
  },
  avonInput: {
    textAlign: 'left',
    paddingRight: '12px',
    marginLeft: '18px',
    fontFamily: 'Montserrat',
  },
  errorInput: {
    background: '#6d08540D',
  },
}));

const SocialList = ({
  field: {
    onBlur, onChange, value: values, name: prefix,
  },
  form: {
    touched, errors, setFieldValue, setFieldTouched,
  },
  setPreviewShow,
  language,
  items,
  ...props
}) => {
  const isWebApp = sessionStorage.getItem('isWebApp') && !isMobile();
  const classes = useStyles({ isWebApp, language });
  const theme = useTheme();
  const onInputChange = (e) => {
    if (typeof setPreviewShow === 'function') {
      setPreviewShow(false);
    }
    onChange(e);
  };


  return (
    <List className={classes.list}>
      {
        items.map(({ name, type, placeholder }) => {
          const prefixedName = `${prefix}.${name}`;
          const errorDisplay = get(touched, prefixedName) && get(errors, prefixedName);
          const toggleIconState = values[name] ? 'remove' : 'add';

          return (
            <Fragment key={`social-item-${name}`}>
              <ListItem className={classes.listItem}>
                <Box className={classes.inputContainer}>
                  <AvonIcon
                    type={type}
                    alt={`${type}-icon`}
                    id={`social-item-${type}-icon`}
                    color={theme.palette.colors.textMedium}
                    height="22px"
                    width="22px"
                  />
                  <InputField
                    id={`prpman-AvonInputField-${name}`}
                    onChange={onInputChange}
                    onBlur={onBlur}
                    name={prefixedName}
                    touched={(!!get(touched, prefixedName)).toString()}
                    value={values[name]}
                    placeholder={placeholder}
                    classes={{ input: [classes.avonInput, errorDisplay ? classes.errorInput : null].join(' ') }}
                    disableUnderline
                    fullWidth
                    {...props}
                  />
                  <AvonIcon
                    type={toggleIconState}
                    alt={`${type}-${toggleIconState}-icon`}
                    id={`${type}-${toggleIconState}-icon`}
                    height="22px"
                    width="22px"
                    onClick={() => {
                      document.getElementById(`prpman-AvonInputField-${name}`).focus();
                      setFieldValue(prefixedName, '');
                      setFieldTouched(prefixedName, false);
                      if (typeof setPreviewShow === 'function') {
                        setPreviewShow(false);
                      }
                    }}
                    color={theme.palette.colors.corePink}
                  />
                </Box>
                {errorDisplay && (
                  <Box width="100%" pl={9} mt={2}>
                    <Typography data-testid="social-error" variant="body2" weight="bold" color="error">{errorDisplay}</Typography>
                  </Box>
                )}
              </ListItem>
              { !isWebApp && (<Divider className={classes.divider} />)}
            </Fragment>
          );
        })
      }
    </List>
  );
};

SocialList.propTypes = {
  field: shape({
    onBlur: func.isRequired,
    onChange: func.isRequired,
  }),
  form: shape({
    errors: object.isRequired,
    touched: object.isRequired,
  }),
  items: arrayOf(shape({
    type: string,
    placeholder: string,
    initialValue: string,
  })),
  prefix: string,
  setPreviewShow: func,
  language: string,
};

export default SocialList;
