
import setPage from './setPage.reducer';
import setApiBody from './setApiBody.reducer';
import setProfile from './setProfile.reducer';
import setRecords from './setRecords.reducer';
import setHasNextPage from './setHasNextPage.reducer';
import setLeadsContent from './setLeadsContent.reducer';
import setPageCount from './setPageCount.reducer';
import setApptId from './setApptId.reducer';
import setSearch from './setSearch.reducer';
import setFilters from './setFilters.reducer';
import setSort from './setSort.reducer';

const setSpinner = (state, action) => action.spinner || state;
const setFirstFetch = (state, action) => action.firstFetch || state;
const setSortOrFilter = (state, action) => action.sortOrFilter || state;

const mainReducer = (state, action) => {
  const {
    apptId,
    profile,
    page,
    apiBody,
    records,
    hasNextPage,
    leadsContent,
    pageCount,
    search,
    filters,
    sort,
    spinner,
    sortOrFilter,
    firstFetch,
  } = state;
  return {
    ...state,
    hasNextPage: setHasNextPage(hasNextPage, action),
    page: setPage(page, action),
    leadsContent: setLeadsContent(leadsContent, action),
    apiBody: setApiBody(apiBody, action),
    profile: setProfile(profile, action),
    records: setRecords(records, action),
    pageCount: setPageCount(pageCount, action),
    apptId: setApptId(apptId, action),
    search: setSearch(search, action),
    filters: setFilters(filters, action),
    sort: setSort(sort, action),
    spinner: setSpinner(spinner, action),
    sortOrFilter: setSortOrFilter(sortOrFilter, action),
    firstFetch: setFirstFetch(firstFetch, action),
  };
};

export default mainReducer;
