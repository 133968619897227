import flatten from '../../../utils/flatten';

const getValidErrors = (validators, parentKey) => {
  if (parentKey) {
    // eslint-disable-next-line
    validators = validators[parentKey];
  }
  if (validators.length > 0) {
    const validErrors = validators.reverse().filter(
      ({ errorMessage, validationRegex }) => (!!errorMessage && !!validationRegex),
    );
    return validErrors.length ? validErrors : null;
  }
  return null;
};

const getErrors = ({ type, ...field }, mobileErrorsParent) => {
  const getters = {
    default: () => {
      const { errors = [], properties = {} } = field;
      if (properties.parentName && mobileErrorsParent) {
        return getValidErrors(errors, mobileErrorsParent);
      }
      return getValidErrors(errors);
    },
  };
  return getters[type] ? getters[type]() : getters.default();
};

// eslint-disable-next-line
export const getValidators = (fields = [], mobileErrorsParent) => Object.values(flatten(fields)).reduce(
  (acc, field) => {
    const { type, properties: { name } } = field;
    const validators = getErrors(field, mobileErrorsParent);
    if (validators) acc.validators[name] = validators;
    if (name) acc.types[name] = type;
    return acc;
  }, { validators: {}, types: {} },
);

const validate = (value = '', errors) => {
  const error = errors.find(({ validationRegex }) => !(new RegExp(validationRegex, 'i').test(value)));
  return error ? error.errorMessage : null;
};

const checkValidators = (value, validator, type) => {
  const checkers = {
    default: () => validate(value, validator),
  };

  const checker = checkers[type] ? checkers[type] : checkers.default;
  return checker(value, validator);
};


export const validateValues = (values = {},
  {
    validators = {},
    types = {},
  } = {}) => Object.entries(validators).reduce(
  (acc, [key, validator]) => {
    const errors = checkValidators(values[key], validator, types[key]);
    if (errors) acc[key] = errors;
    return acc;
  }, {},
);
