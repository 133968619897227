import {
  string, shape,
} from 'prop-types';
import Prospect from './Prospect';
import withCallApi from '../utils/withCallApi';
import onComplete from './utils/onComplete';

const props = {
  onComplete,
};

const ProspectPage = ({
  location: { search },
}) => withCallApi(Prospect, search, { ...props });

ProspectPage.propTypes = {
  location: shape({
    search: string.isRequired,
  }).isRequired,
};

export default ProspectPage;
