import React, {
  useCallback, useState, useEffect,
} from 'react';
import Box from '@material-ui/core/Box';
import { Divider, Typography } from '@material-ui/core/';
import {
  shape, func, string, object, bool,
} from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import useTheme from '@material-ui/styles/useTheme';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import AvonTooltip from '../../AvonTooltip';
import noop from '../../../../utils/noop';
import { isMobile } from '../../../../utils/getDeviceType';

const useStyles = makeStyles(theme => ({
  limited: {
    display: 'block',
    '& textarea': {
      fontSize: '14px',
      fontFamily: 'Montserrat',
      color: theme.palette.colors.textDark,
    },
  },
  typography: {
    '& p': {
      fontSize: 16,
      color: `${theme.palette.colors.corePink} !important`,
    },
  },
  typographyFocus: {
    '& p': {
      fontSize: 16,
      color: theme.palette.colors.corePink,
    },
  },
  typographyFocusAvailable: {
    '& p': {
      fontSize: 16,
      color: '#1a8282',
    },
  },
  toggleShow: {
    '& span': {
      cursor: 'pointer',
      color: `${theme.palette.colors.corePink} !important`,
      fontSize: '14px',
      fontFamily: 'Montserrat',
      fontWeight: 700,
    },
  },
  toggleShow1: {
    '& span': {
      cursor: 'pointer',
      color: `${theme.palette.colors.corePink} !important`,
      fontSize: '12px',
      fontFamily: 'Montserrat-Medium',
    },
    '& span:hover': {
      fontWeight: 'bold',
    },
  },
  boldText: {
    '&& b': {
      fontWeight: 700,
      fontFamily: 'Montserrat',
    },
  },
  formGroup: {
    display: 'inline',
    paddingRight: styleProps => (styleProps.language === 'ar' ? 'unset' : theme.spacing(2)),
    paddingLeft: styleProps => (styleProps.language === 'ar' ? theme.spacing(2) : 'unset'),
    justifyContent: 'space-between',
    color: theme.palette.colors.textMedium,
    '& span': {
      verticalAlign: 'middle',
    },
  },
  selected: {
    border: `1px solid ${theme.palette.colors.corePink}`,
    borderRadius: 16,
    margin: '0 12px 24px 0',
    padding: '17px 20px',
    height: 32,
    backgroundColor: theme.palette.colors.corePink,
    color: '#FFFFFF',
  },
  notSelected: {
    border: `1px solid ${theme.palette.colors.corePink}`,
    borderRadius: 16,
    margin: '0 12px 24px 0',
    padding: '17px 20px',
    height: 32,
    backgroundColor: '#FFFFFF',
    color: theme.palette.colors.corePink,
  },
  option: {
    fontFamily: 'Montserrat',
  },
  groupHeader: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '24px',
    },
  },
}));
const AvonCheckGroup = ({
  focusOn,
  language,
  callApi,
  setPreviewShow,
  field: { name },
  form: {
    setFieldValue,
    values,
  },
  label,
  style,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles();
  // const isMounted = useRef(true);
  const {
    priority, showTip, tipMsg, options, fontFamily, fontWeight, fontSize, color,
  } = { ...props };

  const [selects, setSelects] = useState([]);
  const [selectItems, setSelectItems] = useState({});

  useEffect(
    () => {
      const initSelected = {};
      if (values[name] && values[name].length > 0) {
        setSelects(values[name]);
        values[name].map(
          // eslint-disable-next-line
          select => initSelected[select] = true,
        );
      }
      setSelectItems(initSelected);
    },
    [],
  );

  const onChange = useCallback((checked, op) => {
    if (op === 'add') {
      selects.push(checked);
    }
    if (op === 'remove') {
      selects.splice(selects.findIndex(e => e === checked), 1);
    }
    setSelects(selects);
    setFieldValue(name, selects);

    if (typeof setPreviewShow === 'function') {
      setPreviewShow(false);
    }
  }, [setFieldValue, name, selects, selectItems]);

  const handleOptionChange = checked => (event) => {
    setSelectItems({ ...selectItems, [checked]: event.target.checked });
    onChange(checked, event.target.checked ? 'add' : 'remove');
  };

  const handleOptionChangeNew = (checked) => {
    const item = selectItems[checked];
    if (item !== undefined) {
      setSelectItems({ ...selectItems, [checked]: !item });
      onChange(checked, item ? 'remove' : 'add');
    } else {
      setSelectItems({ ...selectItems, [checked]: true });
      onChange(checked, 'add');
    }
  };

  return (
    <>
      <Box
        component="span"
          // eslint-disable-next-line
          className={classes.typography}
      >
        <Grid container id={`prpman-input-container-${name}`}>
          <Grid key="1" item xs={8}>
            <Box
              fontFamily={fontFamily || (priority === 'secondary' ? 'Montserrat' : 'Montserrat-Medium')}
              fontWeight={fontWeight || (priority === 'secondary' ? 'bold' : 'normal')}
              fontSize={fontSize || (priority === 'secondary' ? '16px' : '14px')}
              color={color || (priority === 'secondary' ? '#181818' : '#546264')}
            >
              <Box variant="body1" color="textPrimary" align="left" display="flex">
                {
                    showTip ? (
                      <Box
                        component="span"
                        mr={language === 'ar' ? 'unset' : 2}
                        ml={language === 'ar' ? 2 : 'unset'}
                      >
                        <AvonTooltip
                          position="top"
                          trigger="click"
                          arrow
                          tipMsg={tipMsg}
                          theme="light"
                          interactive
                          order={name}
                          language={language}
                          showDissmiss
                        >
                          <svg cursor="pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 14">
                            <g fill="none" fillRule="evenodd">
                              <path d="M-1-1h16v16H-1z" />
                              <path
                                fill={theme.palette.colors.corePink}
                                fillRule="nonzero"
                                d="M7 .333a6.667 6.667 0 1 1 0 13.334A6.667 6.667 0 0 1 7 .333zm0 1.334a5.333 5.333 0 1 0 0 10.666A5.333 5.333 0 0 0 7 1.667zM7 9a.667.667 0 1 1 0 1.333A.667.667 0 0 1 7 9zm0-5.333c1.104 0 2 .897 2 2 0 .616-.256 1.027-.776 1.51l-.38.338-.159.154c-.01.008-.014.01-.017.007l-.001-.007-.005.075a.667.667 0 0 1-1.329-.077c0-.43.198-.743.553-1.081l.11-.102.229-.201c.334-.295.442-.452.442-.616a.668.668 0 0 0-1.33-.078l-.004.078a.667.667 0 1 1-1.333 0 2 2 0 0 1 2-2z"
                              />
                            </g>
                          </svg>
                        </AvonTooltip>
                      </Box>
                    ) : null
                  }
                <Box pt={0.5} className={classes.groupHeader} id={`checkGroup-groupHeader-${name}`}>{label || name}</Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mt={3}>
        <Grid item xs={12}>
          <RadioGroup
            aria-label={`checkgroup-${label}`}
            id={`checkgroup-${label}`}
            name={name}
            value={selectItems}
            style={{ display: 'flex', flexDirection: style === 'new' ? 'row' : 'column' }}
          >
            {options.map(radio => (
              <Box key={radio.value}>
                <FormControlLabel
                  control={style === 'new'
                    ? (
                      <Typography
                        id={`${name}-filter-newcheckbox-${radio.value}`}
                        name={`${name}-filter-newcheckbox-${radio.value}`}
                        value={radio.value}
                        className={classes.option}
                      />
                    )
                    : (
                      <Checkbox
                        color="primary"
                        id={`${name}-filter-checkbox-${radio.value}`}
                        name={`${name}-filter-checkbox-${radio.value}`}
                        checked={selectItems[radio.value] || false}
                        onChange={handleOptionChange(radio.value)}
                        value={radio.value}
                      />
                    )}
                  data-testid={`${name}-filter-option-${radio.value}`}
                  id={`${name}-filter-option-${radio.value}`}
                  name={`${name}-filter-option`}
                  label={<span id={`${name}-filter-option--label-${radio.value}`}>{radio.radioName}</span>}
                  labelPlacement="end"
                  onClick={style === 'new' ? () => handleOptionChangeNew(radio.value) : noop}
                  // eslint-disable-next-line
                  className={style === 'new' ? (selectItems[radio.value]
                    ? classes.selected
                    : classes.notSelected) : classes.formGroup}
                />
              </Box>
            ))}
          </RadioGroup>
          {isMobile() && <Divider className={classes.divider} />}
        </Grid>
      </Box>
    </>
  );
};

AvonCheckGroup.propTypes = {
  setBannerShow1: func,
  focusOn: bool,
  setPreviewShow: func,
  field: shape({
    onBlur: func,
    onChange: func,
    name: string,
  }),
  form: shape({
    touched: object,
    errors: object,
    values: object,
  }),
  label: string,
  callApi: func,
  language: string,
  style: string,
};

export default AvonCheckGroup;
