import React, {
  useEffect, useState, useRef, useMemo,
} from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import useTheme from '@material-ui/styles/useTheme';
import {
  string, shape, array, number,
} from 'prop-types';
import BarChart from './BarChart';
import PieChart from './PieChart';
import VertBarChart from './VertBarChart';
import LineChart from './LineChart';
import Table from './Table';
import { genPlaceholderData } from './utils';

const map = {
  bar: BarChart,
  pie: PieChart,
  line: LineChart,
  vertbar: VertBarChart,
  table: Table,
};

const useStyles = makeStyles({
  '@keyframes fade': {
    from: { opacity: 1 },
    to: { opacity: 0.5 },
  },
  loading: {
    animation: '$fade 500ms infinite alternate cubic-bezier(0.4,0,0.2,1)',
  },
});

const DataElement = ({
  type, data, dataKeys, index, market, ...props
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const activeAnimation = useRef(false);
  const [elementData, setElementData] = useState(genPlaceholderData(type));

  const colorWarning = market === 'JT' ? '#F8AA0B' : theme.palette.colors.warning;
  const colorMain = market === 'JT' ? '#7F28C4' : theme.palette.primary.main;
  const colorError = market === 'JT' ? '#851168' : theme.palette.colors.error;

  const colors = useMemo(() => (data
    ? [colorWarning, colorMain, colorError]
    : [240, 220, 200, 180, 160].map(col => `rgb(${col},${col},${col})`)),
  [colorWarning, data, colorMain, colorError]);

  useEffect(() => {
    if (!activeAnimation.current) activeAnimation.current = true;
    let id;
    if (!data && type !== 'pie') {
      id = setTimeout(() => {
        setElementData(genPlaceholderData(type));
      }, 2000);
    }
    return () => {
      clearTimeout(id);
    };
  }, [elementData, data, type]);

  const Element = map[type];

  return (
    Element ? (
      <Box className={!data ? classes.loading : undefined}>
        <Element
          key={`${!data && 'loading-'}${type}-${index}`}
          loading={!data}
          data={data || elementData}
          colors={colors}
          dataKeys={data ? dataKeys : ['value_1', 'value_2']}
          activeAnimation={activeAnimation.current}
          animationDuration={data ? 1000 : 2000}
          {...props}
        />
      </Box>
    ) : null
  );
};

DataElement.propTypes = {
  type: string,
  dateRange: shape({
    from: string,
    to: string,
  }),
  index: number.isRequired,
  data: array,
  dataKeys: array,
  market: string,
};

export default DataElement;
