module.exports = {
  handler: window.navigator.userAgent.match(/(iPhone)|(iPad)|(Macintosh)/i)
    /* istanbul ignore next */
    ? (method, params = {}) => {
      /* istanbul ignore next */
      window.webkit.messageHandlers.avonRec.postMessage(`{"method": "${method}", "params": "${JSON.stringify(params).replace(/"/g, '\\"')}"}`);
    } : (method, params = {}) => {
      window.Android.dispatcher(method, JSON.stringify(params));
    },
};
