import React, { useState, useReducer, useEffect } from 'react';
import {
  bool, arrayOf, string, shape, func, oneOfType, number, exact,
} from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Collapse from '@material-ui/core/Collapse';
import { Typography, Button } from '@avon/component-library';
import TuneIcon from '@material-ui/icons/Tune';
import DateRange from '../../DateRange';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.colors.lightZircon,
    boxShadow: 'none',
  },
  modalButton: {
    width: 'auto',
    display: 'flex',
    margin: 'auto',
  },
  buttonIcon: {
    marginRight: styleProps => (styleProps.language === 'ar' ? 'unset' : theme.spacing(2)),
    marginLeft: styleProps => (styleProps.language === 'ar' ? theme.spacing(2) : 'unset'),
    height: '50%',
  },
  toolbar: { justifyContent: 'center' },
  formGroup: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    color: theme.palette.colors.textMedium,
    fontWeight: '700',
  },
  actionButton: {
    borderRadius: 0,
    margin: 0,
    '&:first-child': {
      color: theme.palette.colors.textLight,
    },
    '&:not(:first-child)': {
      borderLeft: styleProps => (styleProps.language === 'ar' ? 'unset' : `1px solid ${theme.palette.colors.zircon}`),
      borderRight: styleProps => (styleProps.language === 'ar' ? `1px solid ${theme.palette.colors.zircon}` : 'unset'),
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  collapse: {
    padding: '0px 30px',
  },
  modalDateRange: {
    textAlign: 'center',
  },
  actions: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: theme.palette.colors.lightZircon,
    boxShadow: '0 -2px 4px 0 rgba(225, 225, 225, 0.5)',
  },
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const MobileDateSelector = ({
  options, onChange, onExit, onEnter, displayDate, language, labels, market,
}) => {
  const classes = useStyles({ language });
  const [selection, setSelection] = useState('0');
  const [selected, setSelected] = useState('0');
  // eslint-disable-next-line
  const [open, setOpen] = useState(false);

  const updateOptionState = (state, { index, updateOptions }) => {
    const updatedState = [...state];
    updatedState[index] = { ...updatedState[index], ...updateOptions };
    return updatedState;
  };

  const [optionState, optionStateDispatch] = useReducer(updateOptionState, options);

  const handleOpen = () => (setOpen(true));

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setSelected(selection);
    setOpen(false);
  };

  useEffect(() => {
    onChange(optionState[selected].range);
  }, [selected]);

  const tomorrow = new Date();
  tomorrow.setHours(24, 0, 0);

  let dateVerification;

  if (new Date(optionState[selected].range.from) > new Date(optionState[selected].range.to)) {
    dateVerification = [undefined, labels.rangeError];
  }

  if (new Date(optionState[selected].range.to) > tomorrow) {
    dateVerification = [dateVerification ? dateVerification[1] : undefined, labels.rangeScopeError];
  }
  // eslint-disable-next-line
  if (isNaN(optionState[selected].range.from)) {
    // eslint-disable-next-line
    dateVerification = [labels.invalidDateError, dateVerification ? dateVerification[1] : undefined];
  }
  // eslint-disable-next-line
  if (isNaN(optionState[selected].range.to)) {
    // eslint-disable-next-line
    dateVerification = [dateVerification ? dateVerification[0] : undefined, labels.invalidDateError];
  }

  return (
    <div data-testid="mobileDateSelector">
      <Button name="stats-mob-date-selector-open" id="stats-mob-date-selector-open" priority="secondary" className={classes.modalButton} onClick={handleOpen}>
        <TuneIcon className={classes.buttonIcon} />
        {
          optionState[selected].modalChild
            ? `${displayDate(optionState[selected].range.from)} ${labels.dateConnector} ${displayDate(optionState[selected].range.to)}`
            : `${optionState[selected].label}`
        }
      </Button>
      <Dialog data-testid="modal-window" id="admin-modal-window" fullScreen open={open} onExit={onExit} onEnter={onEnter} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="body2" weight="bold" color="textPrimary">
              {labels.wrapper.toUpperCase()}
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          <FormControl component="fieldset" fullWidth>
            <RadioGroup
              aria-label="daterange"
              name="daterange"
              id="admin-daterange"
              value={selection}
              onChange={event => setSelection(event.target.value)}
            >
              {optionState.map((option, index) => (
                <React.Fragment key={index}>
                  <ListItem>
                    <FormControlLabel
                      data-testid="selector-option"
                      id={`admin-selector-option${index}`}
                      value={index.toString()}
                      className={classes.formGroup}
                      control={<Radio color="primary" />}
                      label={option.label}
                      labelPlacement="start"
                    />
                  </ListItem>
                  {
                    option.modalChild ? (
                      <Collapse className={classes.collapse} in={selection === index.toString()} timeout="auto">
                        <ListItem className={classes.modalDateRange}>
                          <DateRange
                            onChange={dateRange => optionStateDispatch({
                              updateOptions: {
                                range: dateRange,
                              },
                              index,
                            })
                            }
                            labels={{
                              from: labels.from,
                              to: labels.to,
                              cancel: labels.cancel,
                              confirm: labels.confirm,
                            }}
                            language={language}
                            market={market}
                            dateRange={optionState[index].range}
                            error={dateVerification}
                          />
                        </ListItem>
                      </Collapse>
                    ) : null
                  }
                  {index !== optionState.length - 1 ? <Divider /> : null}
                </React.Fragment>
              ))}
            </RadioGroup>
          </FormControl>
        </List>

        <DialogActions className={classes.actions}>
          <Button
            barButton
            id="admin-selector-cancel"
            name="selector-cancel"
            priority="tertiary"
            onClick={handleClose}
            language={language}
            className={classes.actionButton}
          >
            {labels.cancel}
          </Button>
          <Button
            barButton
            id="admin-selector-confirm"
            name="selector-confirm"
            priority="tertiary"
            onClick={handleConfirm}
            language={language}
            className={classes.actionButton}
            disabled={!!dateVerification}
          >
            {labels.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

MobileDateSelector.propTypes = {
  options: arrayOf(shape({
    label: string.isRequired,
    range: oneOfType(
      [shape({
        from: string,
        to: string,
      }),
      shape({
        from: number,
        to: number,
      })],
    ),
    modalChild: bool,
  })),
  labels: exact({
    wrapper: string,
    confirm: string,
    cancel: string,
    to: string,
    from: string,
    dateConnector: string,
    rangeError: string,
    rangeScopeError: string,
  }).isRequired,
  language: string.isRequired,
  displayDate: func.isRequired,
  onChange: func.isRequired,
  onExit: func,
  onEnter: func,
  market: string,
};


export default MobileDateSelector;
