import React, {
  useCallback, useState,
} from 'react';
import Box from '@material-ui/core/Box';
import { Divider } from '@material-ui/core/';
import {
  shape, func, string, object, bool,
} from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import { StyledRadio } from '@avon/component-library';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import AvonTooltip from '../../AvonTooltip';
import { isMobile } from '../../../../utils/getDeviceType';

const useStyles = makeStyles(theme => ({
  limited: {
    display: 'block',
    '& textarea': {
      fontSize: '14px',
      fontFamily: 'Montserrat',
      color: theme.palette.colors.textDark,
    },
  },
  typography: {
    '& p': {
      fontSize: 16,
      color: `${theme.palette.colors.corePink} !important`,
    },
  },
  typographyFocus: {
    '& p': {
      fontSize: 16,
      color: theme.palette.colors.corePink,
    },
  },
  typographyFocusAvailable: {
    '& p': {
      fontSize: 16,
      color: '#1a8282',
    },
  },
  toggleShow: {
    '& span': {
      cursor: 'pointer',
      color: `${theme.palette.colors.corePink} !important`,
      fontSize: '14px',
      fontFamily: 'Montserrat',
      fontWeight: 700,
    },
  },
  toggleShow1: {
    '& span': {
      cursor: 'pointer',
      color: `${theme.palette.colors.corePink} !important`,
      fontSize: '12px',
      fontFamily: 'Montserrat-Medium',
    },
    '& span:hover': {
      fontWeight: 'bold',
    },
  },
  boldText: {
    '&& b': {
      fontWeight: 700,
      fontFamily: 'Montserrat',
    },
  },
  formGroup: {
    display: 'inline',
    paddingRight: styleProps => (styleProps.language === 'ar' ? 'unset' : theme.spacing(2)),
    paddingLeft: styleProps => (styleProps.language === 'ar' ? theme.spacing(2) : 'unset'),
    justifyContent: 'space-between',
    color: theme.palette.colors.textMedium,
    '& span': {
      verticalAlign: 'middle',
    },
  },
  radioGroup: {
    display: p => (p.isMobile ? '-webkit-inline-box' : 'unset'),
  },
  groupHeader: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '24px',
    },
  },
}));
const AvonRadioGroup = ({
  focusOn,
  language,
  callApi,
  field: { onBlur, name },
  form: {
    setFieldValue,
  },
  label,
  ...props
}) => {
  const classes = useStyles({ isMobile: isMobile() });
  const theme = useTheme();
  // const isMounted = useRef(true);
  const {
    priority, showTip, tipMsg, options,
  } = { ...props };

  const [selected, setSelected] = useState();
  const onChange = useCallback(({ target: { value } }) => {
    setSelected(value);
    setFieldValue(name, value);
  }, [setFieldValue, name]);

  // const selected = useMemo(() => values[name], []);
  return (
    <>
      <Box
        component="span"
        // eslint-disable-next-line
        className={classes.typography}
      >
        <Grid container id={`prpman-input-container-${name}`}>
          <Grid key="1" item xs={8}>
            <Box
              fontFamily={priority === 'secondary' ? 'Montserrat' : 'Montserrat-Medium'}
              fontSize={priority === 'secondary' ? '16px' : '12px'}
              color={priority === 'secondary' ? '#181818' : '#546264'}
              fontWeight={priority === 'secondary' ? 'bold' : 'normal'}
            >
              <Box variant="body1" color="textPrimary" align="left" display="flex">
                {
                  showTip ? (
                    <Box
                      component="span"
                      mr={language === 'ar' ? 'unset' : 2}
                      ml={language === 'ar' ? 2 : 'unset'}
                    >
                      <AvonTooltip
                        position="top"
                        trigger="click"
                        arrow
                        tipMsg={tipMsg}
                        theme="light"
                        interactive
                        order={name}
                        language={language}
                        showDissmiss
                      >
                        <svg cursor="pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 14">
                          <g fill="none" fillRule="evenodd">
                            <path d="M-1-1h16v16H-1z" />
                            <path
                              fill={theme.palette.colors.corePink}
                              fillRule="nonzero"
                              d="M7 .333a6.667 6.667 0 1 1 0 13.334A6.667 6.667 0 0 1 7 .333zm0 1.334a5.333 5.333 0 1 0 0 10.666A5.333 5.333 0 0 0 7 1.667zM7 9a.667.667 0 1 1 0 1.333A.667.667 0 0 1 7 9zm0-5.333c1.104 0 2 .897 2 2 0 .616-.256 1.027-.776 1.51l-.38.338-.159.154c-.01.008-.014.01-.017.007l-.001-.007-.005.075a.667.667 0 0 1-1.329-.077c0-.43.198-.743.553-1.081l.11-.102.229-.201c.334-.295.442-.452.442-.616a.668.668 0 0 0-1.33-.078l-.004.078a.667.667 0 1 1-1.333 0 2 2 0 0 1 2-2z"
                            />
                          </g>
                        </svg>
                      </AvonTooltip>
                    </Box>
                  ) : null
                }
                <Box pt={0.5} className={classes.groupHeader} id={`radioGroup-groupHeader-${name}`}>{label || name}</Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mt={3}>
        <Grid item xs={12}>
          <RadioGroup
            aria-label="gender"
            id="selector-gender"
            name={name}
            className={classes.radioGroup}
            value={selected}
            onChange={onChange}
            onBlur={onBlur}
          >
            {options.map(field => (
              <Box key={field.value}>
                <Box pt={2} pb={2} style={{ display: 'flex', flexDirection: isMobile() ? 'row' : 'column' }}>
                  <FormControlLabel
                    data-testid="selector-option"
                    id={`selector-option-${field.value}`}
                    name={`selector-option-${field.value}`}
                    value={field.value}
                    label={<span id={`selector-option-label-${field.value}`}>{field.radioName}</span>}
                    className={classes.formGroup}
                    control={<StyledRadio iconSize="20px" id={`selector-option-checkbox-${field.value}`} />}
                    labelPlacement="end"
                  />
                </Box>
              </Box>
            ))}
          </RadioGroup>
          {isMobile() && <Divider className={classes.divider} />}
        </Grid>
      </Box>
    </>
  );
};

AvonRadioGroup.propTypes = {
  setBannerShow1: func,
  focusOn: bool,
  field: shape({
    onBlur: func,
    onChange: func,
    name: string,
  }),
  form: shape({
    touched: object,
    errors: object,
    values: object,
  }),
  label: string,
  callApi: func,
  language: string,
};

export default AvonRadioGroup;
