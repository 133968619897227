

export default search => search
  // remove questionmark from query string
  .replace('?', '')
  // split query string in single parameters (['key=value', 'key=value'])
  .split('&')
  // split each param in arrays ([[key, value], [key, value]])
  .map(param => param.split('='))
  // [['market', 'RU'], ['filter', 'status'], ['filter', 'age']]
  .reduce((obj, [key, value]) => ({
    ...obj,
    [key]: (
      (
        (obj[key]) && (
          Array.isArray(obj[key])
            ? [...obj[key], value]
            : [obj[key], value]
        ))
        || value
    ),
  }), {});
// create an object with each of them ([{key: value}, {key: value}])
// .map(([key, value]) => ({ [key]: value }));
// reduce each param in a single object ({key: value, key: value})
// .reduce((param, { key, value }) => ({ ...urlParams, ...param }), {});
