import axios from 'axios';
import { string } from 'prop-types';
import { getStringItem } from '../session-storage';
import {
  accessTokenKey,
} from '../config';

const extractJSON = response => response.data;

// eslint-disable-next-line no-underscore-dangle
const getAccessToken = () => getStringItem(accessTokenKey) || window._env_.REACT_APP_ACCESS_TOKEN;

const makeBaseApi = ({
  domain,
  market,
  channel,
  device,
  language,
  accessToken = getAccessToken(),
}) => ({
  endpoint,
  mapResult = x => (x),
  ...options
}) => axios({
  url: `${domain}/${market}/${channel}/${device}/${language}${endpoint}`,
  headers: {
    Authorization: `bearer ${accessToken}`,
    version: '1.18.0',
  },
  ...options,
})
  .then(extractJSON)
  .then(mapResult)
  .catch((error) => {
    throw mapResult({ error });
  });

makeBaseApi.propTypes = {
  market: string.isRequired,
  channel: string.isRequired,
  device: string.isRequired,
  language: string.isRequired,
  accessToken: string.isRequired,
};

export default makeBaseApi;
