import React from 'react';
import {
  ResponsiveContainer, LineChart, Line, Legend, Tooltip, CartesianGrid, XAxis, YAxis,
} from 'recharts';
import {
  arrayOf, object, string, number, shape, array, bool,
} from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  gridProps, legendProps, tooltipProps, xAxisProps, useYAxisProps,
} from './utils';

const useStyles = makeStyles(() => ({
  bars: {
    '@media (max-width: 360px)': {
      height: '310px',
    },
  },
  warp: {
    direction: 'ltr',
    '& > svg': {
      direction: styleProps => (styleProps.language === 'ar' ? 'rtl' : 'ltr'),
    },
    '& div > ul > li': {
      marginLeft: styleProps => (styleProps.language === 'ar' ? '10px' : '0px !important'),
      marginRight: styleProps => (styleProps.language === 'ar' ? '0px !important' : '10px'),
    },
    '& div > ul > li > svg': {
      marginLeft: styleProps => (styleProps.language === 'ar' ? '4px' : '0px !important'),
      marginRight: styleProps => (styleProps.language === 'ar' ? '0px !important' : '4px'),
    },
    '& [class*="recharts-tooltip-wrapper"]': {
      '@media (max-width: 345px)': {
        left: '-30px',
      },
      '@media (max-width: 320px)': {
        left: '-40px',
      },
    },
  },
}));

const Chart = ({
  data, dataKeys, height, margin, colors, loading,
  activeAnimation = true, animationDuration, language,
}) => {
  const classes = useStyles({ language });
  const tipProps = tooltipProps();
  tipProps.language = language;
  const lines = dataKeys.map((key, index) => (
    <Line
      isAnimationActive={activeAnimation}
      animationDuration={animationDuration}
      type="linear"
      key={index}
      dataKey={key}
      stackId={index}
      stroke={colors[index]}
      strokeWidth="2"
      dot={!loading}
      className={classes.bars}
    />
  ));


  return (
    <ResponsiveContainer height={height} width="100%">
      <LineChart
        data={data}
        margin={margin}
        className={classes.warp}
      >
        {!loading && <Legend {...legendProps(language)} />}
        {!loading && <CartesianGrid {...gridProps()} />}
        {!loading && <Tooltip {...tipProps} />}
        <XAxis {...xAxisProps('horizontal', language)} hide={loading} />
        <YAxis {...useYAxisProps('horizontal', language)} hide={loading} domain={loading ? [0, 10] : [0, 'auto']} />
        {lines}
      </LineChart>
    </ResponsiveContainer>
  );
};

Chart.propTypes = {
  data: arrayOf(object),
  dataKeys: arrayOf(string).isRequired,
  height: number,
  margin: shape({
    top: number, right: number, left: number, bottom: number,
  }),
  colors: array.isRequired,
  animationDuration: number,
  activeAnimation: bool,
  loading: bool,
  language: string,
};

Chart.defaultProps = {
  margin: {
    top: 40, right: 10, left: 10, bottom: 5,
  },
  loading: false,
  animationDuration: 500,
};

export default Chart;
