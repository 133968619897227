import React, { useEffect, useContext } from 'react';
import {
  string, object, bool,
} from 'prop-types';
import Box from '@material-ui/core/Box';
import MuiButton from '@material-ui/core/Button';
import {
  AvonContainer, Alert, Typography,
} from '@avon/component-library';
import makeStyles from '@material-ui/styles/makeStyles';
import PullToRefresh from 'pulltorefreshjs';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactDOMServer from 'react-dom/server';
import PmaContext from '../../PmaContext';
import { getStringItem } from '../../../utils/session-storage';
import { accessTokenKey } from '../../../utils/config';
import { pmaListBack } from '../../../utils/api';
import noop from '../../../utils/noop';
import { isMobile } from '../../../utils/getDeviceType';

const useStyles = makeStyles(theme => ({
  container: {
    height: p => (p.showTopBar ? 'calc(100% - 54px)' : '100%'),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  alertContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    overflowY: 'hidden',
    '& > div': {
      display: 'unset',
    },
  },
  topbar: {
    zIndex: 102,
    top: 0,
    position: 'sticky',
  },
  toolbar: {
    display: 'flex',
    zIndex: 102,
    width: '100%',
    textAlign: 'justify',
    textAlignLast: 'justify',
    paddingTop: theme.spacing(2.5),
    paddingLeft: styleProps => (styleProps.language === 'ar' ? theme.spacing(3) : theme.spacing(1)),
    paddingRight: styleProps => (styleProps.language === 'ar' ? theme.spacing(1) : theme.spacing(3)),
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.colors.white,
    '& span': {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 16,
    },
  },
  gridButton1: {
    minHeight: theme.spacing(3),
    width: 'auto',
    paddingTop: 0,
    paddingBottom: 0,
    wordBreak: 'break-word',
    color: theme.palette.colors.textDark,
  },
  gridButton2: {
    minHeight: theme.spacing(3),
    width: 'auto',
    paddingTop: 0,
    paddingBottom: 0,
    wordBreak: 'break-word',
    color: theme.palette.colors.corePink,
    '&:hover': {
      backgroundColor: 'rgba(24, 24, 24, 0.08)',
    },
  },
}));

const NoProspect = ({
  heading, subheading, createButton, noProspectIcon,
  noProspectLink, needToken,
}) => {
  const classes = useStyles({ showTopBar: isMobile() && sessionStorage.getItem('isWebApp') });
  const progressProps = {
    size: '2em',
    color: 'grey',
  };
  const getAccessToken = () => getStringItem(accessTokenKey) || window._env_.REACT_APP_ACCESS_TOKEN;

  const navigate = () => {
    if (sessionStorage.getItem('isWebApp')) {
      window.parent.postMessage({ menu: 'prospectPage', subMenu: 'form' }, '*');
    } else {
      pmaListBack('', '', false);
      window.location.href = needToken ? `${noProspectLink}&accessToken=${getAccessToken()}` : `${noProspectLink}`;
    }
  };

  const backHome = () => {
    if (sessionStorage.getItem('isWebApp')) {
      window.parent.postMessage('backHome', '*');
    } else {
      noop();
    }
  };

  const [{
    leadsContent: { title },
  }] = useContext(PmaContext);

  useEffect(() => {
    if (!sessionStorage.getItem('isWebApp') && (window.Android || window.navigator.userAgent.match(/(iPhone)|(iPad)|(Macintosh)/i))) {
      pmaListBack(title);
    }
  }, []);

  useEffect(() => {
    PullToRefresh.destroyAll();
    PullToRefresh.init({
      mainElement: '#root',
      triggerElement: '#root',
      instructionsPullToRefresh: ' ',
      instructionsReleaseToRefresh: ' ',
      instructionsRefreshing: ' ',
      onRefresh: () => {
        window.location.reload();
      },
      iconArrow: ReactDOMServer.renderToString(<CircularProgress {...progressProps} />),
      iconRefreshing: ReactDOMServer.renderToString(<CircularProgress {...progressProps} />),
    });
  }, []);

  return (
    <>
      {(isMobile() && sessionStorage.getItem('isWebApp')) && (
        <Box className={classes.topbar}>
          <Box
            data-testid="toolbar"
            id="admin-pma-toolbar"
            component="div"
            className={classes.toolbar}
          >
            <MuiButton
              className={classes.gridButton1}
              id="back-home-button-pma"
              onClick={backHome}
            >
              <svg width="60" height="44" viewBox="0 0 60 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M23.3872 15.2096C23.7795 14.9047 24.3467 14.9324 24.7072 15.293L24.7904 15.3872C25.0953 15.7795 25.0676 16.3467 24.707 16.7072L20.4132 21H32C32.5523 21 33 21.4477 33 22C33 22.5128 32.614 22.9355 32.1166 22.9933L32 23H20.4167L24.7071 27.2916L24.7903 27.3858C25.0953 27.7781 25.0676 28.3453 24.7071 28.7058C24.3166 29.0963 23.6834 29.0963 23.2929 28.7058L17.3241 22.737C17.1249 22.5542 17 22.2917 17 22C17 21.9992 17 21.9983 17 21.9975C17.0003 21.7419 17.098 21.4865 17.293 21.2915L23.293 15.2928L23.3872 15.2096Z" fill="#181818" />
              </svg>

              {/* {toolBarObject.close} */}
            </MuiButton>

            <Typography
              variant="h4"
              style={{
                alignSelf: isMobile() ? 'center' : 'unset',
                flexGrow: 1,
                textAlignLast: isMobile() ? 'center' : 'left',
                fontSize: '16px',
                margin: isMobile() ? 'unset' : 24,
              }}
            >
              {title}
            </Typography>
            <MuiButton
              color="secondary"
              variant="contained"
              className={classes.gridButton2}
              id="pma-toolbar-right-button"
            />
          </Box>
        </Box>
      )}
      <Box bgcolor="colors.lightZircon" className={classes.container}>

        <AvonContainer>
          <Box className={classes.alertContainer}>
            <Box pt={20} id="pma-no-prospect-Alert-container">
              <Alert
                id="pma-no-prospect-Alert"
                heading={heading}
                body={subheading}
                buttonLabel={createButton.label}
                iconType={noProspectIcon}
                handleClick={() => navigate()}
              />
            </Box>
          </Box>
        </AvonContainer>
      </Box>
    </>
  );
};

NoProspect.propTypes = {
  heading: string.isRequired,
  subheading: string.isRequired,
  createButton: object.isRequired,
  noProspectIcon: string,
  noProspectLink: string,
  needToken: bool,
};

export default NoProspect;
