import React from 'react';
import {
  func, string, bool, object, array,
} from 'prop-types';

import MuiModal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Button, Alert } from '@avon/component-library';
import { Grid, makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(() => ({
  button1: {
    '& a': {
      textDecoration: 'none',
    },
  },
  url: {
    wordBreak: 'break-word',
  },
}));

const Modal = ({
  API_URL,
  type,
  open,
  heading,
  body,
  body1,
  body2,
  market,
  language,
  submitData,
  closeSubmitModal,
  closeChangeModal,
  shareLabel,
  changeLabel,
  isSubmitting,
  successModal,
  errorModal,
  setErrorModalOpen,
  errorOpen,
  errorType,
  vanityNameToChange,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const isNotMobile = useMediaQuery(theme.breakpoints.up('sm'));
  const href = `/admin/prpman?market=${market}&language=${language}&device=mobile&channel=prp`;
  const urlLast = vanityNameToChange || '';
  const url = `${API_URL || 'avon.uk.com'}/prp/${market}/${language}/${urlLast}`;
  const sharePRP = () => {
    const u = navigator.userAgent;
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
    if (isAndroid) {
      // Android.dispatcher('sharePRP', url);
    } else {
      window.webkit.messageHandlers.avonRec.postMessage(
        `{"method":"sharePRP","params":{"url":"${url}","message":"", "type": "prpman" }}}`,
      );
    }
  };
  const content = body1
    && body1
      .replace('{apiURL}', API_URL)
      .replace('{vanityName}', vanityNameToChange);

  const closeAndReset = () => {
    setErrorModalOpen(false);
  };
  return (
    <MuiModal data-testid="submit-modal" id="prpman-submit-modal-new" open={open}>
      <Box
        minWidth={320}
        maxWidth={isNotMobile ? 568 : 355}
        position="relative"
        top={window.screen.height > 640 ? '15%' : '2%'}
        transform="translate(-50%)"
        margin="auto"
      >
        {type === 'share' ? (
          <Paper>
            <Box>
              <Box
                lineHeight="44px"
                mr={language === 'ar' ? 'unset' : 3.5}
                ml={language === 'ar' ? 3.5 : 'unset'}
                textAlign="right"
              >
                <IconButton
                  size="small"
                  aria-label="close"
                  id="prpman-submit-modal-close"
                  onClick={closeSubmitModal}
                  color="secondary"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                  >
                    <g fill="none" fillRule="evenodd">
                      <path d="M-6-6h24v24H-6z" />
                      <path
                        fill="#70777F"
                        fillRule="nonzero"
                        d="M1.613.21l.094.083L6 4.585 10.293.293a1 1 0 0 1 1.497 1.32l-.083.094L7.415 6l4.292 4.293a1 1 0 0 1-1.32 1.497l-.094-.083L6 7.415l-4.293 4.292a1 1 0 0 1-1.497-1.32l.083-.094L4.585 6 .293 1.707A1 1 0 0 1 1.613.21z"
                      />
                    </g>
                  </svg>
                </IconButton>
              </Box>
              <Box textAlign="center" mt={-3}>
                <Box
                  height={64}
                  width={64}
                  borderRadius={32}
                  border="2px solid rgb(41, 49, 50)"
                  color="rgb(41, 49, 50)"
                  textAlign="center"
                  lineHeight="64px"
                  display="inline-block"
                >
                  <Box component="span" fontSize="32px">
                    ?
                  </Box>
                </Box>
              </Box>
              <Box p={9} data-testid="error-modal">
                <Box
                  mb={5}
                  textAlign="center"
                  fontFamily="Montserrat"
                  color="#181818"
                >
                  <Typography variant="h2">{heading}</Typography>
                </Box>
                <Box mt={5} textAlign="center">
                  {body && (
                    <Box className={classes.url}>
                      <Box mb={2}>
                        <Typography priority="h4">{body}</Typography>
                      </Box>
                      <Typography priority="h4">
                        {API_URL || 'avon.uk.com'}
                        /prp/
                        <strong>{urlLast}</strong>
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box data-testid="share-button" pb={3}>
                <Grid container className={classes.button1}>
                  <Grid key="button1" item xs={6}>
                    <Box pl={3} pr={3}>
                      <a href={href}>
                        <Button
                          priority="tertiary"
                          name="prpman-submit-modal-change"
                          id="prpman-submit-modal-change"
                        >
                          {changeLabel}
                        </Button>
                      </a>
                    </Box>
                  </Grid>
                  <Grid key="button12" item xs={6}>
                    <Box pl={3} pr={3}>
                      <Button
                        priority="secondary"
                        name="prpman-submit-modal-share"
                        id="prpman-submit-modal-share"
                        onClick={sharePRP}
                        loading={isSubmitting}
                      >
                        {shareLabel}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Paper>
        ) : (
          <Paper>
            {errorOpen ? (
              <Box p={4}>
                <Alert
                  {...(errorType === 'error' ? errorModal : successModal)}
                  iconType={errorType === 'error' ? 'cross' : 'check'}
                  handleClick={closeAndReset}
                />
              </Box>
            ) : (
              <Box>
                <Box
                  lineHeight="44px"
                  mr={language === 'ar' ? 'unset' : 3.5}
                  ml={language === 'ar' ? 3.5 : 'unset'}
                  textAlign={language === 'ar' ? 'left' : 'right'}
                >
                  <IconButton
                    size="small"
                    aria-label="close"
                    id="prpman-submit-modal-close-2"
                    onClick={closeChangeModal}
                    color="secondary"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M-6-6h24v24H-6z" />
                        <path
                          fill="#70777F"
                          fillRule="nonzero"
                          d="M1.613.21l.094.083L6 4.585 10.293.293a1 1 0 0 1 1.497 1.32l-.083.094L7.415 6l4.292 4.293a1 1 0 0 1-1.32 1.497l-.094-.083L6 7.415l-4.293 4.292a1 1 0 0 1-1.497-1.32l.083-.094L4.585 6 .293 1.707A1 1 0 0 1 1.613.21z"
                        />
                      </g>
                    </svg>
                  </IconButton>
                </Box>
                <Box textAlign="center" mt={-3}>
                  <Box
                    height={64}
                    width={64}
                    borderRadius={32}
                    border="2px solid rgb(41, 49, 50)"
                    color="rgb(41, 49, 50)"
                    textAlign="center"
                    lineHeight="64px"
                    display="inline-block"
                  >
                    <Box component="span" fontSize="32px">
                      ?
                    </Box>
                  </Box>
                </Box>
                <Box p={9} data-testid="error-modal">
                  <Box
                    mb={5}
                    textAlign="center"
                    fontFamily="Montserrat"
                    color="#181818"
                  >
                    <Typography variant="h2">{heading}</Typography>
                  </Box>
                  <Box mt={5} textAlign="center">
                    {body1 && (
                      <Box className={classes.url}>
                        <Box mb={2}>
                          <Typography priority="h4">
                            {type === 'save' ? body1 : content}
                            {type === 'save' ? API_URL || 'avon.uk.com' : ''}
                            <strong>{type === 'save' ? vanityNameToChange : ''}</strong>
                            {body2}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box data-testid="share-button" pb={3}>
                  <Grid container className={classes.button1}>
                    <Grid key="button1" item xs={6}>
                      <Box pl={3} pr={3}>
                        <Button
                          priority="tertiary"
                          name={`prpman-close-modal-${errorType}`}
                          id={`prpman-close-modal-${errorType}`}
                          onClick={closeChangeModal}
                        >
                          {changeLabel}
                        </Button>
                      </Box>
                    </Grid>
                    <Grid
                      key="button12"
                      item
                      xs={6}
                    >
                      <Box pl={3} pr={3}>
                        <Button
                          priority="secondary"
                          id={`prpman-toggle-modal-${errorType}`}
                          name={`prpman-toggle-modal-${errorType}`}
                          loading={isSubmitting}
                          onClick={submitData}
                        >
                          {shareLabel}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}
          </Paper>
        )}
      </Box>
    </MuiModal>
  );
};

Modal.propTypes = {
  type: string.isRequired,
  heading: string,
  body: string,
  body1: string,
  body2: string,
  open: bool.isRequired,
  submitData: func,
  shareLabel: string,
  changeLabel: string,
  avonHost: string,
  vanityName: string,
  errorModal: object,
  successModal: object,
  profile: object,
  closeSubmitModal: func,
  closeChangeModal: func,
  openChangeModal: func,
  isSubmitting: bool,
  market: string,
  language: string,
  suggestList: array,
  setErrorModalOpen: func,
  errorOpen: bool,
  errorType: string,
  API_URL: string,
  vanityNameToChange: string,
};

export default Modal;
