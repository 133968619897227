import React from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import 'react-tippy/dist/tippy.css';
import {
  Tooltip,
} from 'react-tippy';
import { node, string, bool } from 'prop-types';

const AvonTooltip = ({
  order, tipMsg, vanityName, children, showDissmiss, language, ...props
}) => (
  <Tooltip
    interactive
    html={(
      <Box
        pr={language === 'ar' ? 0 : 3}
        pl={language === 'ar' ? 3 : 0}
        style={{ wordWrap: 'break-word' }}
      >
        <Box textAlign={language === 'ar' ? 'right' : 'left'}>
          <p>
            {tipMsg}
            <b>{vanityName}</b>
          </p>
        </Box>
        {
          showDissmiss && (
            <IconButton
              size="small"
              aria-label="close"
              id={`prpman-tipMsg-${order}`}
              name={`prpman-tipMsg-${order}`}
              color="secondary"
              style={{
                position: 'absolute',
                right: language === 'ar' ? 'unset' : '10px',
                left: language === 'ar' ? '10px' : 'unset',
                top: '12px',
              }}
              onClick={() => document.body.click()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
              >
                <g fill="none" fillRule="evenodd">
                  <path d="M-6-6h24v24H-6z" />
                  <path
                    fill="#70777F"
                    fillRule="nonzero"
                    d="M1.613.21l.094.083L6 4.585 10.293.293a1 1 0 0 1 1.497 1.32l-.083.094L7.415 6l4.292 4.293a1 1 0 0 1-1.32 1.497l-.094-.083L6 7.415l-4.293 4.292a1 1 0 0 1-1.497-1.32l.083-.094L4.585 6 .293 1.707A1 1 0 0 1 1.613.21z"
                  />
                </g>
              </svg>
            </IconButton>
          )
        }
      </Box>
      )}
    {...props}
  >
    {children}
  </Tooltip>
);

AvonTooltip.propTypes = {
  order: string,
  showDissmiss: bool,
  tipMsg: string.isRequired,
  vanityName: string,
  children: node.isRequired,
  language: string,
};

export default AvonTooltip;
