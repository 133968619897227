const setPageCount = (state, action) => {
  switch (action.pageCount) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return state;
  }
};
export default setPageCount;
