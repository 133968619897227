import React from 'react';
import { node, bool, string } from 'prop-types';
import { Typography } from '@avon/component-library';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  head: {
    backgroundColor: theme.palette.colors.lightZircon,
    fontWeight: 'bold',
  },
  cell: {
    minWidth: '100px',
  },
}));

const HeadedTable = ({ data, loading, language }) => {
  const classes = useStyles();
  const theme = useTheme();
  const content = [...data];

  const small = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    !loading ? (
      <Box style={{ overflowX: 'auto' }} mt={theme.spacing(2)}>
        <Table size={small ? 'small' : 'medium'}>
          <TableHead className={classes.head}>
            <TableRow>
              {content.shift().map((header, index) => (
                // eslint-disable-next-line no-nested-ternary
                <TableCell key={index} align={index === 0 ? (language === 'ar' ? 'right' : 'left') : 'center'}><Typography variant="body2" weight="bold">{header}</Typography></TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {content.map(row => (
              <TableRow key={row[0]}>
                {row.map((cell, index) => (
                  // eslint-disable-next-line no-nested-ternary
                  <TableCell key={index} className={classes.cell} align={index === 0 ? (language === 'ar' ? 'right' : 'left') : 'center'}>
                    {cell}
                  </TableCell>
                ))
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    )
      : (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      )

  );
};

HeadedTable.propTypes = {
  data: node,
  loading: bool,
  language: string,
};

export default HeadedTable;
