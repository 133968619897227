import React from 'react';
import { ErrorBoundary } from '@avon/component-library';
import { makeBaseApi } from '../api';
import getUrlParams from '../getUrlParams';
import GoogleTagManager from '../../analytics/GTM';

const withCallApi = (Element, search, props) => {
  const {
    market, device, language, channel, accessToken, refreshToken, backToEdit,
  } = getUrlParams(search);

  const callApi = makeBaseApi({
    // eslint-disable-next-line no-underscore-dangle
    domain: window._env_.REACT_APP_API_URL,
    market,
    device,
    language,
    channel,
    accessToken,
    refreshToken,
    backToEdit,
  });

  return (
    <ErrorBoundary callApi={callApi} language={language}>
      <GoogleTagManager
        gtmId="GTM-NS24BSX"
        // eslint-disable-next-line no-underscore-dangle
        domain={window._env_.REACT_APP_API_URL}
        market={market}
        device={device}
        language={language}
        channel={channel}
      />
      <Element
        {...props}
        callApi={callApi}
        language={language}
        market={market}
        backToEdit={backToEdit}
      />
    </ErrorBoundary>
  );
};

export default withCallApi;
