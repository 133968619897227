// ======================== PRP Management Endpoints ========================

export const getPrpManContent = () => ({
  endpoint: '/SLProfile',
});

export const getPrpManData = () => ({
  endpoint: '/salesLeader/profiles?endpointCd=getSalesLeaderProfiles',
});

export const uploadPrpManImage = image => ({
  method: 'POST',
  endpoint: '/file?entityTypeName=SalesLeader&category=myPic',
  data: image,
});

export const deletePrpManImage = imageId => ({
  method: 'DELETE',
  endpoint: `/file?entityTypeName=SalesLeader&entityFieldName=myPic&fId=${imageId}`,
});

export const uploadPrpManData = data => ({
  method: 'POST',
  endpoint: '/salesLeader/profile?endpointCd=editSalesLeaderProfile',
  data,
});

export const switchPrp = data => ({
  method: 'POST',
  endpoint: '/salesLeader/profile?endpointCd=switchPrp',
  data,
});

export const uploadPrpManPreviewData = data => ({
  method: 'POST',
  endpoint: '/salesLeader/profile?endpointCd=editSalesLeaderProfilePreview',
  data,
});

export const uploadPrpManPhoneEmail = data => ({
  method: 'POST',
  endpoint: '/salesLeader/profile?endpointCd=editSalesLeaderProfilePart',
  data,
});

// ======================== Prospect Management Endpoints ========================
export const getProspectContent = () => ({
  endpoint: '/prospect',
});

export const getProspectData = prospectId => ({
  endpoint: `/appts/${prospectId}?endpointCd=getProspect`,
});

export const setProspectData = data => ({
  method: 'POST',
  endpoint: '/appts?endpointCd=prospect',
  data,
});

export const setProspectDataSaveExit = data => ({
  method: 'POST',
  endpoint: '/appts?endpointCd=prospectSaveExit',
  data,
});

// ======================== Stats Endpoints ========================

export const getStatsContent = timeZoneOffset => ({
  endpoint: `/viewStats?zoneOffSet=${timeZoneOffset}`,
});

export const getStatsData = ({
  timeZoneOffset, startTime, endTime, dataElementsReq,
}) => ({
  method: 'POST',
  endpoint: '/analytics/salesLeaders/charts',
  data: {
    zoneOffSet: timeZoneOffset,
    timeRange: {
      startTime,
      endTime,
    },
    dataElementsReq,
  },
});

// ======================== LMA Endpoints ========================

export const resendEmail = emailLink => ({
  endpoint: `/appts/emailLink?link=${emailLink}&endpointCd=resendEmailBySL`,
});

export const resendSMS = apptId => ({
  endpoint: `/appts/${apptId}/agreements/notify?endpointCd=resendSMSBySL`,
});

export const getNotes = apptId => ({
  endpoint: `/appts/${apptId}?endpointCd=getNotes`,
});

export const getLeadsByStatus = ({
  searchStr, sortName, pagination, filters,
}) => ({
  endpoint: '/appts/query?endpointCd=leadData',
  method: 'POST',
  data: {
    searchStr,
    sortName,
    pagination,
    filters,
  },
});

export const sendNotes = ({
  apptId, notesContent,
}) => ({
  endpoint: '/appts?endpointCd=addNote',
  method: 'PUT',
  data: {
    apptId,
    rep: {
      note: notesContent,
    },
  },
});

export const addTestimonial = ({ name, location, testimony }) => ({
  endpoint: '/salesLeader/testimonial',
  method: 'POST',
  data: {
    name,
    location,
    testimony,
  },
});

export const editTestimonial = ({
  id, name, location, testimony,
}) => ({
  endpoint: '/salesLeader/testimonial',
  method: 'PUT',
  data: {
    id,
    name,
    location,
    testimony,
  },
});

export const delTestimonial = id => ({
  endpoint: `/salesLeader/testimonial?id=${id}`,
  method: 'DELETE',
});

export const getAllData = apptId => ({
  endpoint: `/appts/${apptId}?endpointCd=getAllApptData`,
});

export const getViewAllLeads = () => ({
  endpoint: '/viewAllLeads',
});

// ======================== PMA Endpoints ========================

export const getAllDataProspect = apptId => ({
  endpoint: `/appts/${apptId}?endpointCd=getAllProspectData`,
});

export const getViewAllProspects = () => ({
  endpoint: '/viewAllProspects',
});

export const getProspectsByStatus = ({
  searchStr, sortName, pagination, filters,
}) => ({
  endpoint: '/prospects/query?endpointCd=prospectData',
  method: 'POST',
  data: {
    searchStr,
    sortName,
    pagination,
    filters,
  },
});

export const prefillAppt = apptId => ({
  method: 'PUT',
  endpoint: '/appts?endpointCd=prospectToAppt',
  data: {
    apptId,
    rep: {
      pageCode: 'contactDetails',
      contactDetails: { name: '' },
    },
  },
});

export const sendEmailToProspect = apptId => ({
  method: 'PUT',
  endpoint: '/appts?endpointCd=sendEmailToProspect',
  data: {
    apptId,
  },
});

export const deleteProspect = apptId => ({
  endpoint: `/appts/${apptId}?endpointCd=deleteProspect`,
});

export const delegateAppt = (apptId, slNr) => ({
  method: 'PUT',
  endpoint: '/appts?endpointCd=delegateLead',
  data: {
    sl: {
      acctNr: slNr,
    },
    oldApptId: apptId,
  },
});
