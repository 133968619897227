/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable import/no-unresolved */
import React, { useContext, useEffect } from 'react';
import { Dropdown as AvonDropdown } from '@avon/component-library';
import {
  func, shape, string, object, bool, any,
} from 'prop-types';
import ProspectContext from '../../../../prospect/ProspectContext';

const Dropdown = ({
  field: {
    onBlur, name,
  },
  form: {
    setFieldValue, touched, errors, values, setTouched,
  },
  defaultValue,
  childName,
  parentName,
  childHasMask,
  selectOptions,
  language,
  initialData,
  editPhone,
  restoreValue,
  editEmail,
  ...props
}) => {
  const { inputRef, setMobileErrorsParent } = useContext(ProspectContext);
  const initialValue = values[name] ? values[name] : initialData[name];

  useEffect(() => {
    setMobileErrorsParent(initialData[name]);
  }, [editPhone]);

  const selectChange = async (value) => {
    setFieldValue(name, value, true);
    setMobileErrorsParent(value);
    if (childName
      && (values[childName]
        || (!values[childName] && touched[childName]))) {
      await setFieldValue(childName, '', true);
      await setTouched({ childName });
      const inputEle = inputRef.current.querySelector(`#prpman-AvonInputField-${childName}`);
      inputEle.focus();
    }
  };

  const getSelectOptions = () => {
    if (parentName) {
      if (values[parentName]) {
        return selectOptions[values[parentName]];
      }
      return [];
    }
    return selectOptions;
  };

  useEffect(() => {
      if (name === 'mobilePhonNrCd') {
        if (restoreValue || editEmail) {
          setFieldValue(name, initialData[name]);
        }
      }
  }, [editPhone, editEmail, restoreValue]);

  return (
    (initialValue !== undefined) && (
      <AvonDropdown
        onChange={(e) => {
          if (e === initialValue) return;
          selectChange(e);
        }}
        onBlur={onBlur}
        name={name}
        language={language}
        touched={(!!touched[name]).toString()}
        error={touched[name] && errors[name]}
        fullWidth
        defaultValue={values[name] || defaultValue || initialValue}
        selectOptions={getSelectOptions()}
        selectStyle={{ marginTop: '31px' }}
        {...props}
      />
    )
  );
};

Dropdown.propTypes = {
  field: shape({
    name: string.isRequired,
    onBlur: func.isRequired,
  }).isRequired,
  form: shape({
    setFieldValue: func.isRequired,
    touched: object.isRequired,
    errors: object.isRequired,
    values: object.isRequired,
  }).isRequired,
  defaultValue: string,
  childName: string,
  parentName: string,
  childHasMask: bool,
  selectOptions: any,
  language: string,
  initialData: object,
  editPhone: bool,
  editEmail: bool,
  restoreValue: bool,
};

export default Dropdown;
