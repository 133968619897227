
import React, {
  useState, useEffect, Fragment, useMemo, useCallback,
} from 'react';
import {
  object, string, number, oneOfType,
} from 'prop-types';
import { Typography } from '@avon/component-library';
import { isMobile } from '../../../utils/getDeviceType';

const msMap = {
  day: 86400000,
  hour: 3600000,
  minute: 60000,
  second: 1000,
};

const Timer = ({
  formatArray, timerLabelMap, countdown, interval, statusColor,
}) => {
  const isWebApp = sessionStorage.getItem('isWebApp') && !isMobile();
  const [timer, setTimer] = useState(countdown);
  const updateInt = useMemo(() => msMap[interval], [interval]);

  const countdownGenerator = () => formatArray.reduce((previous, unit, i, arr) => {
    const remaining = i > 0 ? timer % msMap[arr[i - 1]] : timer;
    const numOfUnit = Math.floor(remaining / msMap[unit]).toString();
    if (numOfUnit > 0 || previous.length > 0 || i === (arr.length - 1) || (unit === 'hour' && numOfUnit === '0')) {
      previous.push(
        <Fragment key={`${unit}-display`}>
          <Typography component="span" variant={isWebApp ? 'body2' : 'h4'} weight="regular" style={{ color: statusColor }}>{`${previous.length !== 0 ? timerLabelMap.separator : ''}${numOfUnit.padStart(2, '0')}`}</Typography>
          <Typography component="span" variant="body2" weight="regular" style={{ color: statusColor }}>{`${timerLabelMap[unit]}`}</Typography>
        </Fragment>,
      );
    }
    return previous;
  }, []);

  const intervalCalc = useCallback(() => [
    (timer % updateInt) || updateInt,
    Math.ceil((timer - updateInt) / updateInt) * updateInt,
  ], [timer, updateInt]);


  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const [i, timeRemaining] = intervalCalc();
    if (timer > 0) {
      const t = setTimeout(() => {
        setTimer(timeRemaining);
      }, i);
      return () => clearTimeout(t);
    }
  }, [timer, intervalCalc]);

  return countdownGenerator();
};

Timer.propTypes = {
  timerLabelMap: object,
  stusEndDt: oneOfType([string, number]),
  timerType: string,
  color: string,
  statusColor: string,
};

Timer.defaultProps = {
  interval: 'minute',
};

export default Timer;
