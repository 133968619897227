import React, { useState, useCallback } from 'react';
import {
  func, string, bool, object,
} from 'prop-types';

import MuiModal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Button, Alert } from '@avon/component-library';

const Modal = ({
  open,
  fnInCloseModal,
  closeModal,
  heading,
  body,
  submitData,
  saveLabel,
  cancelLabel,
  successModal,
  isSubmitting,
  icon,
  color,
}) => {
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up('sm'));
  const [secondModal, setSecondModal] = useState(false);

  const renderSecondModal = useCallback(async () => {
    if (!isSubmitting) {
      const submitted = await submitData();
      if (submitted && successModal) {
        setSecondModal(true);
      } else if (submitted && submitted.hasError) {
        // do nothing
      } else {
        closeModal();
      }
    }
  }, [isSubmitting, submitData]);

  const closeAndReset = useCallback(() => {
    closeModal();
    setSecondModal(false);
  });

  return secondModal && !successModal ? null : (
    <MuiModal
      data-testid="prpman-submit-modal"
      id="prpman-submit-modal"
      open={open}
      style={{
        overflow: 'auto',
      }}
    >
      <Box
        minWidth={320}
        maxWidth={isNotMobile ? 568 : 355}
        position="relative"
        top={window.screen.height > 640 ? '15%' : '2%'}
        transform="translate(-50%)"
        margin="auto"
      >
        <Paper>
          {secondModal ? (
            <Box p={4}>
              <Alert
                id="prp-alert"
                {...successModal}
                iconType="check"
                handleClick={closeAndReset}
              />
            </Box>
          ) : (
            <Box p={9} data-testid="error-modal" id="prpman-error-modal">
              {icon && (
                <Box textAlign="center" mb={6}>
                  <Box
                    height={64}
                    width={64}
                    borderRadius={32}
                    color={(color && color.fontColor) || 'rgb(41, 49, 50)'}
                    textAlign="center"
                    lineHeight="64px"
                    display="inline-block"
                    style={{ border: `2px solid ${color ? color.borderColor : 'rgb(41, 49, 50)'}` }}
                  >
                    <Box component="span" fontSize="32px">
                      ?
                    </Box>
                  </Box>
                </Box>
              )}
              <Box mb={5} textAlign="center">
                <Typography variant="h2">{heading}</Typography>
              </Box>
              <Box mt={5} textAlign="center">
                {(body && <Typography priority="h4">{body}</Typography>)}
              </Box>
              <Box mt={5}>
                <Button
                  priority="secondary"
                  name="prpman-submit-modal-save"
                  id="prpman-submit-modal-save"
                  onClick={renderSecondModal}
                  loading={isSubmitting}
                >
                  {saveLabel}
                </Button>
              </Box>
              {
                cancelLabel && (
                  <Box mt={5}>
                    <Button
                      priority="tertiary"
                      name="prpman-submit-modal-close"
                      id="prpman-submit-modal-close"
                      onClick={
                        fnInCloseModal
                          ? () => { fnInCloseModal(); closeModal(); }
                          : closeModal
                      }
                    >
                      {cancelLabel}
                    </Button>
                  </Box>
                )
              }
            </Box>
          )}
        </Paper>
      </Box>
    </MuiModal>
  );
};

Modal.propTypes = {
  heading: string,
  body: string,
  fnInCloseModal: func,
  closeModal: func,
  open: bool.isRequired,
  submitData: func.isRequired,
  saveLabel: string.isRequired,
  cancelLabel: string,
  successModal: object,
  isSubmitting: bool,
  icon: bool,
  color: object,
};

export default Modal;
