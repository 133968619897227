import React from 'react';
import { bool, string, object } from 'prop-types';
import {
  Typography,
} from '@avon/component-library';
import { makeStyles, useTheme } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import AvonTooltip from '../../AvonTooltip';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& h3': {
      fontSize: '14px',
    },
    '& h2': {
      fontSize: '20px',
      lineHeight: '28px',
      fontFamily: 'Montserrat',
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        fontWeight: 300,
      },
    },
  },
}));

const Heading = ({
  text,
  language,
  align,
  tipLeft,
  style,
  ...props
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const {
    showTip, tipMsg,
  } = { ...props };
  return (
    <Box
      className={classes.root}
      id={`prpman-heading-${text.substring(0, 5)}`}
    >
      {!tipLeft && (
      <Typography
        align={language === 'ar' ? 'right' : align}
        {...props}
        dangerouslySetInnerHTML={{ __html: text }}
        style={{ marginRight: '8px', ...style }}
        id={`prpman-heading-label-${text.substring(0, 5)}`}
      />
      )}
      {
        showTip ? (
          <Box
            component="span"
            mr={language === 'ar' ? 'unset' : 2}
            ml={language === 'ar' ? 2 : 'unset'}
          >
            <AvonTooltip
              position="top"
              trigger="click"
              arrow
              tipMsg={tipMsg}
              theme="light"
              interactive
              order={text}
              language={language}
              showDissmiss
            >
              <svg cursor="pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 14">
                <g fill="none" fillRule="evenodd">
                  <path d="M-1-1h16v16H-1z" />
                  <path
                    fill={theme.palette.colors.corePink}
                    fillRule="nonzero"
                    d="M7 .333a6.667 6.667 0 1 1 0 13.334A6.667 6.667 0 0 1 7 .333zm0 1.334a5.333 5.333 0 1 0 0 10.666A5.333 5.333 0 0 0 7 1.667zM7 9a.667.667 0 1 1 0 1.333A.667.667 0 0 1 7 9zm0-5.333c1.104 0 2 .897 2 2 0 .616-.256 1.027-.776 1.51l-.38.338-.159.154c-.01.008-.014.01-.017.007l-.001-.007-.005.075a.667.667 0 0 1-1.329-.077c0-.43.198-.743.553-1.081l.11-.102.229-.201c.334-.295.442-.452.442-.616a.668.668 0 0 0-1.33-.078l-.004.078a.667.667 0 1 1-1.333 0 2 2 0 0 1 2-2z"
                  />
                </g>
              </svg>
            </AvonTooltip>
          </Box>
        ) : null
      }
      {tipLeft && (
      <Typography
        align={language === 'ar' ? 'right' : align}
        {...props}
        dangerouslySetInnerHTML={{ __html: text }}
        style={{ marginRight: '8px', ...style }}
        id={`prpman-heading-label-${text.substring(0, 5)}`}
      />
      )}
    </Box>
  );
};

Heading.propTypes = {
  text: string.isRequired,
  language: string,
  align: string,
  tipLeft: bool,
  style: object,
};

export default Heading;
