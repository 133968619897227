import React, { useMemo } from 'react';
import { string } from 'prop-types';
import MobileDateSelector from './MobileDateSelector';
import WebDateSelector from './WebDateSelector';
import isMobile from '../../../utils/isMobile/index';
import formatDate from '../../../utils/formatDate';


const DateSelector = ({ language, market, ...props }) => {
  const displayDate = date => formatDate(date, language, 'D MMMM YYYY');

  const Component = useMemo(
    () => (isMobile() ? MobileDateSelector : WebDateSelector),
    [],
  );

  return (
    <Component {...props} language={language} market={market} displayDate={displayDate} />
  );
};

DateSelector.propTypes = {
  language: string.isRequired,
  market: string,
};

export default DateSelector;
