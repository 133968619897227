import React, { useContext, useState } from 'react';
import { PageTabs, Button } from '@avon/component-library';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import DialogActions from '@material-ui/core/DialogActions';
import { object, string, func } from 'prop-types';
import Sort from './Sort';
import Filter from './Filter';
import LmaContext from '../../LmaContext';


const useStyles = makeStyles(theme => ({
  box: {
    maxWidth: '100%',
  },
  title: {
    height: '19px',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  formGroup: {
    display: 'flex',
    width: '100%',
    paddingRight: styleProps => (styleProps.language === 'ar' ? 'unset' : theme.spacing(5)),
    paddingLeft: styleProps => (styleProps.language === 'ar' ? theme.spacing(5) : 'unset'),
    justifyContent: 'space-between',
    color: theme.palette.colors.textMedium,
    fontWeight: '700',
  },
  actionButton: {
    borderRadius: 0,
    margin: 0,
    '&:first-child': {
      color: theme.palette.colors.textLight,
    },
    '&:not(:first-child)': {
      borderLeft: styleProps => (styleProps.language === 'ar' ? 'unset' : `1px solid ${theme.palette.colors.zircon}`),
      borderRight: styleProps => (styleProps.language === 'ar' ? `1px solid ${theme.palette.colors.zircon}` : 'unset'),
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  actions: {
    width: '100%',
    backgroundColor: theme.palette.colors.white,
    boxShadow: '0 -5px 10px 0 rgba(0, 0, 0, 0.05);',
    zIndex: '1',
  },
  tabContainer: {
    flex: '1',
    overflowY: 'scroll',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const FilterAndSort = ({
  setOpen,
  sortValue,
  setSort,
  filterValue,
  setFilter,
}) => {
  const [{
    apiBody,
    language,
    leadsContent:
    { sortFilter: { sortFilterTabInfo: { sort, filter }, button } },
  }, dispatch] = useContext(LmaContext);
  const [disable, setDisable] = useState(true);
  const [typeExpanded, setTypeExpanded] = useState(false);
  const [sourceExpanded, setSourceExpanded] = useState(false);
  const [statusExpanded, setStatusExpanded] = useState(false);
  const [internalSort, setInternalSort] = useState(sortValue);
  const [internalFilter, setInternalFilter] = useState(filterValue);
  const filterProps = {
    typeExpanded,
    setTypeExpanded,
    sourceExpanded,
    setSourceExpanded,
    statusExpanded,
    setStatusExpanded,
    setDisable,
    filterValue: internalFilter,
    setFilter: setInternalFilter,
  };
  const clear = () => {
    setDisable(false);
    setSort('NEWEST');
    setInternalSort('NEWEST');
    setInternalFilter({
      AGL: false,
      FGL: false,
      asa: false,
      mra: false,
      cca: false,
      web: false,
      prp: false,
      in_progress: false,
      new: false,
      awaiting_acceptance: false,
      pending: false,
    });
    setFilter({
      AGL: false,
      FGL: false,
      asa: false,
      mra: false,
      cca: false,
      web: false,
      prp: false,
      in_progress: false,
      new: false,
      awaiting_acceptance: false,
      pending: false,
    });
  };


  const apply = () => {
    setSort(internalSort);
    setFilter(internalFilter);
    const keys = Object.keys(internalFilter);
    const values = keys.filter(key => internalFilter[key]);
    const source = values.filter(value => !!value.match(/asa|mra|cca|web|prp|Get a call Back/i));
    const type = values.filter(value => !!value.match(/agl|fgl/i));
    const status = values.filter(value => !!value.match(/in_progress|new|awaiting_acceptance|pending/i));

    const leadType = type.length ? type : undefined;
    const leadSource = source.length ? source : undefined;
    const statusType = status.length ? status : undefined;
    const {
      pagination: { pageSize },
    } = apiBody;

    dispatch({
      filters: { ...internalFilter },
      sort: internalSort,
      apiBody: {
        ...apiBody,
        pagination: { pageSize, pageIndex: 0 },
        sortName: internalSort,
        filters: {
          'Lead Type': leadType,
          'Lead Source': leadSource,
          Status: statusType,
        },
      },
    });
    setOpen(false);
  };
  const contentWrapper = (children, bgcolor) => (<Box p={3} bgcolor={bgcolor}>{children}</Box>);
  const classes = useStyles({ language });
  const tabs = [
    {
      label: sort.title,
      content: contentWrapper(<Sort
        setSort={setInternalSort}
        sortValue={internalSort}
        setDisable={setDisable}
      />, 'colors.white'),
    },
    {
      label: filter.title,
      content: contentWrapper(<Filter
        {...filterProps}
      />,
      'colors.white'),
    },
  ];
  return (
    <>
      <Box className={classes.tabContainer}>
        <PageTabs tabs={tabs} id="fliter-and-sort-tabs" name="fliter-and-sort-tabs" />
      </Box>
      <DialogActions className={classes.actions}>
        <Button
          barButton
          name="selector-cancel"
          id="selector-cancel"
          priority="tertiary"
          onClick={clear}
          language={language}
          className={classes.actionButton}
        >
          {button.clear}
        </Button>
        <Button
          barButton
          name="selector-confirm"
          id="selector-confirm"
          priority="tertiary"
          onClick={apply}
          disabled={disable}
          language={language}
          className={classes.actionButton}
        >
          {button.apply}
        </Button>
      </DialogActions>
    </>
  );
};

export default FilterAndSort;

FilterAndSort.propTypes = {
  filterValue: object,
  setFilter: func,
  setOpen: func,
  sortValue: string,
  setSort: func,
};
