import React, {
  useContext, useState, useEffect, useRef, useMemo, useCallback,
} from 'react';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import makeStyles from '@material-ui/styles/makeStyles';
// import MuiButton from '@material-ui/core/Button';
import {
  AvonContainer, Typography, AvonIcon, InputField,
} from '@avon/component-library';
import Card from '@material-ui/core/Card';
import SearchIcon from '@material-ui/icons/Search';
import PullToRefresh from 'pulltorefreshjs';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactDOMServer from 'react-dom/server';
import MuiButton from '@material-ui/core/Button';
import debounce from '../../../utils/debounce';
import ListComponent from './ListComponent';
import PmaContext from '../../PmaContext';
import { useTracking } from '../../../analytics/Analytics';
import FilterAndSort from '../FilterAndSort';
import { pmaListBack } from '../../../utils/api';
import noop from '../../../utils/noop';
import { isMobile } from '../../../utils/getDeviceType';

const useStyles = makeStyles(theme => ({
  background: {
    backgroundColor: theme.palette.colors.lightZircon,
    height: p => (p.isWebApp ? 'unset' : '100%'),
    overflowY: 'hidden',
    '& .ptr--ptr': {
      boxShadow: 'initial',
    },
  },
  topbar: {
    zIndex: 102,
    top: 0,
  },
  toolbar: {
    display: 'flex',
    zIndex: 102,
    width: '100%',
    textAlign: 'justify',
    textAlignLast: 'justify',
    paddingTop: theme.spacing(2.5),
    paddingLeft: styleProps => (styleProps.language === 'ar' ? theme.spacing(3) : theme.spacing(1)),
    paddingRight: styleProps => (styleProps.language === 'ar' ? theme.spacing(1) : theme.spacing(3)),
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.colors.white,
    '& span': {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 16,
    },
  },
  gridButton1: {
    minHeight: theme.spacing(3),
    width: 'auto',
    paddingTop: 0,
    paddingBottom: 0,
    wordBreak: 'break-word',
    color: theme.palette.colors.textDark,
  },
  gridButton2: {
    minHeight: theme.spacing(3),
    width: 'auto',
    paddingTop: 0,
    paddingBottom: 0,
    wordBreak: 'break-word',
    color: theme.palette.colors.corePink,
    '&:hover': {
      backgroundColor: 'rgba(24, 24, 24, 0.08)',
    },
  },
  image: {
    verticalAlign: 'middle',
    paddingLeft: styleProps => (styleProps.language === 'ar' ? 'unset' : theme.spacing(2)),
    paddingRight: styleProps => (styleProps.language === 'ar' ? theme.spacing(2) : 'unset'),
    transform: styleProps => (styleProps.language === 'ar' ? 'rotateY(180deg)' : 'rotateY(0)'),
    display: 'inline-block',
  },
  iconBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  search: {
    borderRadius: '0px',
    boxShadow: '0 0 18px 0 rgba(0, 0, 0, 0.25)',
  },
  badge: {
    backgroundColor: theme.palette.colors.green,
  },
  inputRoot: {
    backgroundColor: theme.palette.colors.lightZircon,
    justifyContent: 'center',
    borderRadius: '6px',
  },
  noBorder: {
    border: 'none',
  },
  placeholderMask: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    color: theme.palette.colors.textLight,
  },
  iconContainer: {
    margin: `auto ${theme.spacing(2)}px`,
    display: 'flex',
  },
  filterTitleText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
    },
  },
  listWarp: {
    '& > div': {
      direction: 'unset !important',
      paddingTop: 10,
    },
  },
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const RepCardContainer = () => {
  const [open, setOpen] = useState(false);
  const [{
    records,
    apiBody,
    search,
    filters,
    sort,
    language,
    leadsContent: { title, sortFilter, searchPlaceHolder }, hasNextPage,
  }, dispatch] = useContext(PmaContext);
  const classes = useStyles({ language, isWebApp: sessionStorage.getItem('isWebApp') });
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [sortValue, setSort] = useState(sort);
  const [filterValue, setFilter] = useState({ ...filters });
  const sortAndFilter = {
    sortValue,
    setSort,
    filterValue,
    setFilter,
  };
  const inputRef = useRef({ value: '' });
  const progressProps = {
    size: '2em',
    color: 'grey',
  };
  // Analytics
  const [, setTracking] = useTracking();

  const iPhone = (method, paramsObject = {}) => {
    const msg = `{"method": "${method}", "params": "${JSON.stringify(paramsObject).replace(/"/g, '\\"')}"}`;
    window.webkit.messageHandlers.avonRec.postMessage(msg);
  };
  const Android = (method, andriodObject = {}) => {
    if (window.Android) {
      window.Android.dispatcher(method, JSON.stringify(andriodObject));
    } else {
      noop();
    }
  };
  const backHome = () => {
    if (sessionStorage.getItem('isWebApp')) {
      window.parent.postMessage('backHome', '*');
    } else if (window.navigator.userAgent.match(/(iPhone)|(iPad)|(Macintosh)/i)) {
      iPhone('backHome', {});
    } else {
      Android('backHome', {});
    }
  };

  const fetchMoreListItems = useCallback(() => {
    const {
      pagination: { pageSize, pageIndex },
    } = apiBody;
    dispatch({
      apiBody: {
        ...apiBody, pagination: { pageSize, pageIndex: pageIndex + 1 },
      },
    });
  }, [apiBody, dispatch]);


  const triggerPlaceholder = useCallback(() => {
    setShowPlaceholder(!(inputRef.current.value || inputRef.current === document.activeElement));
  }, []);

  const inputFocus = useCallback(() => {
    inputRef.current.focus();
    triggerPlaceholder();
  }, [triggerPlaceholder]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const onChange = debounce((inputValue) => {
    const {
      pagination: { pageSize },
    } = apiBody;

    dispatch({
      search: inputValue,
      apiBody: {
        ...apiBody,
        pagination: { pageSize, pageIndex: 0 },
        searchStr: inputValue,
      },
    });
  }, 300);

  useEffect(() => {
    if (!sessionStorage.getItem('isWebApp') && (window.Android || window.navigator.userAgent.match(/(iPhone)|(iPad)|(Macintosh)/i))) {
      pmaListBack(title);
    }
  }, []);

  useEffect(() => {
    setTracking({ eventType: 'pageview', eventName: 'pmaViewAllProspects' });
  }, []);

  useEffect(() => {
    PullToRefresh.destroyAll();
    PullToRefresh.init({
      mainElement: '#pma-refreshElemnet',
      triggerElement: '#pma-refreshElemnet',
      instructionsPullToRefresh: ' ',
      instructionsReleaseToRefresh: ' ',
      instructionsRefreshing: ' ',
      onRefresh: () => {
        onChange(inputRef.current.value || '');
      },
      iconArrow: ReactDOMServer.renderToString(<CircularProgress {...progressProps} />),
      iconRefreshing: ReactDOMServer.renderToString(<CircularProgress {...progressProps} />),
      shouldPullToRefresh: () => !document.querySelector('#pma-refreshElemnet > div > div').scrollTop,
    });
  }, [apiBody]);

  const listItems = useMemo(() => (
    <Box className={classes.listWarp} id="pma-refreshElemnet">
      <ListComponent
        language={language}
        data-testid="scroll"
        items={records}
        loadMore={fetchMoreListItems}
        hasNextPage={hasNextPage}
        inputRef={inputRef}
      />
    </Box>
  ), [records, fetchMoreListItems, hasNextPage]);

  const inputOnChange = (event) => {
    onChange(event.target.value);
  };

  const keys = Object.keys(filterValue);
  const values = keys.filter(key => filterValue[key]);
  return (
    <>
      {sessionStorage.getItem('isWebApp') && (
        <Box className={classes.topbar}>
          <Box
            data-testid="toolbar"
            id="admin-pma-toolbar"
            component="div"
            className={classes.toolbar}
          >
            {isMobile() && (
              <MuiButton
                className={classes.gridButton1}
                id="back-home-button-pma"
                onClick={backHome}
              >
                <svg width="60" height="44" viewBox="0 0 60 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M23.3872 15.2096C23.7795 14.9047 24.3467 14.9324 24.7072 15.293L24.7904 15.3872C25.0953 15.7795 25.0676 16.3467 24.707 16.7072L20.4132 21H32C32.5523 21 33 21.4477 33 22C33 22.5128 32.614 22.9355 32.1166 22.9933L32 23H20.4167L24.7071 27.2916L24.7903 27.3858C25.0953 27.7781 25.0676 28.3453 24.7071 28.7058C24.3166 29.0963 23.6834 29.0963 23.2929 28.7058L17.3241 22.737C17.1249 22.5542 17 22.2917 17 22C17 21.9992 17 21.9983 17 21.9975C17.0003 21.7419 17.098 21.4865 17.293 21.2915L23.293 15.2928L23.3872 15.2096Z" fill="#181818" />
                </svg>

                {/* {toolBarObject.close} */}
              </MuiButton>
            )}
            <Typography
              variant="h4"
              style={{
                alignSelf: isMobile() ? 'center' : 'unset',
                flexGrow: 1,
                fontSize: '16px',
                margin: isMobile() ? 'unset' : 24,
                // eslint-disable-next-line
                textAlignLast: isMobile() ? 'center' : language === 'ar' ? 'right' : 'left',
              }}
            >
              {title}
            </Typography>
            <MuiButton
              color="secondary"
              variant="contained"
              className={classes.gridButton2}
              id="pma-toolbar-right-button"
            />
          </Box>
        </Box>
      )}
      <Box className={classes.background}>
        <Card className={classes.search}>
          <Box pb={3} pl={5} pr={5} pt={3} hidden>
            <Box position="relative">
              <InputField
                onChange={inputOnChange}
                priority="secondary"
                name="search"
                id="pma-search"
                defaultValue={search}
                fullWidth
                classes={{ notchedOutline: classes.noBorder, root: classes.inputRoot }}
                inputProps={{
                  className: classes.searchInput,
                  onBlur: triggerPlaceholder,
                }}
                inputRef={inputRef}
              />
              {showPlaceholder && (
                <Box
                  className={classes.placeholderMask}
                  data-testid="search-placeholder"
                  id="search-placeholder"
                  onClick={inputFocus}
                >
                  <Box className={classes.iconContainer}>
                    <SearchIcon />
                  </Box>
                  <Typography variant="h4" weight="light">{searchPlaceHolder}</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Card>
        <AvonContainer maxWidth="lg" id="pma-AvonContainer">
          <Box pt={6} pb={5} hidden justifyContent="space-between">
            <Typography color="textPrimary" display="inline">{title}</Typography>
            <Badge
              badgeContent={values.length}
              color="primary"
              classes={{ badge: classes.badge }}
            >
              <AvonIcon
                type="sort"
                alt="sort-icon"
                id="pma-sort-icon"
                onClick={handleClickOpen}
                width="24px"
                height="24px"
              />
            </Badge>
          </Box>
          {listItems}
        </AvonContainer>
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} id="pma-dialog">
          <Box
            pb={5}
            pl={language === 'ar' ? 'unset' : 4}
            pr={language === 'ar' ? 4 : 'unset'}
            pt={5}
            onClick={handleClose}
          >
            <Box className={classes.image}>
              <AvonIcon
                type="back"
                alt="back-icon"
                id="pma-back-icon"
                height="36px"
                width="36px"
              />
            </Box>
            <Typography variant="body1" weight="bold" display="inline">{sortFilter.back}</Typography>
          </Box>
          <Box align="center" pb={4}>
            <Typography classes={{ root: classes.filterTitleText }} variant="h2" weight="light" display="inline" align="center">
              {sortFilter.title}
            </Typography>
          </Box>
          <FilterAndSort
            setOpen={setOpen}
            {...sortAndFilter}
          />
        </Dialog>
      </Box>
    </>
  );
};


export default RepCardContainer;
