import React, { useCallback } from 'react';
import { Button } from '@avon/component-library';
import {
  object, func, string, shape, bool,
} from 'prop-types';
import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Avatar from '@material-ui/core/Avatar';
import successIcon from '../../styles/assets/icons/success.svg';
import warnIcon from '../../styles/assets/icons/warn.svg';

const DialogBody = ({
  heading,
  description,
  submitButton,
  closeButton: { label: closeLabel, priority: closePriority },
  handleSubmit,
  closeModal,
  isSubmitting,
  icon,
  iconType,
}) => {
  const onClick = useCallback(() => {
    if (!isSubmitting) handleSubmit();
  }, [isSubmitting, handleSubmit]);

  const icons = {
    success: <Box textAlign="center" mt={6}><Avatar style={{ width: 64, height: 64, display: 'inline-block' }} src={successIcon} /></Box>,
    warn: <Box textAlign="center" mt={6}><Avatar style={{ width: 64, height: 64, display: 'inline-block' }} src={warnIcon} /></Box>,
  };

  const iconColors = {
    success: '#1a8282',
    warn: '#fa6400',
  };

  return (
    <>
      <DialogContent>
        <DialogTitle id="form-dialog-title">
          {icon && icons[iconType]}
          <Box textAlign="center" mx={6} mt={3} mb={0} style={{ fontSize: 22, fontFamily: 'Montserrat-Light', color: icon ? iconColors[iconType] : '#181818' }}>
            {heading}
          </Box>
        </DialogTitle>
        <DialogContentText>
          {description}
        </DialogContentText>
        <DialogActions>
          {submitButton && (
            <Button name="lma-dialog-continue" id="lma-dialog-continue" onClick={onClick} loading={isSubmitting} priority={submitButton.priority} fullwidth>
              {submitButton.label}
            </Button>
          )}
        </DialogActions>
        <DialogActions>
          <Button name="lma-dialog-close-modal" id="lma-dialog-close-modal" onClick={closeModal} priority={closePriority} fullwidth>
            {closeLabel}
          </Button>
        </DialogActions>
      </DialogContent>
    </>
  );
};

DialogBody.propTypes = {
  heading: string,
  description: string,
  submitButton: object,
  closeButton: shape({
    closeLabel: string,
    priority: string,
  }),
  handleSubmit: func,
  closeModal: func,
  isSubmitting: bool,
  icon: bool,
  iconType: string,
};

export default DialogBody;
