import React, { useContext, useEffect, useState } from 'react';
import { useError } from '@avon/component-library';
import SkeletonLoader from '../SkeletonLoader';
import PmaContext from '../../PmaContext';
import Profile from '../Profile';
import { getAllDataProspect } from '../../../utils/api';

const ProfileContainer = () => {
  const [profile, setProfile] = useState();
  const [{ apptId, callApi }] = useContext(PmaContext);
  const { setError, cleanToastBanner } = useError();
  useEffect(() => {
    async function fetchData() {
      try {
        const data = await callApi(getAllDataProspect(apptId));

        const {
          rep, notifications,
        } = data;

        const actionFunctions = {};
        const { name } = data.rep.prospect;

        const fullName = name;

        setProfile({
          rep, actionFunctions, fullName, notifications,
        });

        cleanToastBanner();
      } catch ({ error }) {
        error.reCall = fetchData;
        error.args = [];
        setError(error);
      }
    }
    fetchData();
  }, [apptId, callApi, setError]);

  return (
    <>
      {profile ? (<Profile {...profile} />) : <SkeletonLoader />}
    </>
  );
};

export default ProfileContainer;
