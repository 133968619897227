import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {
  func, string, object, bool,
} from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import useTheme from '@material-ui/styles/useTheme';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  ButtonContainer: {
    display: 'flex',
    margin: e => (e.type === 'email' ? '16px auto' : '2px auto 18px'),
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  saveButton: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    height: '32px',
    padding: '4px 16px',
    border: `2px solid ${theme.palette.colors.corePink} `,
    borderRadius: '4px',
    textTransform: 'uppercase',
    fontWeight: 700,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  cancelButton: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    height: '32px',
    padding: '4px 16px',
    borderRadius: '4px',
    textTransform: 'uppercase',
    fontWeight: 700,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const EditSaveButton = ({
  saveLabel, cancelLabel, editLabel, type, values,
  savePhoneEmail, setRestoreValue, language, edit,
  isSubmitting, setEditPhone, setEditEmail,
}) => {
  const classes = useStyles({ type });
  const theme = useTheme();
  const [clickSave, setClickSave] = useState(false);

  const progressProps = {
    size: '1em',
    color: 'primary',
  };

  return (
    <Box id={`${type}-EditSaveButton`}>
      {edit
        ? (
          <div className={classes.ButtonContainer}>
            {isSubmitting && clickSave
              ? <CircularProgress {...progressProps} style={{ marginTop: 16 }} id={`${type}-CircularProgress`} />
              : (
                <>
                  <Box
                    id={`${type}-save-button`}
                    className={classes.saveButton}
                    onClick={() => { savePhoneEmail(values, type); setClickSave(true); }}
                  >
                    <Typography
                      id={`${type}-save-label`}
                      color="primary"
                      style={{ fontFamily: 'Montserrat-bold' }}
                    >
                      {saveLabel}
                    </Typography>
                  </Box>
                  <Box
                    className={classes.cancelButton}
                    id={`${type}-cancel-button`}
                    onClick={() => {
                      setEditPhone(false);
                      setEditEmail(false);
                      setRestoreValue(true);
                    }}
                  >
                    <Typography
                      id={`${type}-cancel-label`}
                      color="primary"
                      style={{ fontFamily: 'Montserrat-bold' }}
                    >
                      {cancelLabel}
                    </Typography>
                  </Box>
                </>
              )}
          </div>
        )
        : (
          <Box
            className={classes.ButtonContainer}
            id={`${type}-edit-button`}
            onClick={() => {
              setRestoreValue(false);
              if (type === 'phone') {
                setEditPhone(true);
                setEditEmail(false);
              } else {
                setEditPhone(false);
                setEditEmail(true);
              }
              // setRestoreValue(true);
            }}
          >
            <Typography
              id={`${type}-edit-label`}
              color="primary"
              style={{ fontFamily: 'Montserrat-bold' }}
            >
              {editLabel}
            </Typography>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ marginLeft: 10, transform: language === 'ar' ? 'rotateY(180deg)' : 'rotateY(0)' }}>
              <g fill="none" fillRule="evenodd">
                <path d="M0 0H24V24H0z" />
                <path fill={theme.palette.colors.corePink} fillRule="nonzero" d="M14.295 4.121c1.495-1.495 3.919-1.495 5.414 0 1.495 1.495 1.495 3.92 0 5.415l-9 9c-.11.11-.244.192-.391.241l-6 2c-.782.26-1.526-.483-1.265-1.265l2-6c.05-.147.132-.28.242-.39zM13.5 7.742l-6.626 6.627-1.292 3.878 3.877-1.293 6.627-6.626-2.586-2.586zm4.794-2.206c-.714-.715-1.872-.715-2.586 0l-.794.792 2.586 2.586.794-.793c.634-.634.705-1.62.211-2.332l-.099-.13z" />
              </g>
            </svg>
          </Box>
        )
      }
    </Box>
  );
};

EditSaveButton.propTypes = {
  saveLabel: string,
  cancelLabel: string,
  editLabel: string,
  type: string,
  values: object,
  savePhoneEmail: func,
  setRestoreValue: func,
  language: string,
  edit: bool,
  isSubmitting: bool,
  setEditPhone: func,
  setEditEmail: func,
};

export default EditSaveButton;
