import React, {
  useEffect, useContext,
} from 'react';
import Box from '@material-ui/core/Box';
import MuiButton from '@material-ui/core/Button';
import makeStyles from '@material-ui/styles/makeStyles';
import { string, object } from 'prop-types';
import {
  PageTabs, SummaryCard, Typography,
} from '@avon/component-library';
import PmaContext from '../../PmaContext';
import ActionTab from '../ActionTab';
import formatDate from '../../../utils/formatDate';

import { useTracking } from '../../../analytics/Analytics';
import { pmaProfileBack } from '../../../utils/api';
import { isMobile } from '../../../utils/getDeviceType';
import noop from '../../../utils/noop';

const useStyles = makeStyles(theme => ({
  nameText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
    },
  },
  backButton: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  image: {
    verticalAlign: 'middle',
    paddingLeft: styleProps => (styleProps.language === 'ar' ? 'unset' : theme.spacing(2)),
    paddingRight: styleProps => (styleProps.language === 'ar' ? theme.spacing(2) : 'unset'),
    transform: styleProps => (styleProps.language === 'ar' ? 'rotateY(180deg)' : 'rotateY(0)'),
    display: 'inline-block',
  },
  timer: {
    display: 'inline-block',
    marginLeft: styleProps => (styleProps.language === 'ar' ? 'unset' : theme.spacing(2)),
    marginRight: styleProps => (styleProps.language === 'ar' ? theme.spacing(2) : 'unset'),
  },
  alertContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  remainingLabel: {
    color: theme.palette.colors.textLight,
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    overflowY: 'hidden',
  },
  stusLabel: {
    borderRadius: 10,
    height: 20,
    padding: '2px 10px 0px 10px',
  },
  topbar: {
    zIndex: 102,
    top: 0,
    position: 'sticky',
  },
  toolbar: {
    display: 'flex',
    zIndex: 102,
    width: '100%',
    textAlign: 'justify',
    textAlignLast: 'justify',
    paddingTop: theme.spacing(2.5),
    paddingLeft: styleProps => (styleProps.language === 'ar' ? theme.spacing(3) : theme.spacing(1)),
    paddingRight: styleProps => (styleProps.language === 'ar' ? theme.spacing(1) : theme.spacing(3)),
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.colors.white,
    '& span': {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 16,
    },
  },
  gridButton1: {
    minHeight: theme.spacing(3),
    width: 'auto',
    paddingTop: 0,
    paddingBottom: 0,
    wordBreak: 'break-word',
    color: theme.palette.colors.textDark,
  },
  gridButton2: {
    minHeight: theme.spacing(3),
    width: 'auto',
    paddingTop: 0,
    paddingBottom: 0,
    wordBreak: 'break-word',
    color: theme.palette.colors.corePink,
    '&:hover': {
      backgroundColor: 'rgba(24, 24, 24, 0.08)',
    },
  },
}));

const Profile = (
  {
    rep, fullName, actionFunctions, notifications,
  },
) => {
  const [
    {
      language,
      leadsContent: {
        profile: {
          tables,
          actionContent,
          profileContent,
          modals,
        },
        // sortFilter: { back },
      },
    },
  ] = useContext(PmaContext);


  const classes = useStyles({ language });
  // const { prospect } = rep;

  // const [isExpired, setIsExpired] = useState(checkTime(stusEndDtStr, 0));
  // const [isExpiring, setIsExpiring] = useState(checkTime(stusEndDtStr, EXPIRING_TIMEOUT));
  const [, setTracking] = useTracking();
  const { label: actionLabel } = actionContent;
  const {
    label: profileLabel, toolbarTitle, title, backlink,
  } = profileContent;

  useEffect(() => {
    setTracking({ eventType: 'pageview', eventName: 'pmaProfile' });
  }, [setTracking]);

  useEffect(() => {
    if (!sessionStorage.getItem('isWebApp') && (window.Android || window.navigator.userAgent.match(/(iPhone)|(iPad)|(Macintosh)/i))) {
      pmaProfileBack(toolbarTitle, backlink);
    }
  }, []);

  const backHome = () => {
    if (sessionStorage.getItem('isWebApp')) {
      window.parent.postMessage({ menu: 'prospectPage', subMenu: 'saved', params: `ver=${new Date()}` }, '*');
    } else {
      noop();
    }
  };

  const tableObjectMap = (properties) => {
    const { items, key, heading } = properties;
    const row = items
      .filter(({ key: itemKey }) => rep[key][itemKey])
      .map((item) => {
        const {
          key: itemKey, label, translation, type, format: dateFormat, link, width,
        } = item;

        return (
          translation
            ? {
              label,
              // eslint-disable-next-line no-nested-ternary
              value: type === 'date' ? formatDate(rep[key][translation], language, dateFormat) : (typeof rep[key][translation] === 'object' ? rep[key][translation].join('\r\n') : rep[key][translation]),
              // eslint-disable-next-line no-nested-ternary
              wrap: typeof rep[key][translation] === 'object',
              link,
              width,
              isMobile: isMobile(),
            }
            : {
              label,
              // eslint-disable-next-line no-nested-ternary
              value: type === 'date' ? formatDate(rep[key][itemKey], language, dateFormat) : (typeof rep[key][itemKey] === 'object' ? rep[key][itemKey].join('\r\n') : rep[key][itemKey]),
              // eslint-disable-next-line no-nested-ternary
              wrap: typeof rep[key][itemKey] === 'object',
              link,
              width,
              isMobile: isMobile(),
            });
      });

    return {
      key,
      heading,
      row,
    };
  };

  const renderTable = tables
    .filter(({ key }) => rep[key])
    .filter(({ key }) => !(Object.keys(rep[key]).length === 1 && Object.keys(rep[key]).includes('defaultPage')))
    .map((properties) => {
      const { row, heading, key } = tableObjectMap(properties);
      const props = {};
      // props.editOnClick = () => { editAppointment(apptId, key); };
      return row.length > 0 ? (
        <Box
          pb={1}
          key={`box-${key}-${heading}`}
          style={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}
        >
          <SummaryCard
            key={`card-${key}-${heading}`}
            heading={heading}
            items={row}
            language={language}
            {...props}
          />
        </Box>
      )
        : <Box pb={1} key={`box-${key}-${heading}`} />;
    });


  const tabs = [
    {
      label: profileLabel,
      content:
        (
          <Box p={3} bgcolor="colors.lightZircon">
            <Box
              px={4}
            >
              {renderTable}
            </Box>
          </Box>
        ),

    },
    {
      label: actionLabel,
      content: (
        <ActionTab
          {...modals}
          {...actionContent}
          {...actionFunctions}
          notifications={notifications}
          email={(rep && rep.prospect
            && rep.prospect.email) || ''}
        />
      ),

    },
  ];

  return (
    <Box className={classes.actionContainer}>
      {sessionStorage.getItem('isWebApp') && (
      <Box className={classes.topbar}>
        <Box
          data-testid="toolbar"
          id="admin-pma-toolbar"
          component="div"
          className={classes.toolbar}
        >
          <MuiButton
            className={classes.gridButton1}
            id="back-home-button-pma"
            onClick={backHome}
          >
            <svg width="60" height="44" viewBox="0 0 60 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M23.3872 15.2096C23.7795 14.9047 24.3467 14.9324 24.7072 15.293L24.7904 15.3872C25.0953 15.7795 25.0676 16.3467 24.707 16.7072L20.4132 21H32C32.5523 21 33 21.4477 33 22C33 22.5128 32.614 22.9355 32.1166 22.9933L32 23H20.4167L24.7071 27.2916L24.7903 27.3858C25.0953 27.7781 25.0676 28.3453 24.7071 28.7058C24.3166 29.0963 23.6834 29.0963 23.2929 28.7058L17.3241 22.737C17.1249 22.5542 17 22.2917 17 22C17 21.9992 17 21.9983 17 21.9975C17.0003 21.7419 17.098 21.4865 17.293 21.2915L23.293 15.2928L23.3872 15.2096Z" fill="#181818" />
            </svg>

            {/* {toolBarObject.close} */}
          </MuiButton>

          <Typography
            variant="h4"
            style={{
              alignSelf: isMobile() ? 'center' : 'unset',
              flexGrow: 1,
              fontSize: '16px',
              margin: isMobile() ? 'unset' : 24,
              // eslint-disable-next-line
              textAlignLast: isMobile() ? 'center' : language === 'ar' ? 'right' : 'left',
            }}
          >
            {title}
          </Typography>
          <MuiButton
            color="secondary"
            variant="contained"
            className={classes.gridButton2}
            id="pma-toolbar-right-button"
          />
        </Box>
      </Box>
      )}
      <Box p={5} id="pma-dispatch-container">
        <Box mt={3} mb={1} justifyContent="center" display="flex" alignItems="center">
          <div
            className={classes.stusLabel}
            style={{ background: '#BAD9D9' }}
          >
            {title}
          </div>
        </Box>
        <Typography classes={{ root: classes.nameText }} variant="h2" weight="light" align="center" noWrap>{fullName}</Typography>
      </Box>
      <PageTabs tabs={tabs} />
    </Box>
  );
};

Profile.propTypes = {
  rep: object.isRequired,
  fullName: string.isRequired,
  actionFunctions: object.isRequired,
  notifications: object,
};

export default Profile;
