import React, {
  useState, useEffect,
} from 'react';
import Helmet from 'react-helmet';
import { Typography, AvonContainer, useError } from '@avon/component-library';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { func, string } from 'prop-types';
import DateSelector from './components/DateSelector';
import CardLayout from './components/Card';
import DataElement from './components/DataElements';
import { getStatsContent, getStatsData } from '../utils/api';

import { useTracking } from '../analytics/Analytics';

const Statistics = ({ callApi, language, market }) => {
  const theme = useTheme();
  const height = useMediaQuery(theme.breakpoints.up('md')) ? 400 : 360;
  const [content, setContent] = useState({
    title: '', description: '', dateOptions: [], dataElements: [],
  });
  const { setError, cleanToastBanner } = useError();
  const [data, setData] = useState();
  const [active, setActive] = useState({ from: null, to: null });
  const timeZoneOffset = -(new Date()).getTimezoneOffset();
  const [, setTracking] = useTracking();

  // Analytics
  useEffect(() => {
    setTracking({ eventType: 'pageview', eventName: 'prpStatsPage' });
  }, [setTracking]);

  useEffect(() => {
    const getStatic = async () => {
      try {
        const res = await callApi(getStatsContent(timeZoneOffset));
        setActive(res.jsonObject.dateOptions[0].range);
        setContent(res.jsonObject);
        cleanToastBanner();
      } catch ({ error }) {
        error.reCall = getStatic;
        error.args = [];
        setError(error);
      }
    };
    getStatic();
  }, [callApi, timeZoneOffset]);

  useEffect(() => {
    const getData = async () => {
      try {
        const options = {
          dataElementsReq: content.dataElements.map(({ type, elementId }) => ({
            type: type.toUpperCase(), elementId,
          })),
          startTime: active.from,
          endTime: active.to,
          timeZoneOffset,
        };
        setData(await callApi(getStatsData(options)));
        cleanToastBanner();
      } catch ({ error }) {
        error.reCall = getData;
        error.args = [];
        setError(error);
      }
    };
    if (active.from && content.dataElements.length) getData();
  }, [content.dataElements, callApi, active.from, active.to, timeZoneOffset]);
  switch (language) {
    case 'ar':
      // set direction to rtl
      document.body.dir = 'rtl';
      break;

    case 'ru':
    case 'tr':
    case 'tl':
      // set direction to ltr
      document.body.dir = 'ltr';
      break;

    default:
      // Do nothing
      break;
  }
  return (
    <>
      <Helmet htmlAttributes={{ lang: language || 'en' }} />
      <Box bgcolor="colors.lightZircon" py={theme.spacing(1)} minHeight="100%">
        {!!content.dateOptions.length && (
          <AvonContainer maxWidth="lg" id="admin-statistic-container">
            <Grid container spacing={theme.spacing(1)} id="admin-statistic-grid-1">
              <Grid item xs={12} id="admin-statistic-grid-11">
                <CardLayout>
                  <Box data-testid="stats-content">
                    <Box my={theme.spacing(1)}>
                      <Typography
                        color="textPrimary"
                        variant="h1"
                        weight="thin"
                        align={language === 'ar' ? 'right' : 'left'}
                      >
                        {content.title}
                      </Typography>
                    </Box>
                    <Box mt={theme.spacing(1)} mb={theme.spacing(2)}>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                        weight="regular"
                        align={language === 'ar' ? 'right' : 'left'}
                      >
                        {content.description}
                      </Typography>
                    </Box>
                    <Box my={theme.spacing(2)}>
                      <DateSelector
                        labels={content.selectorLabels}
                        options={content.dateOptions}
                        onChange={setActive}
                        language={language}
                        market={market}
                      />
                    </Box>
                  </Box>
                </CardLayout>
              </Grid>

              {content.dataElements.map(
                (
                  {
                    title, description, fullScreen, elementId, type, stack,
                  },
                  index,
                ) => {
                  const { values, dataKeys } = data
                    ? data.dataElements[elementId]
                    : { values: null, dataKeys: null };
                  return (
                    <Grid key={index} item lg={fullScreen ? 12 : 6} xs={12} id={`admin-statistic-grid-2-${index}`}>
                      <CardLayout heading={title} description={description} language={language}>
                        <DataElement
                          index={index}
                          data={values}
                          dataKeys={dataKeys}
                          height={height}
                          type={type}
                          stack={stack}
                          language={language}
                          market={market}
                        />
                      </CardLayout>
                    </Grid>
                  );
                },
              )}
            </Grid>
          </AvonContainer>
        )}
      </Box>
    </>
  );
};

Statistics.propTypes = {
  callApi: func.isRequired,
  language: string.isRequired,
  market: string,
};

export default Statistics;
