import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@avon/component-library';
import './styles/global-styles.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import StatisticsPage from './statisticsPage';
import PrpMan from './prpMan';
import Lma from './lma';
import Pma from './pma';
import Prospect from './prospect';
import Profile from './lma/components/Profile';

const getQueryString = (name) => {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
  const r = decodeURI(window.location.search.substr(1)).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
};

const azureKey = window._env_.REACT_APP_AZURE_KEY;
const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: azureKey,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
if (azureKey) {
  appInsights.loadAppInsights();
}
const originToken = sessionStorage.getItem('accessToken');
const App = () => {
  const [accessToken, setAccessToken] = useState();
  const [ver, setVer] = useState(0);
  useEffect(() => {
    const token = getQueryString('accessToken') || sessionStorage.getItem('accessToken');
    setAccessToken(token);
  }, [ver]);

  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.data && e.data.accessToken) {
        if (!originToken || originToken !== e.data.accessToken) {
          sessionStorage.setItem('accessToken', e.data.accessToken);
          setVer(ver + 1);
        }
      }
      if (e.data && e.data.isWebApp) {
        sessionStorage.setItem('isWebApp', e.data.isWebApp);
      }
      if (e.data && e.data.search) {
        sessionStorage.setItem('searchHis', e.data.search);
      } else {
        sessionStorage.setItem('searchHis', '');
      }
    });
  }, [accessToken]);
  return accessToken ? (
    <ThemeProvider market={getQueryString('market')}>
      <CssBaseline />
      <Switch>
        <Route exact path="*/stats" component={StatisticsPage} />
        <Route exact path="*/prpman" component={PrpMan} />
        <Route exact path="*/prospect" component={Prospect} />
        <Route exact path="*/lma" component={Lma} />
        <Route exact path="*/pma" component={Pma} />
        <Route path="*/lma/profile" component={Profile} />
      </Switch>
    </ThemeProvider>
  ) : null;
};

export default App;
