import React from 'react';
import {
  number, string, shape, oneOfType,
} from 'prop-types';

export const CustomTick = ({
  x, y, payload: { value }, language,
}) => (
  <g className="recharts-layer recharts-cartesian-axis-tick">
    <text
      stroke="none"
      fill="#a2acae"
      x={x}
      y={y}
      className="recharts-text recharts-cartesian-axis-tick-value"
      textAnchor="middle"
    >
      <tspan dy="0.355em">
        {
        language === 'hu' && value.toString().split(' ').length > 1
          ? (`${value.toString().split(' ').reverse().join(' ')
            .replace('.', '')}.`) : value
      }
      </tspan>
    </text>
  </g>
);

const xAxisProps = (type, language) => {
  const horizontal = (
    {
      dataKey: 'name',
      tickLine: false,
      axisLine: false,
      dot: false,
      activeDot: false,
      stroke: 'black',
      allowDecimals: false,
      interval: 'preserveStart',
      // hardcode hu date format, for all markets need backend adpater
      tick: (<CustomTick language={language} />),
      minTickGap: 40,
      reversed: language === 'ar',
    }
  );

  const vertical = (
    {
      type: 'number',
      tickLine: false,
      axisLine: false,
      allowDecimals: false,
      interval: 'preserveStartEnd',
      reversed: language === 'ar',
    }
  );

  switch (type) {
    case 'horizontal':
      return horizontal;
    case 'vertical':
      return vertical;
    default:
      return horizontal;
  }
};

CustomTick.propTypes = {
  x: number,
  y: number,
  value: string,
  language: string,
  payload: shape({
    value: oneOfType([string, number]).isRequired,
  }),
};
export default xAxisProps;
