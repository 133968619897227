import React, {
  useState, useEffect, useContext, useMemo,
} from 'react';
import dayjs from 'dayjs';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import { string, object } from 'prop-types';
import {
  PageTabs, SummaryCard, Button, Typography, AvonIcon,
  Alert,
} from '@avon/component-library';
import LmaContext from '../../LmaContext';
import ActionTab from '../ActionTab';
import Timer from '../Timer';
import formatDate from '../../../utils/formatDate';
import alarmIcon from './assets/alarm.svg';

import { useTracking } from '../../../analytics/Analytics';
import { isMobile } from '../../../utils/getDeviceType';

const MAX_SAFE = 1000000000;
const EXPIRING_TIMEOUT = 60000;

const msRemaining = endDate => dayjs(endDate) - dayjs();

const checkTime = (stusEndDt, targetTime) => (
  !stusEndDt ? false : (msRemaining(stusEndDt) <= targetTime)
);

const useStyles = makeStyles(theme => ({
  nameText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
    },
  },
  backButton: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  image: {
    verticalAlign: 'middle',
    paddingLeft: styleProps => (styleProps.language === 'ar' ? 'unset' : theme.spacing(2)),
    paddingRight: styleProps => (styleProps.language === 'ar' ? theme.spacing(2) : 'unset'),
    transform: styleProps => (styleProps.language === 'ar' ? 'rotateY(180deg)' : 'rotateY(0)'),
    display: 'inline-block',
  },
  timer: {
    display: 'inline-block',
    marginLeft: styleProps => (styleProps.language === 'ar' ? 'unset' : theme.spacing(2)),
    marginRight: styleProps => (styleProps.language === 'ar' ? theme.spacing(2) : 'unset'),
  },
  alertContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > div': {
      padding: 0,
    },
  },
  remainingLabel: {
    color: theme.palette.colors.textLight,
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    overflowY: 'hidden',
    '& #tabpanel-key-0': {
      height: 'calc(100% - 280px)',
    },
  },
  stusLabel: {
    borderRadius: 10,
    height: 20,
    padding: '2px 10px 0px 10px',
    marginTop: 24,
  },
}));

const stusLabelColors = {
  new: '#bad9d9',
  in_progress: '#e3e6e6',
  awaiting_acceptance: '#bad9d9',
  rejected: '#fae2da',
  pending: '#eaf6f8',
};

const Profile = (
  {
    rep, fullName, actionFunctions, link, currentUser,
  },
) => {
  const [
    {
      statusColors,
      language,
      market,
      leadsContent: {
        profile: {
          close,
          finalizeAppointment,
          tables,
          actionContent,
          profileContent,
          modals,
          timerLabelMap,
        },
        sortFilter: { back },
      },
      apptId,
      editAppointment,
    },
    dispatch,
  ] = useContext(LmaContext);

  const isWebApp = sessionStorage.getItem('isWebApp') && !isMobile();
  const classes = useStyles({ language });
  const {
    remainingTimeLabel, stusEndDtStr, stusCd, stusLabel, subStusCd, pageCode,
  } = rep;

  const userId = (currentUser && currentUser.userId) || '';

  const [isExpired, setIsExpired] = useState(checkTime(stusEndDtStr, 0));
  const [isExpiring, setIsExpiring] = useState(checkTime(stusEndDtStr, EXPIRING_TIMEOUT));
  const [, setTracking] = useTracking();
  const { label: actionLabel } = actionContent;
  const { label: profileLabel } = profileContent;

  useEffect(() => {
    setTracking({ eventType: 'pageview', eventName: 'lmaProfile' });
  }, [setTracking]);

  const statusColor = useMemo(() => statusColors[stusCd], [isExpired, stusCd, statusColors]);

  const setTimer = () => {
    const remaining = msRemaining(stusEndDtStr);
    return setTimeout(() => {
      if (isExpiring) {
        setIsExpired(true);
      } else if (remaining < EXPIRING_TIMEOUT) {
        setIsExpiring(true);
      } else {
        setTimer();
      }
    }, (Math.min(MAX_SAFE, remaining - (isExpiring ? 0 : EXPIRING_TIMEOUT))));
  };

  useEffect(() => {
    if (stusEndDtStr) {
      const t = setTimer();
      return () => clearTimeout(t);
    }
    return () => { };
  }, [stusEndDtStr, isExpiring]);

  const tableObjectMap = (properties) => {
    const { items, key, heading } = properties;
    const row = items
      .filter(({ key: itemKey, itemHeader }) => itemHeader || (rep[key][itemKey] && rep[key][itemKey] !== '###undefined###'))
      .map((item) => {
        const {
          // eslint-disable-next-line max-len
          key: itemKey, label, translation, type, format: dateFormat, itemHeader, itemHeaderMt = 24, link: hrefTo, width,
        } = item;

        // eslint-disable-next-line no-nested-ternary
        return (itemHeader !== null && itemHeader !== undefined)
          ? { value: itemHeader, itemHeader: true, itemHeaderMt }
          : (
            translation
              ? {
                label,
                value: type === 'date' ? formatDate(rep[key][translation], language, dateFormat) : rep[key][translation],
                // eslint-disable-next-line no-nested-ternary
                wrap: typeof rep[key][translation] === 'object',
                link: hrefTo,
                width,
                isMobile: isMobile(),
              }
              : {
                label,
                value: type === 'date' ? formatDate(rep[key][itemKey], language, dateFormat) : rep[key][itemKey],
                // eslint-disable-next-line no-nested-ternary
                wrap: typeof rep[key][itemKey] === 'object',
                link: hrefTo,
                width,
                isMobile: isMobile(),
              });
      });

    return {
      key,
      heading,
      row,
    };
  };

  const renderTable = tables
    .filter(({ key }) => rep[key])
    .filter(({ key }) => !(Object.keys(rep[key]).length === 1 && Object.keys(rep[key]).includes('defaultPage')))
    .map((properties) => {
      const { showEditIcon = true } = properties;
      const { row, heading, key } = tableObjectMap(properties);
      const props = {};
      if (stusCd !== 'pending' && showEditIcon) {
        props.editOnClick = () => {
          const pageCodes = [
            'contactDetails',
            'homeAddress',
            'registrationAddress',
            'deliveryAddress',
            'creditNonCredit',
            'nonageCreditToggle',
            'passport',
            'addressConfirmation',
          ];
          if (pageCodes.indexOf(pageCode) === -1) {
            editAppointment(apptId, key, true);
          } else {
            editAppointment(apptId, key, false);
          }
        };
      }
      return (
        <Box
          pb={1}
          key={key}
        >
          <SummaryCard
            key={key}
            heading={heading}
            items={row}
            language={language}
            {...props}
          />
        </Box>
      );
    });


  const tabs = [
    {
      label: profileLabel,
      content:
        (
          <Box p={3} bgcolor="colors.lightZircon">
            <Box
              px={4}
            >
              {renderTable}
              <Box
                p={2}
              >
                {(stusCd !== 'awaiting_acceptance' && stusCd !== 'pending' && finalizeAppointment) && (
                  <Button
                    name="lma-appointment-edit"
                    id="lma-appointment-edit"
                    onClick={() => { editAppointment(apptId); }}
                  >
                    {finalizeAppointment}
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        ),

    },
    {
      label: actionLabel,
      content: (
        <ActionTab
          market={market}
          status={stusCd}
          subStus={subStusCd}
          currentUserId={userId}
          link={link}
          {...modals}
          {...actionContent}
          {...actionFunctions}
        />
      ),

    },
  ];

  const expiringMessage = useMemo(() => (
    <>
      <Typography component="span" variant="h4" weight="regular" style={{ color: statusColor }}>{'<1'}</Typography>
      <Typography component="span" variant="body2" weight="regular" style={{ color: statusColor }}>{timerLabelMap.minute}</Typography>
    </>
  ), [timerLabelMap.minute]);

  return isExpired ? (
    <Box bgcolor="colors.lightZircon" className={classes.alertContainer} style={{ padding: 0 }}>
      <Alert
        id="lma-dispatch-alert"
        heading={fullName}
        body={remainingTimeLabel}
        buttonLabel={close}
        iconType="cross"
        handleClick={() => { dispatch({ page: 'rep card', apptId: 0 }); }}
      />
    </Box>

  ) : (
    <Box className={classes.actionContainer} id="lma-action-container">
      <Box p={5} id="lma-dispatch-container">
        {isMobile()
          ? (
            <Box
              id="lma-dispatch-card"
              pb={3}
              onClick={() => dispatch({
                page: 'rep card',
                apptId: 0,
              })}
              className={classes.backButton}
            >
              <Box className={classes.image}>
                <AvonIcon
                  id="back-icon"
                  type="back"
                  alt="back"
                  height="36px"
                  width="36px"
                />
              </Box>
              <Typography variant="body1" weight="bold" display="inline">{back}</Typography>
            </Box>
          )
          : null
        }
        <Box
          mt={isWebApp ? 0 : 3}
          mb={isWebApp ? 0 : 1}
          justifyContent="center"
          display="flex"
          alignItems="center"
        >
          <div
            className={classes.stusLabel}
            style={{ background: stusLabelColors[stusCd] }}
          >
            {stusLabel}
          </div>
        </Box>

        {
          isWebApp ? (
            <Box style={{
              fontFamily: 'Montserrat-Light',
              fontSize: 32,
              lineHeight: '42px',
              color: '#181818',
              textAlign: 'center',
              paddingBottom: 12,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
            >
              {fullName}
            </Box>
          ) : (
            <Typography classes={{ root: classes.nameText }} variant="h2" weight="light" align="center" noWrap>{fullName}</Typography>
          )
        }
        <Box
          display="flex"
          flexDirection={isWebApp ? 'row' : 'column'}
          alignItems="center"
          justifyContent="center"
          height={isWebApp ? 24 : 'unset'}
        >
          <Box
            mt={isWebApp ? 0 : 3}
            mb={isWebApp ? 0 : 1}
            justifyContent="center"
            display="flex"
            alignItems="center"
          >
            {
              isWebApp ? (
                <Box width="16px" height="16px"><img src={alarmIcon} alt="alarm-icon" /></Box>
              ) : (
                <AvonIcon
                  color={statusColor}
                  type="clock"
                  alt="clock-icon"
                  id="clock-icon"
                  height="19px"
                  width="19px"
                />
              )
            }
            <Box
              ml={language === 'ar' ? 0 : 1.5}
              mr={language === 'ar' ? 1.5 : 0}
              style={{ color: statusColor }}
              data-testid="timer"
              id="timer"
            >
              {!isExpiring ? (
                <Timer
                  formatArray={['day', 'hour', 'minute']}
                  timerLabelMap={timerLabelMap}
                  countdown={msRemaining(stusEndDtStr)}
                  statusColor={isWebApp ? '#7F28C4' : statusColor}
                />
              ) : expiringMessage
              }
            </Box>
          </Box>
          {
            isWebApp ? (
              <Box style={{
                fontFamily: 'Montserrat',
                fontSize: 16,
                color: '#546264',
                marginLeft: 6,
              }}
              >
                {remainingTimeLabel}
              </Box>
            ) : (
              <Typography variant="body1" classes={{ root: classes.remainingLabel }} align="center" noWrap>
                {remainingTimeLabel}
              </Typography>
            )
          }
        </Box>
      </Box>
      <PageTabs tabs={tabs} />
    </Box>
  );
};

Profile.propTypes = {
  rep: object.isRequired,
  currentUser: object,
  fullName: string.isRequired,
  actionFunctions: object.isRequired,
  link: string.isRequired,
};

export default Profile;
