import React, {
  useContext, useState, useMemo, Fragment, useReducer,
} from 'react';

import Box from '@material-ui/core/Box';
import { object, string } from 'prop-types';
import { Button, useError, Typography } from '@avon/component-library';
import dayjs from 'dayjs';

import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import { sendEmailToProspect, prefillAppt, deleteProspect } from '../../../utils/api';
import DialogBody from '../DialogBody';
import PmaContext from '../../PmaContext';
import debounce from '../../../utils/debounce';
import { isMobile } from '../../../utils/getDeviceType';
import mailIcon from '../../styles/assets/icons/mail.svg';

const contentWrapper = children => (
  <Grid item xs={12}>
    <Box px={6} py={5} bgcolor="colors.white">
      {children}
    </Box>
  </Grid>
);

const ActionTab = ({
  // sharePrpButton: { label: sharePrpLabel, priority: sharePrpPriority },
  startApptButton: { label: startApptLabel, priority: startApptPriority },
  resendEmailButton: { label: resendEmailLabel, priority: emailPriority },
  rejectButton: { label: rejectLabel, priority: rejectPriority },
  emailModal,
  rejectModal,
  sharePrpModal,
  startApptModal,
  notifications,
  email,
  emailHasSentDesc1,
  emailHasSentDesc2,
  selectActionLabel1 = 'Select an action',
  selectActionLabel2 = 'Take an action over this lead',
}) => {
  const [{
    callApi, apptId, apiBody, editAppointment, sharePRP,
  }, dispatch] = useContext(PmaContext);

  const [currentModalType, setCurrentModalType] = useState('email');
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const initialArgs = {
    sharePrp: {
      action: () => sharePRP(),
    },
    startAppt: {
      action: () => callApi(prefillAppt(apptId)),
    },
    email: {
      action: () => callApi(sendEmailToProspect(apptId)),
    },
    reject: {
      action: () => callApi(deleteProspect(apptId)),
    },
  };

  const reducer = (state, { type }) => {
    const newState = state;
    newState[type].disabled = true;
    return newState;
  };

  const [buttonState, updateButtonState] = useReducer(reducer, initialArgs);

  const { setError, cleanToastBanner } = useError();

  const openModal = (type) => {
    if (confirmationOpen === true && modalOpen === false) {
      setTimeout(() => setConfirmationOpen(false), 500);
    }
    setCurrentModalType(type);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setConfirmationOpen(false);
  };

  const handleRejectClose = () => {
    const {
      pagination: { pageSize },
    } = apiBody;

    dispatch({
      apiBody: {
        ...apiBody, pagination: { pageSize, pageIndex: 0 },
      },
      page: 'prospect card',
      apptId: 0,
    });
  };

  const handleSubmit = async () => {
    try {
      const { action } = buttonState[currentModalType];
      setSubmitting(true);
      await action().then(
        response => (response.apptId && currentModalType === 'startAppt' ? editAppointment(apptId) : () => { }),
        300,
      ).then(() => setSubmitting(false));
      updateButtonState({ type: currentModalType });
      if (currentModalType !== 'startAppt' && !isSubmitting) {
        setConfirmationOpen(true);
      }
      cleanToastBanner();
    } catch ({ error }) {
      error.reCall = handleSubmit;
      error.args = [];
      setError(error);
      closeModal();
      setSubmitting(false);
    }
  };

  const emailHasSent = () => {
    let hasSent = false;
    if (notifications
      && notifications.prospectReg.history) {
      // eslint-disable-next-line no-restricted-syntax
      for (const sent of notifications.prospectReg.history) {
        const { status, sentDate } = sent;
        if (status && status === 'success') {
          hasSent = sentDate;
          return hasSent;
        }
      }
    }
    return hasSent;
  };

  const modalElement = useMemo(() => {
    const modals = {
      email: emailModal,
      reject: rejectModal,
      sharePrp: sharePrpModal,
      startAppt: startApptModal,
    };

    const isReject = currentModalType === 'reject';

    const Element = DialogBody;
    const continueFunc = isReject ? handleRejectClose : closeModal;
    const secondModal = modals[currentModalType].properties.secondModal.properties;
    const { submitButton } = secondModal;
    const emailSentTime = emailHasSent();
    const confirmModal = (currentModalType === 'email' && emailSentTime)
      ? ({
        ...modals[currentModalType].properties,
        description: `${emailHasSentDesc1} ${dayjs(new Date(`${emailSentTime.substr(0, 23)}+00:00`)).format('DD/MM/YY HH:mm:ss')}${emailHasSentDesc2}`,
      })
      : modals[currentModalType].properties;

    // eslint-disable-next-line
    const props = confirmationOpen && modalOpen ? (currentModalType !== 'startAppt' ? ({
      closeModal: submitButton ? closeModal : continueFunc,
      handleSubmit: submitButton ? handleRejectClose : null,
      isSubmitting,
      ...secondModal,
    }) : null) : ({
      isSubmitting,
      handleSubmit: debounce(() => handleSubmit(), 1000),
      closeModal,
      ...confirmModal,
    });

    if (confirmationOpen === true
      && modalOpen === true
      && isSubmitting === false
      && props === null) {
      setConfirmationOpen(false);
      setModalOpen(true);
    }
    return props && (
      <Element
        {...props}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentModalType, confirmationOpen, isSubmitting]);

  const resendButtonProps = [
    // {
    //   type: 'sharePrp',
    //   labels: {
    //     resend: sharePrpLabel,
    //   },
    //   priority: sharePrpPriority || 'primary',
    //   needOpenModal: false,
    // },
    {
      type: 'startAppt',
      labels: {
        resend: startApptLabel,
      },
      priority: startApptPriority || 'primary',
      needOpenModal: true,
    },
    {
      type: 'email',
      labels: {
        // resend: notifications && notifications.prospectReg ? false : resendEmailLabel,
        resend: email && resendEmailLabel,
      },
      priority: emailPriority || 'secondary',
      needOpenModal: true,
      icon: isMobile() ? null : <img alt="mail" src={mailIcon} />,
    },
    {
      type: 'reject',
      labels: {
        resend: rejectLabel,
      },
      priority: isMobile() ? (rejectPriority || 'tertiary') : 'secondary',
      needOpenModal: true,
    },
  ];

  const resendButtons = resendButtonProps.reduce((previous, {
    type, labels: { resend }, priority, needOpenModal, icon,
  }) => {
    const updated = previous;

    if (resend) {
      updated.push(
        <Grid item xs={12} sm={12} md={6} style={{ margin: isMobile() ? '12px 6px' : 'unset' }}>
          <Fragment key={`pma-open-${type}-modal-button-${apptId}`}>
            <Button
              onClick={needOpenModal ? () => openModal(type) : initialArgs[type].action}
              priority={priority}
              name={`pma-open-${type}-modal`}
              id={`pma-open-${type}-modal`}
              startIcon={icon}
              disabled={isSubmitting}
            >
              {resend}
            </Button>
          </Fragment>
        </Grid>,
      );
    }

    return updated;
  }, []);

  return (
    <>
      <Grid container spacing={3}>
        {isMobile() ? (!!resendButtons.length && contentWrapper(
          <>
            <Box py={5}>
              {resendButtons}
            </Box>
          </>,
        ))
          : (
            contentWrapper(
              <Grid container>
                <Grid item xs={12} sm={6} md={6} style={{ paddingBottom: 12 }}>
                  <Typography weight="bold" style={{ fontSize: 20, marginBottom: 6 }}>{selectActionLabel1}</Typography>
                  <Typography variant="body2">{selectActionLabel2}</Typography>
                </Grid>
                <Grid container item xs={12} sm={6} md={6} direction="row-reverse" spacing={3}>
                  {!!resendButtons.length
                    && (
                      resendButtons
                    )}
                </Grid>
              </Grid>,
            )
          )}
      </Grid>

      <Dialog
        open={modalOpen}
        onExited={() => setConfirmationOpen(false)}
        aria-labelledby="form-dialog-title"
        id="form-dialog-title"
        maxWidth="md"
      >
        {modalElement}
      </Dialog>
    </>
  );
};

ActionTab.propTypes = {
  // sharePrpButton: object,
  startApptButton: object,
  resendEmailButton: object,
  rejectButton: object,
  sharePrpModal: object,
  startApptModal: object,
  emailModal: object,
  rejectModal: object,
  notifications: object,
  email: string,
  emailHasSentDesc1: string,
  emailHasSentDesc2: string,
  selectActionLabel1: string,
  selectActionLabel2: string,
};


export default ActionTab;
