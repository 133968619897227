const legendProps = language => ({
  layout: 'horizontal',
  iconType: 'circle',
  verticalAlign: 'top',
  align: language === 'ar' ? 'right' : 'left',
  wrapperStyle: {
    height: 0,
    top: 0,
    lineHeight: '22px',
    margin: '-10px',
    direction: language === 'ar' ? 'rtl' : 'ltr',
  },
});

export default legendProps;
