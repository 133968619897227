import React from 'react';
import { string, node } from 'prop-types';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import makeStyles from '@material-ui/styles/makeStyles';
import { Typography } from '@avon/component-library';
import useTheme from '@material-ui/styles/useTheme';

const useStyles = makeStyles(theme => ({
  card: {
    boxShadow: 'none',
    border: 'solid 1px #ececec',
    height: '100%',
  },
  content: {
    padding: theme.spacing(5),
  },
}));

const CardLayout = ({
  heading, description, children, language,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Card className={classes.card}>
      <CardContent>
        {!!heading && (
          <Box my={theme.spacing(1)}>
            <Typography variant="h3" align={language === 'ar' ? 'right' : 'left'} weight="thin">
              {heading}
            </Typography>
          </Box>
        )}
        {!!description && (
          <Box my={theme.spacing(1)}>
            <Typography variant="body1" align={language === 'ar' ? 'right' : 'left'} weight="regular">
              {description}
            </Typography>
          </Box>
        )}
        {!!children && (
          <Box mt={theme.spacing(2)}>
            {children}
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

CardLayout.propTypes = {
  heading: string,
  description: string,
  children: node,
  language: string,
};

export default CardLayout;
