import {
  string, shape,
} from 'prop-types';
import Pma from './Pma';
import getUrlParams from '../utils/getUrlParams';
import withCallApi from '../utils/withCallApi';

const PmaPage = ({
  location: { search },

}) => {
  const {
    apptId, status,
  } = getUrlParams(search);
  // eslint-disable-next-line
  let singleApptId = apptId;
  if (typeof apptId === 'object' && apptId && apptId.length > 1) {
    // eslint-disable-next-line
    singleApptId = apptId[0];
  }
  return withCallApi(Pma, search, { apptId: singleApptId, status });
};

PmaPage.propTypes = {
  location: shape({
    search: string.isRequired,
  }).isRequired,
};

export default PmaPage;
