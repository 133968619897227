import React, {
  useContext,
} from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Typography, AvonIcon } from '@avon/component-library';
import {
  object, string,
} from 'prop-types';
import dayjs from 'dayjs';
import PmaContext from '../../PmaContext';
import { isMobile } from '../../../utils/getDeviceType';

const useStyles = makeStyles(theme => ({
  card: {
    direction: p => (p.language === 'ar' ? 'rtl' : 'ltr'),
    width: '100%',
    display: 'flex',
    border: `1px solid ${theme.palette.colors.zircon}`,
    borderRadius: '5px',
    boxShadow: 'none',
    alignItems: 'stretch',
    backgroundColor: '#ffffff',
    '&:hover': {
      cursor: () => 'pointer',
    },
  },
  nameContainer: {
    width: p => (p.isMobile ? '60%' : 'calc(100% - 120px)'),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: `${theme.spacing(4)}px`,
    overflowWrap: 'break-word',
  },
  prospect: {
    paddingBottom: `${theme.spacing(1)}px`,

  },
  timerContainer: {
    width: p => (p.isMobile ? '40%' : 120),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderLeft: styleProps => (styleProps.language === 'ar' ? 'unset' : `1px solid ${theme.palette.colors.zircon}`),
    borderRight: styleProps => (styleProps.language === 'ar' ? `1px solid ${theme.palette.colors.zircon}` : 'unset'),
  },
  name: {
    fontSize: 16,
  },
}));

const RepCard = ({
  apptId,
  rep: {
    prospect: { name, email, mobilePhonNr },
  },
  rgaData,
}) => {
  const [{
    language,
    leadsContent: {
      repCard: { dateFormat },
    },
  }] = useContext(PmaContext);

  const classes = useStyles({ language, isMobile: isMobile() });
  const theme = useTheme();

  const prospect = [
    {
      value: [name, { color: '#181818', fontFamily: 'Montserrat', fontWeight: 700 }, ''],
    },
    {
      value: [email, { color: theme.palette.colors.corePink, fontFamily: 'Montserrat', fontWeight: 700 }, 'mailto'],
    },
    {
      value: [mobilePhonNr, { color: theme.palette.colors.corePink, fontFamily: 'Montserrat', fontWeight: 700 }, 'tel'],
    },
  ];

  const emailIcon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M20 4H4C2.89543 4 2 4.89543 2 6V18C2 19.1046 2.89543 20 4 20H20C21.1046 20 22 19.1046 22 18V6C22 4.89543 21.1046 4 20 4ZM18.0862 6H5.91604L12.004 10.732L18.0862 6ZM4 7.04396V18H20V7.04532L12.619 12.7892C12.2941 13.0421 11.852 13.0674 11.5035 12.8653L11.3911 12.7895L4 7.04396Z" fill="#546264" />
    </svg>
  );

  const telIcon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.99977 1L16.9998 1.01C18.0998 1.01 18.9998 1.9 18.9998 3V21C18.9998 22.1 18.0998 23 16.9998 23H6.99977C5.89977 23 5.00977 22.1 5.00977 21V3C5.00977 1.9 5.89977 1 6.99977 1ZM6.99977 19H16.9998V5H6.99977V19Z" fill="#546264" />
    </svg>
  );

  const webProspect = [
    {
      type: 'name',
      value: [{ data: name, valueStyle: { color: '#181818', fontFamily: 'Montserrat', fontWeight: 700 } }],
    },
    {
      type: 'contact',
      value: [
        {
          data: email,
          valueStyle: {
            color: theme.palette.colors.corePink, fontFamily: 'Montserrat', fontWeight: 700, 'word-break': 'break-all',
          },
          linkto: 'mailto',
          icon: emailIcon,
        },
        {
          data: mobilePhonNr,
          valueStyle: {
            color: theme.palette.colors.corePink, fontFamily: 'Montserrat', fontWeight: 700, 'word-break': 'break-all',
          },
          linkto: 'tel',
          icon: telIcon,
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      <Card pb={2} className={classes.card} data-testid="rep-card" id={`rep-card-${apptId}`}>
        <Box className={classes.nameContainer}>
          {isMobile() ? prospect.map(({ value: [value, valueStyle, linkto] }) => (
            <Typography
              key={`${value}-details-block`}
              variant="body1"
              classes={{ root: classes.prospect }}
            >
              {linkto ? (
                <a href={`${linkto}:${value}`} style={{ textDecoration: 'none' }} onClick={e => e.stopPropagation()}>
                  <span style={valueStyle}>
                    {value}
                  </span>
                </a>
              )
                : (
                  <span style={valueStyle}>
                    {value}
                  </span>
                )}
            </Typography>
          ))
            : webProspect.map(({ type, value }) => (type === 'name' ? value.map(({
              data, valueStyle,
            }) => (
              <Typography
                key={`${type}-details-block`}
                variant="bold"
                style={{ fontSize: 20, margin: '0 0 12px 18px' }}
                classes={{ root: classes.prospect }}
              >
                <span style={valueStyle}>
                  {data}
                </span>
              </Typography>
            )) : (
              <Box style={{ display: 'flex', flexDirection: 'column' }}>
                {value.map(({
                  data, valueStyle, linkto, icon,
                }) => (
                  <Box display="flex">
                    {data && (
                    <Box style={{ margin: '0px 12px 0 18px' }}>
                      {icon}
                    </Box>
                    )}
                    <Typography
                      key={`${type}-details-block`}
                      variant="body1"
                      classes={{ root: classes.prospect }}
                    >
                      <a href={`${linkto}:${data}`} style={{ textDecoration: 'none' }} onClick={e => e.stopPropagation()}>
                        <span style={valueStyle}>
                          {data}
                        </span>
                      </a>
                    </Typography>
                  </Box>
                ))}
              </Box>
            )))}
        </Box>
        <Box flex="1" px={1} className={classes.timerContainer}>
          <Box mb={2}>
            <AvonIcon
              color="#181818"
              type="clock"
              alt="clock-icon"
              height="36px"
              width="36px"
            />
          </Box>
          <Box px={3} color="#A2ACAE">
            <Typography style={{ fontFamily: 'Montserrat', fontSize: 14, color: '#181818' }} align="center">
              {rgaData.creatDt && dayjs(new Date(`${rgaData.creatDt.substr(0, 23)}+00:00`)).format(dateFormat || 'YY/MM/DD')}
            </Typography>
          </Box>
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default RepCard;

RepCard.propTypes = {
  apptId: string,
  rep: object,
  rgaData: object,
};
