import {
  string, shape,
} from 'prop-types';
import StatisticsPage from './Statistics';
import withCallApi from '../utils/withCallApi';

const Statistics = ({
  location: { search },
}) => withCallApi(StatisticsPage, search);

Statistics.propTypes = {
  location: shape({
    search: string.isRequired,
  }).isRequired,
};

export default Statistics;
