import React, { useState, useEffect, useRef } from 'react';
import {
  object, array, string, func, bool,
} from 'prop-types';
import { Button, Typography } from '@avon/component-library';
import Box from '@material-ui/core/Box';
import useTheme from '@material-ui/styles/useTheme';
import { makeStyles } from '@material-ui/styles';
import AvonTooltip from '../AvonTooltip';
import TestimonialForm from './TestimonialForm';
import { delTestimonial } from '../../../utils/api';
import { isMobile } from '../../../utils/getDeviceType';

const useStyles = makeStyles(theme => ({
  listBox: {
    backgroundColor: theme.palette.colors.lightZircon,
    padding: 16,
  },
  detailBox: {
    backgroundColor: theme.palette.colors.white,
    marginTop: 16,
    border: '1px solid #E4EDED',
    boxSizing: 'border-box',
    borderRadius: 4,
    alignSelf: 'stretch',
    padding: 14,
  },
  detailIcons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const Testimonial = ({
  testimonialContent,
  testimonialData,
  language,
  callApi,
  isSubmitting,
  setBannerShow,
  refresh,
  onSaveClick,
  onDeleteClick,
}) => {
  const isWebApp = sessionStorage.getItem('isWebApp') && !isMobile();
  const classes = useStyles();
  const inputRef = useRef({});
  const theme = useTheme();

  const {
    heading,
    headingTooltip,
    subHeading,
    listHeading,
    formButtonLabel,
    delSuccessLabel,
  } = testimonialContent;

  const [showForm, setShowForm] = useState(false);
  const [initData, setInitData] = useState({ name: '', location: '', testimony: '' });

  useEffect(() => {}, [initData]);

  const toggleForm = show => setShowForm(show);

  const delData = async (id) => {
    try {
      await callApi(delTestimonial(id));
      setBannerShow({ open: true, content: delSuccessLabel });
      toggleForm(false);
      refresh();
      return true;
    } catch ({ error }) {
      return false;
    }
  };

  const delIcon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13 2C14.5977 2 15.9037 3.24892 15.9949 4.82373L16 5H18H20C20.5523 5 21 5.44772 21 6C21 6.51284 20.614 6.93551 20.1166 6.99327L20 7H19V18C19 19.5977 17.7511 20.9037 16.1763 20.9949L16 21H8C6.40232 21 5.09634 19.7511 5.00509 18.1763L5 18V7H4C3.44772 7 3 6.55228 3 6C3 5.48716 3.38604 5.06449 3.88338 5.00673L4 5H6H8C8 3.40232 9.24892 2.09634 10.8237 2.00509L11 2H13ZM10 7H8H7V18C7 18.5128 7.38604 18.9355 7.88338 18.9933L8 19H16C16.5128 19 16.9355 18.614 16.9933 18.1166L17 18V7H16H14H10ZM14 5H10L10.0067 4.88338C10.0645 4.38604 10.4872 4 11 4H13L13.1166 4.00673C13.614 4.06449 14 4.48716 14 5ZM14.9933 8.88338C14.9355 8.38604 14.5128 8 14 8C13.4477 8 13 8.44772 13 9V17L13.0067 17.1166C13.0645 17.614 13.4872 18 14 18C14.5523 18 15 17.5523 15 17V9L14.9933 8.88338ZM10 8C10.5128 8 10.9355 8.38604 10.9933 8.88338L11 9V17C11 17.5523 10.5523 18 10 18C9.48716 18 9.06449 17.614 9.00673 17.1166L9 17V9C9 8.44772 9.44772 8 10 8Z" fill={theme.palette.colors.corePink} />
    </svg>
  );

  const editIcon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.2946 4.12132L5.29462 13.1213C5.18485 13.2311 5.10214 13.3649 5.05305 13.5122L3.05305 19.5122C2.79246 20.294 3.5362 21.0377 4.31796 20.7771L10.318 18.7771C10.4652 18.728 10.5991 18.6453 10.7088 18.5355L19.7088 9.53553C21.2039 8.04044 21.2039 5.61641 19.7088 4.12132C18.2137 2.62623 15.7897 2.62623 14.2946 4.12132ZM18.407 5.6585C19.0065 6.37684 18.969 7.44694 18.2946 8.12132L17.5016 8.91413L14.9159 6.32842L15.7088 5.53553C16.4229 4.82149 17.5806 4.82149 18.2946 5.53553L18.407 5.6585ZM16.0873 10.3282L13.5017 7.74261L6.87473 14.3694L5.58273 18.2474L9.45973 16.9544L16.0873 10.3282Z" fill={theme.palette.colors.corePink} />
    </svg>
  );

  return (
    <Box>
      <Box pb={isWebApp ? 0 : 2}>
        <Box variant="body1" color="textPrimary" align="left" display="flex">
          <Box
            component="span"
            mr={language === 'ar' ? 'unset' : 2}
            ml={language === 'ar' ? 2 : 'unset'}
          >
            <AvonTooltip
              position="top"
              trigger="click"
              arrow
              tipMsg={headingTooltip}
              theme="light"
              interactive
              language={language}
              showDissmiss
            >
              <svg cursor="pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 14">
                <g fill="none" fillRule="evenodd">
                  <path d="M-1-1h16v16H-1z" />
                  <path
                    fill={theme.palette.colors.corePink}
                    fillRule="nonzero"
                    d="M7 .333a6.667 6.667 0 1 1 0 13.334A6.667 6.667 0 0 1 7 .333zm0 1.334a5.333 5.333 0 1 0 0 10.666A5.333 5.333 0 0 0 7 1.667zM7 9a.667.667 0 1 1 0 1.333A.667.667 0 0 1 7 9zm0-5.333c1.104 0 2 .897 2 2 0 .616-.256 1.027-.776 1.51l-.38.338-.159.154c-.01.008-.014.01-.017.007l-.001-.007-.005.075a.667.667 0 0 1-1.329-.077c0-.43.198-.743.553-1.081l.11-.102.229-.201c.334-.295.442-.452.442-.616a.668.668 0 0 0-1.33-.078l-.004.078a.667.667 0 1 1-1.333 0 2 2 0 0 1 2-2z"
                  />
                </g>
              </svg>
            </AvonTooltip>
          </Box>
          <Typography style={{ fontSize: 16, color: '#181818' }} weight="bold">
            {heading}
          </Typography>
        </Box>
        <Box style={isWebApp ? {
          marginTop: 20,
          padding: 24,
          background: '#F6F9F9',
          borderRadius: 8,
          fontFamily: 'Montserrat',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        } : {}}
        >
          { isWebApp && (
            <Box style={{
              fontWeight: 'bold',
              fontSize: 20,
              lineHeight: '28px',
              color: '#181818',
              marginBottom: 2,
            }}
            >
              {listHeading}
            </Box>
          )}
          <Box style={isWebApp ? {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          } : {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
          >
            <Box style={isWebApp ? {
              flex: 1,
              fontSize: 18,
              lineHeight: '26px',
              color: '#000000',
              marginRight: language === 'ar' ? 'unset' : 12,
              marginLeft: language === 'ar' ? 12 : 'unset',
            } : {
              margin: '20px 0',
              fontSize: 14,
              color: '#707677',
            }}
            >
              {subHeading}
            </Box>
            <Box style={isWebApp ? {
              width: 262,
            } : {
              alignSelf: 'center',
              width: '100%',
            }}
            >
              <Button
                name="add-new-testimonial"
                id="add-new-testimonial"
                onClick={async () => {
                  setInitData({ name: '', location: '', testimony: '' });
                  await toggleForm(true);
                  const formEle = inputRef.current;
                  formEle.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
                }}
                disabled={showForm}
                priority="secondary"
                fullwidth
              >
                {formButtonLabel || 'ADD NEW TESTIMONIAL'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        id="testimonialForm"
        ref={inputRef}
        mb={isWebApp ? 0 : 8}
        display={showForm ? 'block' : 'none'}
        style={isWebApp ? {
          background: '#f6f9f9',
          padding: '0 24px',
        } : {}}
      >
        {showForm ? (
          <TestimonialForm
            language={language}
            callApi={callApi}
            content={testimonialContent}
            isSubmitting={isSubmitting}
            toggleForm={toggleForm}
            setShowForm={setShowForm}
            setBannerShow={setBannerShow}
            refresh={refresh}
            initData={initData}
            onSaveClick={onSaveClick}
          />
        ) : null}
      </Box>

      {testimonialData && testimonialData.length > 0
      && (
      <Box id="testimonialList" className={classes.listBox}>

        {
          !isWebApp && (
            <Typography
              id="listHeading"
              style={{
                fontSize: 16, color: '#181818', marginBottom: 16, fontFamily: 'Montserrat',
              }}
            >
              {listHeading}
            </Typography>
          )
        }
        {testimonialData.map(data => (
          <Box id={`testimonial-detail-${data.id}`} key={`testimonial-detail-${data.id}`} className={classes.detailBox} style={{ marginBottom: 16 }}>
            <Typography
              id={`testimonial-header-${data.id}`}
              style={{
                fontSize: 14,
                color: '#181818',
                marginBottom: 14,
                fontFamily: 'Montserrat',
                overflowWrap: 'break-word',
              }}
            >
              {`${data.name} - ${data.location}`}
            </Typography>
            <div
              id={`testimonial-divide-${data.id}`}
              width="100%"
              style={{
                top: 50, left: 14, height: 0, background: '#E4EDED', border: '1px solid #E4EDED',
              }}
            />
            <Typography
              id={`testimonial-testimony-${data.id}`}
              style={{
                fontSize: 12,
                color: '#707677',
                margin: '14px 0 14px 0',
                fontFamily: 'Montserrat',
                overflowWrap: 'break-word',
              }}
            >
              {data.testimony}
            </Typography>
            <Box
              id={`testimonial-actions-${data.id}`}
              className={classes.detailIcons}
            >
              <Box
                id={`testimonial-editbox-${data.id}`}
                style={{ display: 'flex', alignItems: 'center' }}
                onClick={async () => {
                  setInitData({
                    id: data.id,
                    name: data.name,
                    location: data.location,
                    testimony: data.testimony,
                  });
                  await setShowForm(true);
                  const formEle = inputRef.current;
                  formEle.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
                }}
              >
                <Typography
                  id={`testimonial-actions-edit-${data.id}`}
                  style={{
                    color: theme.palette.colors.corePink,
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: 'Montserrat',
                    marginRight: 10,
                  }}
                >
                  {testimonialContent.editIconLabel || 'EDIT'}
                </Typography>
                {editIcon}
              </Box>
              <Box id={`testimonial-delbox-${data.id}`} ml={3} onClick={() => onDeleteClick(delData, data.id)}>
                {delIcon}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      )}
    </Box>
  );
};

Testimonial.propTypes = {
  testimonialData: array,
  testimonialContent: object,
  language: string,
  callApi: func,
  isSubmitting: bool,
  setBannerShow: func,
  refresh: func,
  onSaveClick: func,
  onDeleteClick: func,
};


export default Testimonial;
