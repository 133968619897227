import React from 'react';
import { Typography } from '@avon/component-library';
import { number } from 'prop-types';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    '& p': {
      color: theme.palette.colors.greyText,
      fontSize: '14px',
      fontFamily: 'Montserrat',
    },
  },
}));

const CharLimit = ({
  current, limit, ...props
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography variant="body1" weight="thin" color="textPrimary" align="right" {...props}>
        {`${current} / ${limit}`}
      </Typography>
    </Box>
  );
};

CharLimit.propTypes = {
  current: number.isRequired,
  limit: number.isRequired,
};

export default CharLimit;
