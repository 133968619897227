import React, { useContext, useState } from 'react';
import {
  func, string, object, bool,
} from 'prop-types';
import {
  Box, Badge,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  AvonIcon,
} from '@avon/component-library';
import Select from './Select';
import LmaContext from '../../LmaContext';
// import sortAndFilterIcon from './assets/filter_idle.svg';
import { isTablet } from '../../../utils/getDeviceType';
import InputSearch from '../InputSearch';

const useStyles = makeStyles(theme => ({
  fileterBarContainer: {
    padding: '24px 24px 0',
    width: '100%',
    marginBottom: 18,
    '& + div': {
      height: 'calc(100% - 145px)',
      overflow: 'auto',
      padding: '0 24px',
      '& > div': {
        marginTop: e => (e.page === 'noLeads' ? 12 : 0),
      },
    },
  },
  top: {
    position: 'relative',
    marginBottom: 24,
    display: 'flex',
    alignItems: 'center',
    height: 32,
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: 24,
    color: '#181818',
    justifyContent: e => (e.isTablet ? 'space-between' : 'unset'),
    '& img': {
      cursor: 'pointer',
    },
  },
  badge: {
    backgroundColor: theme.palette.colors.green,
  },
}));

const autorenew = color => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12 9V6C8.69 6 6 8.69 6 12C6 13.01 6.25 13.97 6.7 14.8L5.24 16.26C4.46 15.03 4 13.57 4 12C4 7.58 7.58 4 12 4V1L16 5L12 9ZM17.3 9.2L18.76 7.74C19.54 8.97 20 10.43 20 12C20 16.42 16.42 20 12 20V23L8 19L12 15V18C15.31 18 18 15.31 18 12C18 10.99 17.74 10.04 17.3 9.2Z" fill={color} />
  </svg>
);

const FileterAndSortBar = ({
  page,
  inputOnChange,
  search,
  spinner,
  sortValue,
  setSort,
  filterValue,
  setFilter,
  firstFetch,
  sortOrFilter,
  handleClear,
  showSearch,
}) => {
  const [{
    language,
    filters,
    leadsContent: {
      title, selectText, sortFilter: {
        sortFilterTabInfo: {
          sort: { title: sortByTitle },
          filter: { title: filtersTitle },
        },
      },
    },
  }] = useContext(LmaContext);
  const classes = useStyles({ language, isTablet: isTablet(), page });
  const [collapsePanel, setCollapsePanel] = useState(false);
  const theme = useTheme();

  const [badgeCount, setBadgeCount] = useState(Object.keys(filters).length);

  return (
    <Box className={classes.fileterBarContainer}>
      <Box className={classes.top}>
        <Box mr={2.5}>{title}</Box>
        {
          isTablet() ? (
            <>
              {/* <Box width={24} height={24} onClick={() => setCollapsePanel(true)}>
                <img src={sortAndFilterIcon} alt="sort-filter-icon" /> */}
              <Badge
                badgeContent={badgeCount}
                color="primary"
                classes={{ badge: classes.badge }}
              >
                <AvonIcon
                  type="sort"
                  alt="sort-icon"
                  id="lma-sort-icon"
                  onClick={() => setCollapsePanel(true)}
                  width="24px"
                  height="24px"
                />
              </Badge>
              {/* </Box> */}
              <Select
                height={48}
                placeholder={selectText}
                type="filter"
                defaultValue={filtersTitle}
                page={page}
                sortValue={sortValue}
                setSort={setSort}
                filterValue={filterValue}
                setFilter={setFilter}
                setCollapsePanel={setCollapsePanel}
                collapsePanel={collapsePanel}
                onClear={handleClear}
                setBadgeCount={setBadgeCount}
              />
            </>
          ) : (
            <Box width={24} height={24} onClick={() => handleClear()}>
              {autorenew(theme.palette.colors.corePink)}
            </Box>
          )
        }
      </Box>
      {
        isTablet() ? (
          <Box flex={1.5} position="relative" style={{ display: showSearch ? 'block' : 'none' }}>
            <InputSearch
              page="repCard"
              inputOnChange={inputOnChange}
              search={search}
              spinner={spinner}
              firstFetch={firstFetch}
              sortOrFilter={sortOrFilter}
              handleClear={handleClear}
            />
          </Box>
        ) : (
          <Box style={{
            display: 'flex',
            alignItems: 'center',
            height: 48,
          }}
          >
            <Box flex={1.5} position="relative">
              <InputSearch
                page="repCard"
                inputOnChange={inputOnChange}
                search={search}
                spinner={spinner}
                firstFetch={firstFetch}
                sortOrFilter={sortOrFilter}
                handleClear={handleClear}
              />
            </Box>
            <Box style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              flex: 1,
              margin: '0 12px',
            }}
            >
              <Select
                height={48}
                placeholder={sortByTitle.toString().toUpperCase()}
                type="sort"
                defaultValue={sortByTitle}
                page={page}
                sortValue={sortValue}
                setSort={setSort}
                filterValue={filterValue}
                setFilter={setFilter}
                onClear={handleClear}
                setBadgeCount={setBadgeCount}
              />
            </Box>
            <Box style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              flex: 1,
              minWidth: 250,
            }}
            >
              <Select
                height={48}
                placeholder={selectText}
                type="filter"
                defaultValue={filtersTitle}
                page={page}
                sortValue={sortValue}
                setSort={setSort}
                filterValue={filterValue}
                setFilter={setFilter}
                onClear={handleClear}
                setBadgeCount={setBadgeCount}
              />
            </Box>
          </Box>
        )
      }
    </Box>
  );
};

FileterAndSortBar.propTypes = {
  page: string,
  inputOnChange: func,
  search: string,
  spinner: string,
  filterValue: object,
  setFilter: func,
  sortValue: string,
  setSort: func,
  firstFetch: string,
  sortOrFilter: string,
  handleClear: func,
  showSearch: bool,
};

FileterAndSortBar.defaultProps = {
  spinner: 'hide',
  showSearch: true,
};


export default FileterAndSortBar;
