import React, {
  useMemo, useCallback, useRef, useEffect, useState,
} from 'react';
import { InputField as AvonInputField, Typography } from '@avon/component-library';
import Box from '@material-ui/core/Box';
import useTheme from '@material-ui/styles/useTheme';
import {
  MenuItem, Card, Divider,
} from '@material-ui/core/';
import {
  shape, func, string, object, number, bool,
} from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Downshift from 'downshift';
import CharCount from './CharCount';
import debounce from '../../../utils/debounce';
import checkVanity from '../../../utils/api';
import AvonTooltip from '../../AvonTooltip';
import { isMobile } from '../../../../utils/getDeviceType';

const useStyles = makeStyles(theme => ({
  limited: {
    display: 'block',
    '& textarea': {
      fontSize: '14px',
      fontFamily: 'Montserrat',
      color: theme.palette.colors.textDark,
    },
  },
  typography: {
    '& p': {
      fontSize: 16,
      color: `${theme.palette.colors.corePink} !important`,
    },
  },
  typographyFocus: {
    '& p': {
      fontSize: 16,
      color: theme.palette.colors.corePink,
    },
  },
  typographyFocusAvailable: {
    '& p': {
      fontSize: 16,
      color: '#1a8282',
    },
  },
  toggleShow: {
    '& span': {
      cursor: 'pointer',
      color: `${theme.palette.colors.corePink} !important`,
      fontSize: '14px',
      fontFamily: 'Montserrat',
      fontWeight: 700,
    },
  },
  toggleShow1: {
    '& span': {
      cursor: 'pointer',
      color: `${theme.palette.colors.corePink} !important`,
      fontSize: '12px',
      fontFamily: 'Montserrat-Medium',
    },
    '& span:hover': {
      fontWeight: 'bold',
    },
  },
  vanity: {
    '& .MuiInputAdornment-positionStart': {
      marginRight: 0,
      marginLeft: 0,
      lineHeight: 'inherit',
      '& p': {
        color: '#181818',
        fontFamily: 'Montserrat',
        fontSize: 14,
      },
    },
    '& .MuiInputBase-input': {
      paddingBottom: '7px',
      fontSize: 14,
      color: '#181818',
      fontFamily: 'Montserrat',
      fontWeight: 700,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.colors.corePink,
      transform: 'scaleX(0)',
    },
  },
  vanityFocus: {
    '& .MuiInputAdornment-positionStart': {
      marginRight: 0,
      marginLeft: 0,
      lineHeight: 'inherit',
      '& p': {
        color: '#181818',
        fontFamily: 'Montserrat',
        fontSize: 14,
      },
    },
    '& .MuiInputBase-input': {
      paddingBottom: '7px',
      fontSize: 14,
      color: '#181818',
      caretColor: theme.palette.colors.corePink,
      fontFamily: 'Montserrat',
      fontWeight: 700,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.colors.corePink,
    },
  },
  vanityError: {
    '& .MuiInputAdornment-positionStart': {
      marginRight: 0,
      marginLeft: 0,
      lineHeight: 'inherit',
      '& p': {
        color: '#181818',
        fontFamily: 'Montserrat',
        fontSize: 14,
      },
    },
    '& .MuiInputBase-input': {
      paddingBottom: '7px',
      fontSize: 14,
      color: '#181818',
      fontFamily: 'Montserrat',
      fontWeight: 700,
    },
    '& .MuiInput-underline:after': {
      transform: 'scaleX(0)',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: theme.palette.colors.error,
    },
  },
  vanityAvailable: {
    '& .MuiInputAdornment-positionStart': {
      marginRight: 0,
      marginLeft: 0,
      lineHeight: 'inherit',
      '& p': {
        color: '#181818',
        fontFamily: 'Montserrat',
        fontSize: 14,
      },
    },
    '& .MuiInputBase-input': {
      paddingBottom: '7px',
      fontSize: 14,
      color: '#181818',
      caretColor: '#1a8282',
      fontFamily: 'Montserrat',
      fontWeight: 700,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#1a8282',
    },
  },
  downshift: {
    '& .MuiPaper-elevation1': {
      boxShadow: 'unset',
    },
    '& .MuiPaper-rounded': {
      borderRadius: 0,
    },
    '& .MuiCard-root': {
      overflow: 'unset',
      backgroundColor: 'inherit',
    },
  },
  input: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    width: '100%',
  },
  noResults: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(4),
  },
  results: {
    display: 'inline-block',
  },
  manualAddress: {
    color: theme.palette.primary.main,
    whiteSpace: 'normal',
    fontWeight: 'bold',
  },
  menuItem: {
    whiteSpace: 'normal',
    paddingLeft: 0,
    paddingTop: 1,
    minHeight: theme.spacing(4),
  },
  menuItemRow: {
    fontSize: '14px',
    wordBreak: 'break-all',
    [theme.breakpoints.up('768')]: {
      fontSize: '16px',
    },
  },
  boldText: {
    '&& b': {
      fontWeight: 700,
      fontFamily: 'Montserrat',
    },
  },
}));

const IOSSwitch = withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#1A8282',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#e4eded',
      border: '1px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `0px solid ${theme.palette.grey[400]}`,
    backgroundColor: '#e4eded',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => (
  <Switch
    focusVisibleClassName={classes.focusVisible}
    disableRipple
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
    }}
    {...props}
  />
));

const InputField = ({
  locationShow,
  setLocationShow,
  phoneShow,
  setPhoneShow,
  emailShow,
  setEmailShow,
  aboutMeShow,
  setAboutMeShow,
  introShow,
  setIntroShow,
  setPreviewShow,
  setBannerShow1,
  showHideMap,
  focusOn,
  isAvailableURL,
  setIsAvailableURL,
  language,
  callApi,
  field: { onBlur, name },
  form: {
    touched, errors, values, setFieldValue,
  },
  parentName,
  label, maxLength, startsWith, startsWithShorthand, maxLengthNotCharCount,
  initialData,
  editEmail,
  editPhone,
  restoreValue,
  ...props
}) => {
  const isWebApp = sessionStorage.getItem('isWebApp') && !isMobile();
  const classes = useStyles();
  const theme = useTheme();
  const isMounted = useRef(true);
  const {
    priority, toggle, showTip, tipMsg, showContent, hideContent, suggestList = [], mask: maskObj,
  } = { ...props };

  const [mask, setMask] = useState(maskObj);

  useEffect(() => {
    if (parentName && maskObj) {
      setMask(maskObj[values[parentName]]);
    }
  }, [values[parentName]]);

  useEffect(() => {
    if (name === 'vanityName' && values[name] === '' && suggestList.length > 0) {
      setFieldValue(name, suggestList[0]);
    }
  }, [suggestList[0]]);


  const getAddressList = debounce(async (inputValue) => {
    setIsAvailableURL(false);
    callApi(
      checkVanity({ inputValue }),
    ).then((response) => {
      if (response.vanityName) {
        if (isMounted.current) {
          // setFieldValue(name, inputValue ? `${inputValue}` : '');
          setIsAvailableURL(true);
          // setFieldError(name, '456')
        }
      }
    }).catch(() => {
      // setFieldValue(name, inputValue ? `${inputValue}` : '');
      setIsAvailableURL(false);
    });
  }, 300);

  const endAdornment = useMemo(() => (
    maxLength ? (
      <CharCount
        current={values[name].length}
        limit={maxLength}
      />
    ) : undefined), [values[name]], maxLength);


  const onChange = useCallback(({ target: { value } }) => {
    if (typeof setPreviewShow === 'function') {
      if (name !== 'mobilePhonNr' && name !== 'emailAddrTxt') {
        setPreviewShow(false);
      }
    }
    if (startsWith) {
      if (name === 'vanityName') {
        setFieldValue(name, value ? `${value}` : '');
        // setFieldError(name, '123')
        getAddressList(value);
      } else {
        setFieldValue(name, value ? `${startsWith}${value}` : '');
      }
    } else {
      setFieldValue(name, value);
    }
  }, [setFieldValue, name, startsWith]);

  const handleInputFocus = () => {
    if (isWebApp) {
      return;
    }
    const lmaActionContainer = document.getElementById('lma-action-container');
    if (name === 'notesContent' && lmaActionContainer) {
      lmaActionContainer.style.height = 'unset';
    }
  };
  const handleInputBlur = (e) => {
    onBlur(e);
    if (isWebApp) {
      return;
    }
    setTimeout(() => {
      const lmaActionContainer = document.getElementById('lma-action-container');
      if (name === 'notesContent' && lmaActionContainer) {
        lmaActionContainer.style.height = '100%';
      }
      const actionTabContainer = document.getElementById('actionTabContainer');
      // eslint-disable-next-line
      actionTabContainer && actionTabContainer.parentNode.scrollTo(0, 150);
    }, 50);
  };

  const downShiftChange = (selectedAddress) => {
    setFieldValue(name, selectedAddress);
  };

  const defaultValue = (values[name] && startsWith)
    ? values[name].replace(startsWith, '')
    : values[name];

  useEffect(() => {
    if (editEmail && !editPhone && name === 'mobilePhonNr') {
      setFieldValue(name, initialData[name]);
    }
    if (name === 'emailAddrTxt' && !editEmail && editPhone) {
      setFieldValue(name, initialData[name]);
    }
    if (restoreValue
      && (name === 'mobilePhonNr' || name === 'emailAddrTxt')) {
      setFieldValue(name, initialData[name]);
    }
  }, [editEmail, editPhone, initialData, restoreValue]);

  const toggleHide = () => {
    if (typeof setPreviewShow === 'function') {
      if (name !== 'mobilePhonNr' && name !== 'emailAddrTxt') {
        setPreviewShow(false);
      }
    }
    if (name === 'city') {
      setLocationShow(!locationShow);
      if (locationShow) {
        setBannerShow1({ open: true, content: hideContent });
      } else { setBannerShow1({ open: true, content: showContent }); }
    }
    if (name === 'mobilePhonNr') {
      setPhoneShow(!phoneShow);
      if (phoneShow) {
        setBannerShow1({ open: true, content: hideContent });
      } else { setBannerShow1({ open: true, content: showContent }); }
    }
    if (name === 'emailAddrTxt') {
      setEmailShow(!emailShow);
      if (emailShow) {
        setBannerShow1({ open: true, content: hideContent });
      } else { setBannerShow1({ open: true, content: showContent }); }
    }
    if (name === 'aboutme') {
      setAboutMeShow(!aboutMeShow);
      if (aboutMeShow) {
        setBannerShow1({ open: true, content: hideContent });
      } else { setBannerShow1({ open: true, content: showContent }); }
    }
    if (name === 'introduction') {
      setIntroShow(!introShow);
      if (introShow) {
        setBannerShow1({ open: true, content: hideContent });
      } else { setBannerShow1({ open: true, content: showContent }); }
    }
  };

  let switchChecked = false;
  let switchLabel = null;
  let thing = null;
  if (
    (name === 'city' && locationShow)
    || (name === 'mobilePhonNr' && phoneShow)
    || (name === 'emailAddrTxt' && emailShow)
    || (name === 'aboutme' && aboutMeShow)
    || (name === 'introduction' && introShow)
  ) {
    thing = showHideMap.hide;
    switchLabel = showHideMap.show;
    switchChecked = true;
  } else if (
    (name === 'city' && !locationShow)
    || (name === 'mobilePhonNr' && !phoneShow)
    || (name === 'emailAddrTxt' && !emailShow)
    || (name === 'aboutme' && !aboutMeShow)
    || (name === 'introduction' && !introShow)
  ) {
    thing = showHideMap.show;
    switchLabel = showHideMap.show;
    switchChecked = false;
  }

  return (
    <>
      <Box
        component="span"
        // eslint-disable-next-line
        className={focusOn && name === 'vanityName' ? (isAvailableURL ? classes.typographyFocusAvailable : classes.typographyFocus) : classes.typography}
      >
        <Grid container id={`prpman-input-container-${name}`}>
          <Grid key="1" item xs={name === 'vanityName' ? 12 : 8}>
            <Box
              fontFamily={priority === 'secondary' ? 'Montserrat' : 'Montserrat-Medium'}
              fontSize={priority === 'secondary' ? '14px' : '12px'}
              color={priority === 'secondary' ? 'textPrimary' : 'textLight'}
            >
              <Box variant="body1" color="textPrimary" align="left" display="flex">
                {
                  showTip ? (
                    <Box
                      component="span"
                      mr={language === 'ar' ? 'unset' : 2}
                      ml={language === 'ar' ? 2 : 'unset'}
                    >
                      <AvonTooltip
                        position="top"
                        trigger="click"
                        arrow
                        tipMsg={tipMsg}
                        theme="light"
                        interactive
                        order={name}
                        language={language}
                        showDissmiss
                      >
                        <svg cursor="pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 14">
                          <g fill="none" fillRule="evenodd">
                            <path d="M-1-1h16v16H-1z" />
                            <path
                              fill={theme.palette.colors.corePink}
                              fillRule="nonzero"
                              d="M7 .333a6.667 6.667 0 1 1 0 13.334A6.667 6.667 0 0 1 7 .333zm0 1.334a5.333 5.333 0 1 0 0 10.666A5.333 5.333 0 0 0 7 1.667zM7 9a.667.667 0 1 1 0 1.333A.667.667 0 0 1 7 9zm0-5.333c1.104 0 2 .897 2 2 0 .616-.256 1.027-.776 1.51l-.38.338-.159.154c-.01.008-.014.01-.017.007l-.001-.007-.005.075a.667.667 0 0 1-1.329-.077c0-.43.198-.743.553-1.081l.11-.102.229-.201c.334-.295.442-.452.442-.616a.668.668 0 0 0-1.33-.078l-.004.078a.667.667 0 1 1-1.333 0 2 2 0 0 1 2-2z"
                            />
                          </g>
                        </svg>
                      </AvonTooltip>
                    </Box>
                  ) : null
                }
                <Box pt={0.5} id={`prpman-input-label-${name}`}>{label || name}</Box>
              </Box>
            </Box>
          </Grid>
          {
            name !== 'introduction' && name !== 'aboutme' ? (
              <Grid
                key="2"
                id={`prpman-input-grid-${name}`}
                item
                xs={name === 'vanityName' ? 0 : 4}
                className={priority === 'secondary' ? classes.toggleShow : classes.toggleShow1}
              >
                {toggle ? (
                  <Typography variant="body1" id={`prpman-input-toggle-${name}`} weight="regular" color="primary" align={language === 'ar' ? 'left' : 'right'}>
                    {/* eslint-disable-next-line */}
                    <span
                      id={`prpman-input-span-${name}`}
                      onClick={toggleHide}
                    >
                      {thing}
                    </span>
                  </Typography>
                ) : null
                }
              </Grid>
            ) : (
              <Grid
                key="2"
                id={`prpman-input-grid-${name}`}
                item
                xs={name === 'vanityName' ? 0 : 4}
              >
                {toggle ? (
                  <Typography variant="body1" id={`prpman-input-toggle-${name}`} weight="regular" color="primary" align={language === 'ar' ? 'left' : 'right'}>
                    <Box
                      component="span"
                      style={{
                        marginRight: isMobile() ? 6 : 20,
                        fontFamily: 'Montserrat',
                        fontWeight: 700,
                        fontSize: 16,
                        lineHeight: '24px',
                        color: '#181818',
                      }}
                      id={`prpman-input-toggle-label-${name}`}
                    >
                      {switchLabel}
                    </Box>
                    <IOSSwitch
                      checked={switchChecked}
                      onChange={toggleHide}
                      value="checkedB"
                      id={`prpman-input-switch-${name}`}
                      name={`admin-prpman-switchr-${name}`}
                    />
                  </Typography>
                ) : null}
              </Grid>
            )
          }

        </Grid>
      </Box>

      <Box mt={3}>
        {
          name === 'vanityName' ? (
            <div className={classes.downshift}>
              <Box
                // eslint-disable-next-line
                className={focusOn ? (isAvailableURL ? classes.vanityAvailable : classes.vanityFocus)
                  : (touched[name] && errors[name] ? classes.vanityError : classes.vanity)}
              >
                <Downshift
                  id={`prpman-AvonInputField-Downshift-${name}`}
                  onSelect={downShiftChange}
                  itemToString={selectedAddress => (selectedAddress || values[name])}
                  inputValue={values[name]}
                >
                  {({ getInputProps, getItemProps, isOpen }) => (
                    <div>
                      <Card>
                        <div>
                          <AvonTooltip
                            position="top-start"
                            trigger="click"
                            tipMsg={startsWith}
                            vanityName={values[name]}
                            language={language}
                            theme="light"
                            interactive
                            order="vanityname"
                          >
                            <AvonInputField
                              id={`prpman-AvonInputField-${name}`}
                              inputProps={getInputProps({
                                disableUnderline: true,
                                // eslint-disable-next-line
                                onBlur: props.onBlur,
                                onChange,
                              })}
                              classes={{
                                root: maxLength ? classes.limited : undefined,
                              }}
                              name={name}
                              language={language}
                              autoComplete="off"
                              startsWith={!maxLength ? startsWithShorthand : undefined}
                              touched={(!!touched[name]).toString()}
                              error={touched[name] && errors[name]}
                              endAdornment={
                                // eslint-disable-next-line
                                name === 'vanityName' ? (
                                  // eslint-disable-next-line
                                  touched[name] && errors[name] ? (<img src="assets/images/alert.png" alt="error" />) : (focusOn && isAvailableURL ? <img src="assets/images/checkmark.png" alt="success" /> : '')
                                ) : endAdornment}
                              fullWidth
                              {...props}
                            />
                          </AvonTooltip>
                        </div>
                        {isOpen ? (
                          <Box>
                            <Divider variant="middle" />
                            {suggestList.map((selectedAddress, index) => (
                              <MenuItem
                                component="div"
                                id={`prpman-suggestList-${index}`}
                                className={classes.menuItem}
                                {...getItemProps({ key: index, index, item: selectedAddress })}
                              >
                                <Box
                                  component="div"
                                  pl={language === 'ar' ? 'unset' : 0.5}
                                  pr={language === 'ar' ? 0.5 : 'unset'}
                                  className={classes.menuItemRow}
                                >
                                  <Box component="span" style={{ color: '#a2acae' }} fontWeight="100" id={`prpman-startsWith-${index}`}>{startsWith}</Box>
                                  <Box component="span" color={theme.palette.colors.corePink} fontWeight="600" id={`prpman-selectedAddress-${index}`}>{selectedAddress}</Box>
                                </Box>
                              </MenuItem>
                            ))}
                          </Box>
                        ) : null}
                      </Card>
                    </div>
                  )}
                </Downshift>
              </Box>
            </div>
          )
            : (
              <AvonInputField
                id={`prpman-AvonInputField-${name}`}
                classes={{
                  root: maxLength ? classes.limited : undefined,
                }}
                onChange={onChange}
                onFocus={handleInputFocus}
                onBlur={(e) => { handleInputBlur(e); }}
                name={name}
                language={language}
                startsWith={!maxLength ? startsWith : undefined}
                touched={(!!touched[name]).toString()}
                error={touched[name] && errors[name]}
                defaultValue={defaultValue}
                endAdornment={endAdornment}
                value={defaultValue}
                inputProps={({ maxLength: maxLengthNotCharCount })}
                fullWidth
                {...{ ...props, mask }}
              />
            )
        }
      </Box>
    </>
  );
};

InputField.propTypes = {
  locationShow: bool,
  setLocationShow: func,
  phoneShow: bool,
  setPhoneShow: func,
  emailShow: bool,
  aboutMeShow: bool,
  setAboutMeShow: func,
  setEmailShow: func,
  introShow: bool,
  setPreviewShow: func,
  setIntroShow: func,
  setBannerShow1: func,
  focusOn: bool,
  isAvailableURL: bool,
  setIsAvailableURL: func,
  field: shape({
    onBlur: func,
    onChange: func,
    name: string,
  }),
  form: shape({
    touched: object,
    errors: object,
    values: object,
  }),
  label: string,
  maxLength: number,
  maxLengthNotCharCount: number,
  startsWith: string,
  parentName: string,
  startsWithShorthand: string,
  showHideMap: object,
  callApi: func,
  language: string,
  initialData: object,
  editEmail: bool,
  editPhone: bool,
  restoreValue: bool,
};

export default InputField;
