import React, { useContext } from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { string, func } from 'prop-types';
import { StyledRadio } from '@avon/component-library';

import Divider from '@material-ui/core/Divider';

import PmaContext from '../../PmaContext';


const useStyles = makeStyles(theme => ({

  formGroup: {
    display: 'flex',
    width: '100%',
    paddingRight: styleProps => (styleProps.language === 'ar' ? 'unset' : theme.spacing(5)),
    paddingLeft: styleProps => (styleProps.language === 'ar' ? theme.spacing(5) : 'unset'),
    marginLeft: styleProps => (styleProps.language === 'ar' ? '-11px' : '16px'),
    marginRight: styleProps => (styleProps.language === 'ar' ? '16px' : '-11px'),
    justifyContent: 'space-between',
    color: theme.palette.colors.textMedium,
    fontWeight: '700',
  },
  divider: {
    marginLeft: '-12px',
    marginRight: '-12px',
  },

}));

const Sort = ({ sortValue, setSort, setDisable }) => {
  const [{
    leadsContent:
    { sortFilter: { sortFilterTabInfo: { sort } } },
    language,
  }] = useContext(PmaContext);
  const classes = useStyles({ language });
  const handleChange = (event) => {
    setSort(event.target.value);
    setDisable(false);
  };

  return (
    <>
      <FormControl component="fieldset" fullWidth>
        <RadioGroup
          aria-label="gender"
          id="selector-gender"
          name="sort"
          className={classes.group}
          value={sortValue}
          onChange={handleChange}
        >
          {sort.sortOptions.map(field => (
            <Box key={field.value}>
              <Box pt={2} pb={2}>
                <FormControlLabel
                  data-testid="selector-option"
                  id={`selector-option-${field.value}`}
                  name={`selector-option-${field.value}`}
                  value={field.value}
                  label={field.radioName}
                  className={classes.formGroup}
                  control={<StyledRadio iconSize="20px" />}
                  labelPlacement="start"
                />
              </Box>
              <Divider className={classes.divider} />
            </Box>
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default Sort;

Sort.propTypes = {
  setDisable: func,
  sortValue: string,
  setSort: func,
};
