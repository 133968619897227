import React, { useState, useRef } from 'react';
import {
  Box, Avatar, Fab, Modal, Paper, Typography, Popover,
} from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import useTheme from '@material-ui/styles/useTheme';
import {
  string, bool, func, object,
} from 'prop-types';
import loadImage from 'blueimp-load-image';
import { Button } from '@avon/component-library';
import { CloseRounded } from '@material-ui/icons';
import avatarPlaceholder from './avatarPlaceholder.svg';
import { isMobile } from '../../../utils/getDeviceType';
import cameraIcon from './assets/camera.svg';
import lineCopyIcon from './assets/LineCopy.svg';
import Editor from '../Editor';

const elementShadow = '#d8d8d8 0 2px 8px';
const borderWidth = 0;

const useStyles = makeStyles(theme => ({
  canvas: {
  },
  avatar: {
    height: '130px',
    width: '130px',
  },
  borderBox: {
    boxShadow: elementShadow,
    border: `${borderWidth}px solid ${theme.palette.secondary.main}`,
    borderRadius: '100%',
    marginBottom: '10px',
    position: 'relative',
    background: theme.palette.colors.lightZircon,
  },
  fab: {
    boxShadow: elementShadow,
    position: 'absolute',
    right: styleProps => (styleProps.language === 'ar' ? 'unset' : `-${borderWidth / 2}px`),
    left: styleProps => (styleProps.language === 'ar' ? `-${borderWidth / 2}px` : 'unset'),
    bottom: `-${borderWidth / 2}px`,
    '&:hover': {
      backgroundColor: 'white',
    },
    '& label': {
      cursor: 'pointer',
    },
  },
  input: {
    display: 'none',
  },
  avatarDropdownList: {
    position: 'absolute',
    top: 32,
    left: e => (e.language === 'ar' ? 'unset' : -15),
    right: e => (e.language === 'ar' ? -15 : 'unset'),
    zIndex: 10,
    padding: 6,
    border: '1px solid #E4EDED',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    background: '#ffffff',
    textAlign: 'left',
    // maxWidth: '42vw',

    '&::before': {
      content: "''",
      position: 'absolute',
      top: -10,
      left: 8,
      zIndex: 10,
      borderBottom: '10px solid #E4EDED',
      borderLeft: '9px solid transparent',
      borderRight: '9px solid transparent',
      width: 0,
      height: 0,
    },
    '&::after': {
      content: "''",
      position: 'absolute',
      top: -9,
      left: 8,
      zIndex: 10,
      borderBottom: '10px solid #FFFFFF',
      borderLeft: '9px solid transparent',
      borderRight: '9px solid transparent',
      width: 0,
      height: 0,
    },
  },
  avatarDropdownItem: {
    padding: '0 6px',
    fontFamily: 'Montserrat',
    fontSize: 14,
    lineHeight: '34px',
    color: '#181818',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    '&:first-child': {
      fontFamily: 'Montserrat',
      color: '#181818',
      textTransform: 'capitalize',
    },
    '&:hover': {
      background: '#F6F9F9',
    },
  },
}));

const AvatarHeader = ({
  avatarUrl = avatarPlaceholder, editButton, alt, language, onComplete, labels, takePicture,
  uploadPicture, dragPicture, NoCameraFoundModal, allowCameraModal, deletePicture,
  deleteImage, uploadAvatar,
}) => {
  // const isWebApp = sessionStorage.getItem('isWebApp') && !isMobile();
  const classes = useStyles({ language });
  const theme = useTheme();
  const videoRef = useRef();
  const canvasRef = useRef();
  const imageEditorRef = useRef();
  const avatarDropdownList = isMobile() ? [
    { value: uploadPicture, type: 'upload' },
    { value: deletePicture, type: 'delete' },
  ] : [
    { value: takePicture, type: 'camera' },
    { value: uploadPicture, type: 'upload' },
    { value: deletePicture, type: 'delete' },
  ];

  const allow = allowCameraModal || {};
  const notFount = NoCameraFoundModal || {};

  // const [showDropdown, setShowDropdown] = useState(false);
  const [uploadType, setUploadType] = useState();
  const [modalAllow, setModalAllow] = useState(false);
  const [cameraModal, setCameraModal] = useState(false);
  const [modalContent, setModalContent] = useState({ ...allow });
  const [editorImage, setEditorImage] = useState();
  const [mediaStreamTrack, setMediaStreamTrack] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handlClickAvatarDropdownItem = (e, type) => {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();
    // setShowDropdown(false);
    setUploadType(type);
    if (type === 'upload') {
      document.getElementById('edit-upload-photo').click();
    } else if (type === 'delete') {
      deleteImage();
    } else {
      // Older browsers might not implement mediaDevices at all, so we set an empty object first
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }

      // Some browsers partially implement mediaDevices. We can't just assign an object
      // with getUserMedia as it would overwrite existing properties.
      // Here, we will just add the getUserMedia property if it's missing.
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = (constraints) => {
          // First get ahold of the legacy getUserMedia, if present
          const getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

          // Some browsers just don't implement it - return a rejected promise with an error
          // to keep a consistent interface
          if (!getUserMedia) {
            setModalContent({ ...notFount });
            setModalAllow(true);
            return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
          }

          // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
          return new Promise(((resolve, reject) => {
            getUserMedia.call(navigator, constraints, resolve, reject);
          }));
        };
      }

      navigator.mediaDevices.getUserMedia({ video: true, audio: false })
        .then((stream) => {
          setModalAllow(false);
          setCameraModal(true);
          setMediaStreamTrack(stream);
          // Older browsers may not have srcObject
          if ('srcObject' in videoRef.current) {
            videoRef.current.srcObject = stream;
          } else {
            // Avoid using this in new browsers, as it is going away.
            videoRef.current.src = window.URL.createObjectURL(stream);
          }
          videoRef.current.onloadedmetadata = () => {
            videoRef.current.play();
          };
        })
        .catch((err) => {
          setModalContent({ ...notFount });
          setModalAllow(true);
          // eslint-disable-next-line
          console.log(`${err.name}: ${err.message}`);
        });
    }
    handleClose();
  };

  const handleGenerateAvatar = () => {
    if (uploadType === 'camera') {
      const context = canvasRef.current.getContext('2d');
      if (isMobile()) {
        const ActWidth = window.innerWidth - 10;
        // eslint-disable-next-line
        const ActHeight = parseInt(ActWidth * 0.73, 0);
        context.drawImage(videoRef.current, 0, 0, `${ActWidth}`, `${ActHeight}`);
      } else {
        context.drawImage(videoRef.current, 0, 0, '648', '469');
      }
      const tempImg = canvasRef.current.toDataURL('image/jpeg');
      setEditorImage(tempImg);
      setUploadType('upload');
      // eslint-disable-next-line
      mediaStreamTrack.getTracks().forEach(track => track.stop());
    } else {
      imageEditorRef.current.handleConfirm();
      setCameraModal(false);
    }
  };

  const handleEditUpload = ({ target }) => {
    setCameraModal(true);
    loadImage(target.files[0], (img) => {
      setEditorImage(img.toDataURL('image/jpeg'));
    }, { orientation: true });
  };

  const preview = sessionStorage.getItem('previewEdit');
  let previewAvatar;
  if (preview) {
    const previewData = JSON.parse(preview);
    // eslint-disable-next-line
    previewAvatar = previewData.previewAvatar;
  }

  return (
    <>
      <Box className={classes.canvas}>
        <Box display="flex" justifyContent="center">
          <Box className={classes.borderBox}>
            <Avatar alt={alt} src={previewAvatar || avatarUrl} className={classes.avatar} />
            {editButton ? (
              <Fab id="prpman-edit-img" size="small" color="secondary" aria-label="edit-image" className={classes.fab}>
                <Box position="relative">
                  {/* eslint-disable-next-line */}
                  <div
                    onClick={handleClick}
                  // onClick={() => setShowDropdown(!showDropdown)}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ transform: language === 'ar' ? 'rotateY(180deg)' : 'rotateY(0)' }}>
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0H24V24H0z" />
                        <path fill={theme.palette.colors.corePink} fillRule="nonzero" d="M14.295 4.121c1.495-1.495 3.919-1.495 5.414 0 1.495 1.495 1.495 3.92 0 5.415l-9 9c-.11.11-.244.192-.391.241l-6 2c-.782.26-1.526-.483-1.265-1.265l2-6c.05-.147.132-.28.242-.39zM13.5 7.742l-6.626 6.627-1.292 3.878 3.877-1.293 6.627-6.626-2.586-2.586zm4.794-2.206c-.714-.715-1.872-.715-2.586 0l-.794.792 2.586 2.586.794-.793c.634-.634.705-1.62.211-2.332l-.099-.13z" />
                      </g>
                    </svg>
                    <input
                      value=""
                      accept="image/*"
                      className={classes.input}
                      id="edit-upload-photo"
                      onChange={handleEditUpload}
                      type="file"
                      data-testid="avatar-upload-input"
                    />
                  </div>
                  {/* {isNotMobile ?
                    <Box
                      className={classes.avatarDropdownList}
                      style={{ display: showDropdown ? 'unset' : 'none' }}
                    >
                      {
                        avatarDropdownList.map(({ value, type }) => (
                          <Box
                            className={classes.avatarDropdownItem}
                            key={`notification-detail-select-key-${type}`}
                            onClick={e => handlClickAvatarDropdownItem(e, type)}
                          >
                            {!uploadAvatar && type === 'delete' ? null : value}
                          </Box>
                        ))
                      }
                    </Box>
                    : */}
                  <Popover
                    id="popover-box"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    {
                      avatarDropdownList.map(({ value, type }) => (
                        <Box
                          className={classes.avatarDropdownItem}
                          key={`notification-detail-select-key-${type}`}
                          onClick={e => handlClickAvatarDropdownItem(e, type)}
                        >
                          {!uploadAvatar && type === 'delete' ? null : value}
                        </Box>
                      ))
                    }
                  </Popover>
                  {/* } */}
                </Box>

              </Fab>
            ) : null}
          </Box>
        </Box>
      </Box>
      {/* allow camera modal */}
      <Modal
        data-testid="prpman-avatar-upload-modal"
        id="prpman-avatar-upload-modal"
        open={modalAllow}
        style={{
          overflow: 'auto',
        }}
      >
        <Box
          style={{ maxWidth: 648 }}
          position="relative"
          top={window.screen.height > 640 ? '15%' : '2%'}
          transform="translate(-50%)"
          margin="auto"
        >
          <Box
            style={{
              position: 'absolute',
              top: 12,
              right: 12,
              cursor: 'pointer',
            }}
            onClick={() => setModalAllow(false)}
          >
            <CloseRounded />
          </Box>
          <Paper>
            <Box p={15} data-testid="avatar-modal" id="prpman-avatar-modal">
              <Box textAlign="center">
                <Typography variant="h2">{modalContent.heading}</Typography>
              </Box>
              <Box mt={9} textAlign="center">
                <Typography priority="h4">{modalContent.body}</Typography>
              </Box>
              <Box mt={9}>
                <Button
                  priority="secondary"
                  name="prpman-submit-modal-save"
                  id="prpman-submit-modal-save"
                  onClick={() => setModalAllow(false)}
                >
                  {modalContent.button}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Modal>
      {/* camera modal */}
      <Modal
        data-testid="prpman-camera-photo-modal"
        id="prpman-camera-photo-modal"
        open={cameraModal}
        style={{
          overflow: 'auto',
        }}
      >
        <Box
          style={{
            width: 648,
            position: 'absolute',
            left: '50%',
            marginLeft: '-324px',
            top: '50%',
            marginTop: '-335px',
          }}
        >
          <Box
            style={{
              position: 'absolute',
              top: 12,
              right: 12,
              cursor: 'pointer',
            }}
            onClick={() => {
              setCameraModal(false);
              // eslint-disable-next-line
              mediaStreamTrack ? mediaStreamTrack.getTracks().forEach(track => track.stop()) : null
            }}
          >
            <CloseRounded />
          </Box>

          <Paper>
            <Box pt={13} pb={15} data-testid="camera-modal" id="prpman-camera-modal-web">
              {!isMobile()
                ? (
                  <Box textAlign="center" pb={42 / 4}>
                    <Box style={{
                      fontFamily: 'Montserrat-Light',
                      fontSize: 36,
                      lineHeight: '48px',
                      color: '#181818',
                    }}
                    >
                      {uploadType === 'camera' ? takePicture : dragPicture}
                    </Box>
                  </Box>
                )
                : null
              }
              <Box style={{
                width: '100%',
                height: 469,
              }}
              >
                <>

                  {!isMobile()
                    ? (
                      <>
                        {/* eslint-disable-next-line */}
                        <video
                          ref={videoRef}
                          id="video"
                          width="100%"
                          height="100%"
                          style={{
                            display: uploadType === 'camera' ? 'block' : 'none',
                          }}
                        />
                        <canvas
                          ref={canvasRef}
                          id="canvas"
                          width="648px"
                          height="469px"
                          style={{ display: 'none' }}
                        />
                      </>
                    )
                    : null
                  }
                  <Box style={{
                    display: uploadType === 'upload' ? 'block' : 'none',
                    height: '100%',
                  }}
                  >
                    <Editor
                      style={{ display: uploadType === 'upload' ? 'block' : 'none' }}
                      editorImage={editorImage}
                      open
                      setEditorImage={setEditorImage}
                      onComplete={onComplete}
                      labels={labels}
                      ref={imageEditorRef}
                      closeModal={() => setCameraModal(false)}
                      // videoRef={videoRef}
                      // handleGenerateAvatar={handleGenerateAvatar}
                      // canvasRef={canvasRef}
                    />
                  </Box>
                </>
              </Box>
            </Box>
          </Paper>
          <Box
            style={{
              position: 'absolute',
              bottom: 22,
              left: uploadType === 'camera' ? 'calc(50% - 38px)' : 'calc(100% - 100px)',
              width: 76,
              height: 76,
              borderRadius: '50%',
              background: '#7F28C4',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={handleGenerateAvatar}
          >
            {
              uploadType === 'camera' ? (
                <img style={{ width: 24, height: 24 }} src={cameraIcon} alt="camera-icon" />
              ) : (
                <img style={{ width: 24, height: 24 }} src={lineCopyIcon} alt="linecopy-icon" />
              )
            }
          </Box>
        </Box>
      </Modal>
    </>
  );
};

AvatarHeader.propTypes = {
  avatarUrl: string,
  editButton: bool,
  // onEditClick: func,
  alt: string,
  language: string,
  onComplete: func,
  labels: object,
  takePicture: string,
  uploadPicture: string,
  dragPicture: string,
  NoCameraFoundModal: object,
  allowCameraModal: object,
  deletePicture: string,
  deleteImage: func,
  uploadAvatar: bool,
};


export default AvatarHeader;
