import React from 'react';
import {
  ResponsiveContainer, PieChart, Pie, Cell, Legend, Tooltip,
} from 'recharts';
import {
  arrayOf, object, number, shape, array, bool, string,
} from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  legendProps, pieCustomizedLabel, tooltipProps,
} from './utils';

const useStyles = makeStyles(() => ({
  bars: {
    '@media (max-width: 360px)': {
      height: '310px',
    },
  },
  warp: {
    direction: 'ltr',
    '& > svg': {
      direction: styleProps => (styleProps.language === 'ar' ? 'rtl' : 'ltr'),
    },
    '& div > ul > li': {
      marginLeft: styleProps => (styleProps.language === 'ar' ? '10px' : '0px !important'),
      marginRight: styleProps => (styleProps.language === 'ar' ? '0px !important' : '10px'),
    },
    '& div > ul > li > svg': {
      marginLeft: styleProps => (styleProps.language === 'ar' ? '4px' : '0px !important'),
      marginRight: styleProps => (styleProps.language === 'ar' ? '0px !important' : '4px'),
    },
    '& [class*="recharts-tooltip-wrapper"]': {
      '@media (max-width: 345px)': {
        left: '-30px',
      },
      '@media (max-width: 320px)': {
        left: '-40px',
      },
    },
  },
}));

const Chart = ({
  data, height, margin, colors, loading, dataKeys, animationDuration, activeAnimation, language,
}) => {
  const tipProps = tooltipProps();
  tipProps.language = language;
  const classes = useStyles({ language });
  const pie = data.map((entry, index) => <Cell key={`cell-${index}`} fill={colors[index]} className={classes.bars} />);

  const pieWidth = window.innerWidth - 82;
  return (
    <ResponsiveContainer height={height} width="100%">
      <PieChart margin={margin} className={classes.warp}>
        <Pie
          isAnimationActive={activeAnimation}
          animationDuration={animationDuration}
          startAngle={90}
          endAngle={450}
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={!loading ? pieCustomizedLabel : undefined}
          outerRadius={pieWidth < height ? pieWidth * 0.4 : height * 0.4}
          nameKey="name"
          dataKey={dataKeys[0]}
        >
          {pie}
        </Pie>
        {!loading && <Legend {...legendProps(language)} />}
        {!loading && <Tooltip {...tipProps} />}
      </PieChart>
    </ResponsiveContainer>
  );
};

Chart.propTypes = {
  data: arrayOf(object),
  dataKeys: arrayOf(string),
  height: number,
  margin: shape({
    top: number, right: number, left: number, bottom: number,
  }),
  colors: array.isRequired,
  loading: bool,
  animationDuration: number,
  activeAnimation: bool,
  language: string,
};

Chart.defaultProps = {
  margin: {
    top: 40, right: 10, left: 10, bottom: 5,
  },
  loading: false,
  animationDuration: 500,
  dataKeys: ['value'],
};

export default Chart;
