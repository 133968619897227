import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { AvonContainer } from '@avon/component-library';
import makeStyles from '@material-ui/styles/makeStyles';
import { bool } from 'prop-types';


const useStyles = makeStyles(theme => ({

  card: {
    maxWidth: '100%',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.colors.zircon,
    borderRadius: '5px',
    boxShadow: '0px 0px',
    height: 156,
  },
  background: {
    backgroundColor: theme.palette.colors.lightZircon,
    minHeight: window.innerHeight,
    height: '100%',
    overflowY: 'hidden',
  },

}));

const SkeletonLoader = ({
  headVisiable,
}) => {
  const classes = useStyles();
  const elements = [
    ['90%', '70%', '60%', '40%'],
    ['40%', '20%', '50%', '30%'],
    ['30%', '40%', '70%', '60%'],
    ['70%', '70%', '60%', '40%'],
    ['80%', '70%', '50%', '90%'],
  ];
  return (
    <div className={classes.background}>
      <AvonContainer maxWidth="lg">
        {
          headVisiable && (
            <Box pt={7} pb={5}>
              <Skeleton height={25} />
            </Box>
          )
        }
        {elements.map((card, cardNumber) => (
          <Box pb={3} key={`card-item-${cardNumber}`}>
            <Card pb={2} className={classes.card}>
              {card.map((item, index) => (
                <Box p={2} key={`card-content-${index}`}>
                  <Skeleton
                    height={20}
                    width={elements[cardNumber][index]}
                  />
                </Box>
              ))}
            </Card>
          </Box>
        ))}
      </AvonContainer>
    </div>
  );
};

SkeletonLoader.propTypes = {
  headVisiable: bool,
};

SkeletonLoader.defaultProps = {
  headVisiable: true,
};

export default SkeletonLoader;
