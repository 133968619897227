import React, {
  useContext, useState, useEffect, useRef, useMemo, useCallback,
} from 'react';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  AvonContainer, Typography, AvonIcon,
} from '@avon/component-library';
import { Card, CircularProgress } from '@material-ui/core';
import PullToRefresh from 'pulltorefreshjs';
import ReactDOMServer from 'react-dom/server';
import debounce from '../../../utils/debounce';
import ListComponent from './ListComponent';
import LmaContext from '../../LmaContext';
import { useTracking } from '../../../analytics/Analytics';
import FilterAndSort from '../FilterAndSort';
import SkeletonLoader from '../SkeletonLoader';
import FileterAndSortBar from '../FileterAndSortBar';
import { isMobile, isTablet } from '../../../utils/getDeviceType';
import InputSearch from '../InputSearch';

const useStyles = makeStyles(theme => ({
  background: {
    backgroundColor: e => (e.isWebApp ? '#ffffff' : theme.palette.colors.lightZircon),
    height: '100%',
    overflowY: 'hidden',
    '& .ptr--ptr': {
      boxShadow: 'initial',
    },
  },
  image: {
    verticalAlign: 'middle',
    paddingLeft: styleProps => (styleProps.language === 'ar' ? 'unset' : theme.spacing(2)),
    paddingRight: styleProps => (styleProps.language === 'ar' ? theme.spacing(2) : 'unset'),
    transform: styleProps => (styleProps.language === 'ar' ? 'rotateY(180deg)' : 'rotateY(0)'),
    display: 'inline-block',
  },
  iconBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  search: {
    borderRadius: '0px',
    boxShadow: '0 0 18px 0 rgba(0, 0, 0, 0.25)',
  },
  badge: {
    backgroundColor: theme.palette.colors.green,
  },
  filterTitleText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
    },
  },
  listWarp: {
    maxHeight: 'calc(100% + 100px)',
    '& > div': {
      direction: 'unset !important',
    },
  },
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const RepCardContainer = () => {
  const [open, setOpen] = useState(false);
  const [{
    records,
    apiBody,
    search,
    filters,
    sort,
    language,
    spinner,
    firstFetch,
    sortOrFilter,
    leadsContent: { title, sortFilter }, hasNextPage,
  }, dispatch] = useContext(LmaContext);
  const isWebApp = sessionStorage.getItem('isWebApp') && !isMobile();
  const classes = useStyles({ language, isWebApp });
  const [sortValue, setSort] = useState(sort);
  const [filterValue, setFilter] = useState({ ...filters });
  const [showSearch, setShowSearch] = useState(true);
  const sortAndFilter = {
    sortValue,
    setSort,
    filterValue,
    setFilter,
  };
  const inputRef = useRef({ value: '' });
  const progressProps = {
    size: '50px',
    color: 'grey',
  };
  // Analytics
  const [, setTracking] = useTracking();
  const searchHis = sessionStorage.getItem('searchHis');

  const fetchMoreListItems = useCallback(() => {
    const {
      pagination: { pageSize, pageIndex },
    } = apiBody;
    dispatch({
      apiBody: {
        ...apiBody, pagination: { pageSize, pageIndex: pageIndex + 1 },
      },
    });
  }, [apiBody, dispatch]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClear = () => {
    setFilter({});
    setSort('');
    const {
      pagination: { pageSize },
    } = apiBody;
    dispatch({
      filters: {},
      sort: '',
      search: '',
      sortOrFilter: false,
      apiBody: {
        ...apiBody,
        pagination: { pageSize, pageIndex: 0 },
        searchStr: '',
        sortName: '',
        filters: {},
      },
    });
  };

  const onChange = debounce((inputValue) => {
    // if (inputValue.replace(/\s*/g, '') === '') {
    //   // eslint-disable-next-line
    //   inputValue = '';
    //   dispatch({
    //     firstFetch: 'y',
    //   });
    // }
    if (!isMobile()) {
      window.parent.postMessage({ search: inputValue }, '*');
      sessionStorage.setItem('searchHis', inputValue);
    }

    const {
      pagination: { pageSize },
    } = apiBody;

    dispatch(inputValue ? {
      search: inputValue,
      apiBody: {
        ...apiBody,
        pagination: { pageSize, pageIndex: 0 },
        searchStr: inputValue,
      },
    }
      : {
        apiBody: {
          ...apiBody,
          pagination: { pageSize, pageIndex: 0 },
          searchStr: inputValue,
        },
      });
  }, 500);

  useEffect(() => {
    setTracking({ eventType: 'pageview', eventName: 'lmaViewAllLeads' });

    if (!search.current && searchHis && !isMobile()) {
      onChange(searchHis);
    }

    let preScroll = 0;
    const handleScroll = (e) => {
      if (preScroll > e.target.scrollTop) {
        setShowSearch(false);
      } else {
        setShowSearch(true);
      }
      preScroll = e.target.scrollTop;
    };
    if (isMobile() || isTablet()) {
      document.addEventListener('scroll', handleScroll, true);
    }

    return () => {
      if (isMobile() || isTablet()) {
        document.removeEventListener('scroll', handleScroll, true);
      }
    };
  }, []);

  useEffect(() => {
    PullToRefresh.destroyAll();
    PullToRefresh.init({
      mainElement: '#lma-refreshElemnet',
      triggerElement: '#lma-refreshElemnet',
      direction: 'down',
      refreshTimeout: 100,
      distMax: 200,
      distReload: 200,
      distIgnore: 0,
      instructionsPullToRefresh: ' ',
      instructionsReleaseToRefresh: ' ',
      instructionsRefreshing: ' ',
      onRefresh: () => {
        onChange(inputRef.current.value || '');
      },
      iconArrow: ReactDOMServer.renderToString(<CircularProgress {...progressProps} />),
      iconRefreshing: ReactDOMServer.renderToString(<CircularProgress {...progressProps} />),
      shouldPullToRefresh: () => !document.querySelector('#lma-refreshElemnet > div > div').scrollTop,
    });
  }, [apiBody]);

  const listItems = useMemo(() => (
    <Box className={classes.listWarp} id="lma-refreshElemnet">
      <ListComponent
        language={language}
        data-testid="scroll"
        items={records}
        loadMore={fetchMoreListItems}
        hasNextPage={hasNextPage}
        inputRef={inputRef}
      />
    </Box>
  ), [records, fetchMoreListItems, hasNextPage]);

  const inputOnChange = (event) => {
    onChange(event.target.value || '');
  };

  const keys = Object.keys(filterValue);
  const values = keys.filter(key => filterValue[key]);

  return (
    <Box className={classes.background}>
      {
        isWebApp ? (
          <FileterAndSortBar
            page="repCard"
            inputOnChange={inputOnChange}
            search={search || searchHis}
            spinner={spinner}
            firstFetch={firstFetch}
            sortOrFilter={sortOrFilter}
            handleClear={handleClear}
            {...sortAndFilter}
            showSearch={showSearch}
          />
        ) : (
          <Card className={classes.search} style={{ display: showSearch ? 'block' : 'none' }}>
            <Box pb={3} pl={5} pr={5} pt={3}>
              <Box position="relative">
                <InputSearch
                  page="repCard"
                  inputOnChange={inputOnChange}
                  search={search}
                  spinner={spinner}
                  firstFetch={firstFetch}
                  sortOrFilter={sortOrFilter}
                  handleClear={handleClear}
                />
              </Box>
            </Box>
          </Card>
        )
      }
      <AvonContainer
        maxWidth="lg"
        id="lma-AvonContainer"
        style={{ height: 'calc(100% - 128px)', overflowY: isMobile() ? 'unset' : 'auto' }}
      >
        {
          isWebApp ? null : (
            <Box pt={6} pb={5} display="flex" justifyContent="space-between">
              <Typography color="textPrimary" display="inline">{title}</Typography>
              <Badge
                badgeContent={values.length}
                color="primary"
                classes={{ badge: classes.badge }}
              >
                <AvonIcon
                  type="sort"
                  alt="sort-icon"
                  id="lma-sort-icon"
                  onClick={handleClickOpen}
                  width="24px"
                  height="24px"
                />
              </Badge>
            </Box>
          )
        }
        {spinner !== 'hide' ? <SkeletonLoader headVisiable={false} /> : listItems}
      </AvonContainer>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} id="lma-dialog">
        <Box
          pb={5}
          pl={language === 'ar' ? 'unset' : 4}
          pr={language === 'ar' ? 4 : 'unset'}
          pt={5}
          onClick={handleClose}
        >
          <Box className={classes.image}>
            <AvonIcon
              type="back"
              alt="back-icon"
              id="lma-back-icon"
              height="36px"
              width="36px"
            />
          </Box>
          <Typography variant="body1" weight="bold" display="inline">{sortFilter.back}</Typography>
        </Box>
        <Box align="center" pb={4}>
          <Typography classes={{ root: classes.filterTitleText }} variant="h2" weight="light" display="inline" align="center">
            {sortFilter.title}
          </Typography>
        </Box>
        <FilterAndSort
          setOpen={setOpen}
          {...sortAndFilter}
        />
      </Dialog>
    </Box>
  );
};


export default RepCardContainer;
