const map = {
  bar: [6, 4, 4],
  pie: [3, 50],
  line: [6, 6, 2],
  vertbar: [6, 4, 4],
};

const genData = (i, range, base = 5, count = 1) => {
  const data = [];
  for (let x = 0; x <= i; x += 1) {
    const temp = { name: x.toString() };
    for (let y = 1; y <= count; y += 1) {
      temp[`value_${y}`] = base + Math.round(Math.random() * range);
    }
    data.push(temp);
  }
  return data;
};

const placeholderData = (type) => {
  const genType = map[type] || map.bar;
  return genData(...genType);
};

export default placeholderData;
