import React, { useContext, useEffect } from 'react';
import { useError } from '@avon/component-library';
import RepCardContainer from '../RepCardContainer';
import ProfileContainer from '../ProfileContainer';
import LmaContext from '../../LmaContext';
import SkeletonLoader from '../SkeletonLoader';
import NoLeads from '../NoLeads';
import { getLeadsByStatus, getViewAllLeads } from '../../../utils/api';

const SwitchView = (() => {
  const { setError, cleanToastBanner } = useError();
  const [{
    page, callApi, apiBody, records, leadsContent, sharePRP, apptId, firstFetch,
  }, dispatch] = useContext(LmaContext);

  useEffect(() => {
    const fetchLeadContent = async () => {
      try {
        const response = await callApi(getViewAllLeads());
        dispatch({ leadsContent: response.jsonObject });
        cleanToastBanner();
      } catch ({ error }) {
        setError(error);
      }
    };
    fetchLeadContent();
    // eslint-disable-next-line
  }, []);


  const dispatchObject = (response) => {
    const hasNextPage = response.records.length < 20 ? 'false' : 'true';
    if (!response.hasData) {
      return { page: 'no leads' };
    }

    if (apiBody.pagination.pageIndex) {
      return { records: [...records, ...response.records], page: 'rep card', hasNextPage };
    }

    if (response.pagination.recordCount) {
      return {
        records: response.records, page: 'rep card', pageCount: 'false', hasNextPage,
      };
    }
    return { pageCount: 'true', page: 'rep card' };
  };

  useEffect(() => {
    const fetchLeadData = async () => {
      try {
        if (firstFetch !== 'y') {
          const { searching = 'Searching' } = leadsContent || {};
          dispatch({
            spinner: searching,
          });
        }
        const response = await callApi(getLeadsByStatus(apiBody));
        dispatch(dispatchObject(response));
        cleanToastBanner();
      } catch ({ error }) {
        setError(error);
      }
      dispatch({ spinner: 'hide', firstFetch: 'n' });
    };
    fetchLeadData();
    // eslint-disable-next-line
  }, [apiBody]);
  if (leadsContent && apptId) {
    // setTracking({ eventType: 'pageview', eventName: 'lmaProfile' });
    return <ProfileContainer />;
  }
  if (leadsContent && page === 'rep card') {
    // setTracking({ eventType: 'pageview', eventName: 'lmaViewAllLeads' });
    return <RepCardContainer />;
  }
  if (leadsContent && page === 'no leads') {
    // setTracking({ eventType: 'pageview', eventName: 'lmaNoLeads' });
    return <NoLeads {...leadsContent.noLeadsComponents} handleClick={sharePRP} />;
  }
  return <SkeletonLoader />;
});

export default SwitchView;
