import React, { useReducer } from 'react';
import Helmet from 'react-helmet';
import {
  func, string, array, oneOfType,
} from 'prop-types';
import useTheme from '@material-ui/styles/useTheme';
import mainReducer from './reducers';
import LmaContext from './LmaContext';
import SwitchView from './components/SwitchView';
import { mobile } from '../utils/handlers';
import { isMobile } from '../utils/getDeviceType';

window.setAccessToken = accessToken => sessionStorage.setItem('accessToken', accessToken);

const mobileFunctions = {
  editAppointment: !sessionStorage.getItem('isWebApp')
    ? (apptId, editStep = '', isAfterSummary = false) => mobile.handler('editAppointment', { apptId, editStep, isAfterSummary })
    : (apptId, editStep = '', isAfterSummary = false) => window.parent.postMessage({ menu: 'appointment', params: `apptId=${apptId}&editStep=${editStep}&isAfterSummary=${isAfterSummary}&ver=${new Date()}` }, '*'),
  sharePRP: () => mobile.handler('sharePRP', { type: 'lma' }),
};

const Lma = ({
  callApi, apptId, status, language, market,
}) => {
  let statusArray;
  const filters = {};
  if (status) {
    statusArray = Array.isArray(status) ? status : [status];
    statusArray.map((value) => {
      filters[value] = true;
      return filters;
    });
  }

  const theme = useTheme();

  const initialState = {
    apptId,
    records: [],
    profile: null,
    page: 'loading',
    search: '',
    filters,
    // sort: 'NEWEST',
    sort: '',
    hasNextPage: true,
    leadsContent: false,
    pageCount: true,
    callApi,
    statusColors: {
      new: theme.palette.colors.corePink,
      in_progress: theme.palette.colors.warning,
      awaiting_acceptance: theme.palette.colors.information,
      expired: '#A2ACAE',
      pending: theme.palette.colors.textLight,
    },
    apiBody: {
      searchStr: '',
      pagination: { pageSize: (sessionStorage.getItem('isWebApp') && !isMobile()) ? 100 : 20, pageIndex: 0 },
      filters: {
        Status: statusArray,
      },
    },
    language,
    market,
    spinner: 'hide',
    firstFetch: 'y',
    sortOrFilter: false,
    ...mobileFunctions,
  };

  switch (language) {
    case 'ar':
      // set direction to rtl
      document.body.dir = 'rtl';
      break;

    case 'ru':
    case 'tr':
    case 'tl':
      // set direction to ltr
      document.body.dir = 'ltr';
      break;

    default:
      // Do nothing
      break;
  }
  return (
    <>
      <Helmet htmlAttributes={{ lang: language || 'en' }} />
      <LmaContext.Provider value={useReducer(mainReducer, initialState)}>
        <SwitchView />
      </LmaContext.Provider>
    </>
  );
};

export default Lma;

Lma.propTypes = {
  callApi: func.isRequired,
  apptId: string,
  status: oneOfType([string, array]),
  language: string,
  market: string,
};
