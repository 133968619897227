import noop from '../noop';

export const iPhone = (method, paramsObject = {}) => {
  const msg = `{"method": "${method}", "params": "${JSON.stringify(paramsObject).replace(/"/g, '\\"')}"}`;
  window.webkit.messageHandlers.avonRec.postMessage(msg);
};

export const Android = (method, andriodObject = {}) => {
  if (window.Android) {
    window.Android.dispatcher(method, JSON.stringify(andriodObject));
  } else {
    noop();
  }
};

export const backHome = () => {
  if (window.navigator.userAgent.match(/(iPhone)|(iPad)|(Macintosh)/i)) {
    iPhone('backHome', {});
  } else {
    Android('backHome', {});
  }
};

export const pmaProfileBack = (title = '', url = '') => {
  if (window.navigator.userAgent.match(/(iPhone)|(iPad)|(Macintosh)/i)) {
    iPhone('backAction', {
      action: 'goWebUrl', title, url, showHeader: true,
    });
  } else {
    Android('backAction', {
      action: 'goWebUrl', title, url, showHeader: true,
    });
  }
};

export const pmaListBack = (title = '', url = '', showHeader = true) => {
  if (window.navigator.userAgent.match(/(iPhone)|(iPad)|(Macintosh)/i)) {
    iPhone('backAction', {
      action: 'pop', title, url, showHeader,
    });
  } else {
    Android('backAction', {
      action: 'pop', title, url, showHeader,
    });
  }
};
