import flatten from '../../../utils/flatten';

// Use this to assign components that should not have form input values
const ignore = [
  'Heading',
];

const mapInit = (initValue, type) => {
  const mappers = {
    default: () => initValue || '',
  };

  const mapper = mappers[type] ? mappers[type] : mappers.default;
  return mapper(initValue);
};

export const mapInitialValues = (initValues = {}, fields = []) => flatten(fields).reduce(
  (acc, field) => {
    const { properties: { name }, type } = field;
    if (!ignore.includes(type)) {
      acc[name] = mapInit(initValues[name], type, field);
    }
    return acc;
  }, {},
);

const mapSub = (subValue, type) => {
  const mappers = {
    default: () => subValue,
  };

  return mappers[type] ? mappers[type]() : mappers.default();
};

export const mapSubmitValues = (values = {}, types) => Object.entries(values).reduce(
  (acc, [name, value]) => {
    const val = mapSub(value, types[name]);
    if (val) acc[name] = val;
    return acc;
  }, {},
);
