import React, {
  useContext, useState, useRef, useCallback, useEffect,
} from 'react';
import { func, string, bool } from 'prop-types';
import {
  Box, Typography, InputAdornment, IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { InputField } from '@avon/component-library';
import LmaContext from '../../LmaContext';
import { isTablet } from '../../../utils/getDeviceType';

const useStyles = makeStyles(theme => ({
  inputRoot: {
    backgroundColor: theme.palette.colors.lightZircon,
    justifyContent: 'center',
    borderRadius: '6px',
  },
  noBorder: {
    border: 'none',
  },
  searchInput: {
    padding: '14.5px 14px',
  },
  placeholderMask: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    color: theme.palette.colors.textLight,
    backgroundColor: '#f6f9f9',
  },
  iconContainer: {
    margin: `auto ${theme.spacing(2)}px`,
    display: 'flex',
  },
  iconButton: {
    padding: 1,
    backgroundColor: '#e4eded',
  },
}));

const searchClearIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.6129 6.2097C7.22061 5.90468 6.65338 5.93241 6.29289 6.29289C5.90237 6.68342 5.90237 7.31658 6.29289 7.70711L10.5858 12L6.29289 16.2929L6.2097 16.3871C5.90468 16.7794 5.93241 17.3466 6.29289 17.7071C6.68342 18.0976 7.31658 18.0976 7.70711 17.7071L12 13.4142L16.2929 17.7071L16.3871 17.7903C16.7794 18.0953 17.3466 18.0676 17.7071 17.7071C18.0976 17.3166 18.0976 16.6834 17.7071 16.2929L13.4142 12L17.7071 7.70711L17.7903 7.6129C18.0953 7.22061 18.0676 6.65338 17.7071 6.29289C17.3166 5.90237 16.6834 5.90237 16.2929 6.29289L12 10.5858L7.70711 6.29289L7.6129 6.2097Z" fill="#707677" />
  </svg>
);

const searchIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C12.2105 18 13.7873 17.4274 15.0491 16.4633L19.2929 20.7071L19.3871 20.7903C19.7794 21.0953 20.3466 21.0676 20.7071 20.7071C21.0976 20.3166 21.0976 19.6834 20.7071 19.2929L16.4633 15.0491C17.4274 13.7873 18 12.2105 18 10.5C18 6.35786 14.6421 3 10.5 3ZM10.5 5C13.5376 5 16 7.46243 16 10.5C16 13.5376 13.5376 16 10.5 16C7.46243 16 5 13.5376 5 10.5C5 7.46243 7.46243 5 10.5 5Z" fill="#A2ACAE" />
  </svg>
);

const InputSearch = ({
  page,
  inputOnChange,
  search,
  spinner,
  firstFetch,
  sortOrFilter,
  handleClear,
}) => {
  const [{
    language,
    leadsContent: {
      searchPlaceHolder,
    },
  }] = useContext(LmaContext);
  const classes = useStyles({ language, isTablet: isTablet(), page });
  const [showClear, setShowClear] = useState(false);
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const searchRef = useRef({ value: '' });

  const triggerPlaceholder = useCallback(() => {
    setShowPlaceholder(!(searchRef.current.value || searchRef.current === document.activeElement));
  }, []);

  const inputFocus = useCallback(() => {
    searchRef.current.focus();
    setShowClear(true);
    triggerPlaceholder();
  }, [triggerPlaceholder]);

  useEffect(() => {
    if (spinner !== 'hide') {
      setShowPlaceholder(true);
    } else if (firstFetch === 'y' || sortOrFilter) {
      setShowPlaceholder(true);
    } else {
      setShowPlaceholder(false);
    }
  }, [spinner]);

  useEffect(() => {
    if (search) {
      setShowClear(true);
      searchRef.current.focus();
    } else {
      setShowPlaceholder(true);
    }
  }, [search]);

  return (
    <Box>
      <InputField
        onChange={inputOnChange}
        priority="secondary"
        name="search"
        id="lma-search"
        defaultValue={search}
        fullWidth
        classes={{ notchedOutline: classes.noBorder, root: classes.inputRoot }}
        inputProps={{
          className: classes.searchInput,
          onBlur: triggerPlaceholder,
          onKeyDown: () => setShowClear(true),
        }}
        inputRef={searchRef}
        startAdornment={(
          <InputAdornment position="start">
            {searchIcon}
          </InputAdornment>
        )}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              aria-label="clear"
              className={classes.iconButton}
              onClick={
                () => {
                  searchRef.current.value = '';
                  searchRef.current.focus();
                  setShowClear(false);
                  handleClear();
                }
              }
            >
              {showClear ? searchClearIcon : null}
            </IconButton>
          </InputAdornment>
        )}
      />
      {showPlaceholder && (
        <Box
          className={classes.placeholderMask}
          data-testid="search-placeholder"
          id="search-placeholder"
          onClick={inputFocus}
        >
          {
            spinner !== 'hide' ? (
              <Typography fontFamily="Montserrat" fontSize={14}>{spinner}</Typography>
            ) : (
              <>
                <Box className={classes.iconContainer}>
                  {searchIcon}
                </Box>
                <Typography fontFamily="Montserrat" fontSize={14}>{searchPlaceHolder}</Typography>
              </>
            )
          }
        </Box>
      )}
    </Box>
  );
};

InputSearch.propTypes = {
  page: string,
  inputOnChange: func,
  search: string,
  spinner: string,
  firstFetch: string,
  sortOrFilter: bool,
  handleClear: func,
};

InputSearch.defaultProps = {
  spinner: 'hide',
};

export default InputSearch;
