import React, { useCallback, useState } from 'react';
import {
  func, string, bool, object,
  number,
} from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiModal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import { AvonHeader, Button } from '@avon/component-library';
import MuiButton from '@material-ui/core/Button';
import successIcon from '../../../lma/styles/assets/icons/success.svg';
import warnIcon from '../../../lma/styles/assets/icons/warn.svg';
import { isMobile } from '../../../utils/getDeviceType';


const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    zIndex: 102,
    width: '100%',
    textAlign: 'justify',
    textAlignLast: 'justify',
    paddingTop: theme.spacing(2.5),
    paddingLeft: styleProps => (styleProps.language === 'ar' ? theme.spacing(3) : theme.spacing(1)),
    paddingRight: styleProps => (styleProps.language === 'ar' ? theme.spacing(1) : theme.spacing(3)),
    boxShadow: styleProps => (styleProps.hideToolbarShadow === true ? 'unset' : '0 2px 4px 0 rgba(0, 0, 0, 0.1)'),
    backgroundColor: theme.palette.colors.white,
    '& span': {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 16,
    },
  },
  gridButton1: {
    minHeight: theme.spacing(3),
    width: 'auto',
    paddingTop: 0,
    paddingBottom: 0,
    wordBreak: 'break-word',
    color: theme.palette.colors.textDark,
  },
  gridButton2: {
    minHeight: theme.spacing(3),
    width: 'auto',
    paddingTop: 0,
    paddingBottom: 0,
    wordBreak: 'break-word',
    color: theme.palette.colors.corePink,
    '&:hover': {
      backgroundColor: 'rgba(24, 24, 24, 0.08)',
    },
  },
}));

const Fullpage = ({
  open,
  closeModal,
  heading,
  icon,
  title,
  titleAlign,
  buttonWidth,
  bodyPadding,
  bodyMargin,
  body,
  submitData,
  saveLabel,
  cancelLabel,
  cancelButtonPriority,
  isSubmitting,
  backHome,
  language,
  needReload,
  hideToolbarShadow,
  modalName,
  market,
}) => {
  const isWebApp = sessionStorage.getItem('isWebApp') && !isMobile();
  const styleProps = { language, hideToolbarShadow };
  const classes = useStyles(styleProps);

  const [innerHeight] = useState(window.innerHeight);

  const iconMap = {
    success: <Box textAlign="center" mt={6}><Avatar style={{ width: 64, height: 64, display: 'inline-block' }} src={successIcon} /></Box>,
    warn: <Box textAlign="center" mt={6}><Avatar style={{ width: 64, height: 64, display: 'inline-block' }} src={warnIcon} /></Box>,
  };

  const closeAndReset = useCallback(() => {
    closeModal();
    if (needReload) {
      window.location.reload();
    }
  });

  return (
    <MuiModal
      data-testid="prospect-submit-modal"
      id="prospect-submit-modal"
      open={open}
    >
      <Box
        width="100%"
        position="relative"
        margin="auto"
      >
        <Paper>
          <Box height={innerHeight} p={0} data-testid="error-modal" id="prospect-error-modal">
            {backHome || title ? (
              <Box className={classes.topbar}>
                {
                  !sessionStorage.getItem('isWebApp') && <AvonHeader type={market === 'JT' ? 'JT' : 'avon'} />
                }
                <Box
                  data-testid="toolbar"
                  id="fullpage-prospect-toolbar111"
                  component="div"
                  className={classes.toolbar}
                >
                  {backHome && (
                    <MuiButton
                      className={classes.gridButton1}
                      id="fullpage-back-home-button-prospect"
                      onClick={backHome}
                    >
                      <svg width="60" height="44" viewBox="0 0 60 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M23.3872 15.2096C23.7795 14.9047 24.3467 14.9324 24.7072 15.293L24.7904 15.3872C25.0953 15.7795 25.0676 16.3467 24.707 16.7072L20.4132 21H32C32.5523 21 33 21.4477 33 22C33 22.5128 32.614 22.9355 32.1166 22.9933L32 23H20.4167L24.7071 27.2916L24.7903 27.3858C25.0953 27.7781 25.0676 28.3453 24.7071 28.7058C24.3166 29.0963 23.6834 29.0963 23.2929 28.7058L17.3241 22.737C17.1249 22.5542 17 22.2917 17 22C17 21.9992 17 21.9983 17 21.9975C17.0003 21.7419 17.098 21.4865 17.293 21.2915L23.293 15.2928L23.3872 15.2096Z" fill="#181818" />
                      </svg>

                      {/* {toolBarObject.close} */}
                    </MuiButton>
                  )}
                  <Typography
                    variant="h4"
                    style={{
                      alignSelf: isWebApp ? 'left' : 'center', flexGrow: 1, textAlignLast: titleAlign || 'center', paddingLeft: 24, paddingTop: 12,
                    }}
                  >
                    {title}
                  </Typography>
                  <MuiButton
                    className={classes.gridButton2}
                    id="fullpage-topbar-right-placeholder"
                  />
                </Box>
              </Box>
            ) : null}
            {icon && (
              <Box style={{ paddingTop: sessionStorage.getItem('isWebApp') ? 'unset' : 40 }}>
                {iconMap[icon]}
              </Box>
            )}
            <Box
              height={(sessionStorage.getItem('isWebApp') ? innerHeight : innerHeight - 184) - (icon ? 84 : 0)}
              overflow="auto"
              p={bodyPadding || '40px 31px 20px 33px'}
              style={isWebApp && modalName === 'delegate' ? {
                display: 'flex',
              } : {
                margin: bodyMargin,
              }}
            >
              <Box height={sessionStorage.getItem('isWebApp') ? 'unset' : '80%'} minHeight={200} flex={1}>
                <Box mb={5} textAlign="center">
                  <Typography variant="h2">{heading}</Typography>
                </Box>
                {body}
              </Box>
              <Box
                height="20%"
                display={buttonWidth ? 'flex' : 'unset'}
                flexDirection={buttonWidth ? 'column' : 'unset'}
                alignItems={buttonWidth ? 'center' : 'unset'}
                style={isWebApp && modalName === 'delegate' ? {
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  alignItems: 'center',
                } : {}}
              >
                {saveLabel && (
                  <Box
                    width={buttonWidth || 'usnet'}
                    mt={5}
                    style={isWebApp && modalName === 'delegate' ? {
                      flex: 1,
                      marginLeft: 12,
                    } : {}}
                  >
                    <Button
                      priority="primary"
                      name="prospect-submit-modal-save"
                      id="prospect-submit-modal-save"
                      onClick={submitData}
                      loading={isSubmitting}
                    >
                      {saveLabel}
                    </Button>
                  </Box>
                )}
                {
                  cancelLabel && (
                    <Box
                      width={buttonWidth || 'usnet'}
                      mt={5}
                      style={isWebApp && modalName === 'delegate' ? {
                        flex: 1,
                      } : {}}
                    >
                      <Button
                        priority={cancelButtonPriority || 'tertiary'}
                        name="prospect-submit-modal-close"
                        id="prospect-submit-modal-close"
                        onClick={closeAndReset}
                      >
                        {cancelLabel}
                      </Button>
                    </Box>
                  )
                }
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </MuiModal>
  );
};

Fullpage.propTypes = {
  heading: string,
  title: string,
  titleAlign: string,
  buttonWidth: number,
  icon: string,
  bodyPadding: string,
  bodyMargin: string,
  body: object,
  closeModal: func,
  open: bool.isRequired,
  submitData: func.isRequired,
  saveLabel: string.isRequired,
  cancelLabel: string,
  cancelButtonPriority: string,
  isSubmitting: bool,
  backHome: func.isRequired,
  language: string,
  needReload: bool,
  hideToolbarShadow: bool,
  modalName: string,
  market: string,
};


export default Fullpage;
