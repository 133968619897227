import React from 'react';

export const infoIcon = () => (
  <svg className="banner-information-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd" transform="matrix(1 0 0 -1 -2 22)">
      <path d="M0 0h24v24H0z" />
      <circle cx="12" cy="12" r="10" fill="#FFF" />
      <path fill="#181818" fillRule="nonzero" d="M12 15a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-8a1 1 0 0 1 .993.883L13 8v5a1 1 0 0 1-1.993.117L11 13V8a1 1 0 0 1 1-1z" />
    </g>
  </svg>
);

export const lockIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12">
    <path fill="#FFF" fillRule="nonzero" d="M5 0a3 3 0 0 1 2.995 2.824L8 3v1a2 2 0 0 1 1.995 1.85L10 6v4a2 2 0 0 1-1.85 1.995L8 12H2a2 2 0 0 1-1.995-1.85L0 10V6a2 2 0 0 1 1.85-1.995L2 4V3a3 3 0 0 1 3-3zm0 2a1 1 0 0 0-.993.883L4 3v1h2V3a1 1 0 0 0-1-1z" />
  </svg>
);

export const confirmIcon = () => (
  <svg className="banner-confirmation-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd" transform="translate(-2 -2)">
      <path d="M0 0h24v24H0z" />
      <circle cx="12" cy="12" r="10" fill="#FFF" />
      <path fill="#1A8282" fillRule="nonzero" d="M16.293 8.293a1 1 0 0 1 1.497 1.32l-.083.094-6 6a1 1 0 0 1-1.32.083l-.094-.083-3-3a1 1 0 0 1 1.32-1.497l.094.083L11 13.585l5.293-5.292z" />
    </g>
  </svg>
);

export const warningIcon = () => (
  <svg className="banner-warning-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd" transform="translate(-2 -2)">
      <path d="M0 0h24v24H0z" />
      <circle cx="12" cy="12" r="10" fill="#FFF" />
      <path fill="#E52828" fillRule="nonzero" d="M12 15a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-8a1 1 0 0 1 .993.883L13 8v5a1 1 0 0 1-1.993.117L11 13V8a1 1 0 0 1 1-1z" />
    </g>
  </svg>
);

export const prospectSuccessIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#1A8282" />
    <path d="M21.2929 11.2929C21.6834 10.9024 22.3166 10.9024 22.7071 11.2929C23.0676 11.6534 23.0953 12.2206 22.7903 12.6129L22.7071 12.7071L14.7071 20.7071C14.3466 21.0676 13.7794 21.0953 13.3871 20.7903L13.2929 20.7071L9.29289 16.7071C8.90237 16.3166 8.90237 15.6834 9.29289 15.2929C9.65338 14.9324 10.2206 14.9047 10.6129 15.2097L10.7071 15.2929L14 18.585L21.2929 11.2929Z" fill="white" />
  </svg>
);

export const errorIcon = () => (
  <svg className="banner-error-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <path d="M-2-2h24v24H-2z" />
      <path fill="#FFF" fillRule="nonzero" d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0z" />
      <path fill="#851168" fillRule="nonzero" d="M7.784 6.381l.095.083 2.12 2.12 2.122-2.12a1 1 0 0 1 1.498 1.32l-.083.095-2.122 2.12 2.122 2.122a1 1 0 0 1-1.32 1.498l-.095-.083L10 11.414l-2.121 2.122a1 1 0 0 1-1.498-1.32l.083-.095L8.584 10l-2.12-2.121a1 1 0 0 1 1.32-1.498z" />
    </g>
  </svg>
);
