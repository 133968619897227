window.dataLayer = window.dataLayer /* istanbul ignore next */
|| [];
// console.log('dataLayer', window.dataLayer);

export const gaPageView = ({ eventName, options }) => {
  window.dataLayer.push({
    eventType: 'pageview',
    event: eventName,
    payload: options,
  });
  // console.log('sending GA pageview', eventName /* window.dataLayer */);
};

export const gaFunnelStep = (step) => {
  window.dataLayer.push({
    event: 'checkout',
    ecommerce: {
      checkout: {
        // step and option are key data points here
        actionField: { step },
        products: [{ // product details irrelevant
          name: '',
          id: '',
          price: '',
          brand: '',
          category: '',
          variant: '',
          quantity: '',
        }],
      },
    },
  });
  // console.log('window.dataLayer's, window.dataLayer);
};
