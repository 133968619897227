import React, {
  useState, useEffect, useCallback, useImperativeHandle, forwardRef,
} from 'react';
import ReactCrop from 'react-image-crop';
import {
  string, func, bool, exact,
} from 'prop-types';
import 'react-image-crop/dist/ReactCrop.css';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Slider from '@material-ui/core/Slider';
import Grid from '@material-ui/core/Grid';
import loadImage from 'blueimp-load-image';
import {
  Typography,
} from '@avon/component-library';
import { AddRounded, RemoveRounded } from '@material-ui/icons';
import { isMobile } from '../../../utils/getDeviceType';
// import cameraIcon from '../AvatarHeader/assets/camera.svg';
// TODO
// 1. Change colour of shadow when slider being used (currently pink)
// 3. Sort out button positioning and sizing for longer text

export const getCrop = (zoom, image, crop, type) => {
  const width = (zoom * Math.min(image.height, image.width)) / 100;
  const height = (zoom * Math.min(image.height, image.width)) / 100;
  let tempCrop = {
    width,
    height,
    unit: 'px',
    aspect: 1,
  };
  if (type) {
    tempCrop = Object.assign(tempCrop, {
      x: (image.width - width) / 2,
      y: (image.height - height) / 2,
    });
  } else {
    tempCrop = Object.assign(tempCrop, {
      x: crop.x + width > image.width ? image.width - width : crop.x,
      y: crop.y + height > image.height ? image.height - height : crop.y,
    });
  }
  return tempCrop;
};

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.colors.lightZircon,
    boxShadow: 'none',
  },
  toolbar: { justifyContent: 'space-around' },
  toolbarButtons: {
    padding: theme.spacing(3),
  },
  dialogPaper: {
    background: '#181818',
    touchAction: 'none',
  },
  canvas: e => (e.isMobile() ? {
    display: 'flex',
    flexDirection: 'column',
    padding: 'unset',
    paddingTop: '0!important',
    height: '100%',
  } : {
    display: 'flex',
    flexDirection: 'column',
  }),
  cropContainer: {
    display: 'flex',
    flex: '1 1 auto',
    // height: '100%',
    // width: '100%',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
  },
  progressContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progressRotating: {
    animation: '$progressRotate 1s linear infinite',
    '-webkit-animation': '$progressRotate 0.5s linear infinite',
  },
  '@keyframes progressRotate': {
    '0%': {
      transform: 'rotate(0deg)',
      '-webkit-transform': 'rotate(0deg)',
    },
    '25%': {
      transform: 'rotate(90deg)',
      '-webkit-transform': 'rotate(90deg)',
    },
    '50%': {
      transform: 'rotate(180deg)',
      '-webkit-transform': 'rotate(180deg)',
    },
    '75%': {
      transform: 'rotate(270deg)',
      '-webkit-transform': 'rotate(270deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
      '-webkit-transform': 'rotate(360deg)',
    },
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  imageSelector: {
    height: '100%',
    margin: 'auto',
    '& > div:not(.ReactCrop__crop-selection)': {
      height: '100%',
    },
    '& > .ReactCrop__crop-selection': {
      border: '3px white solid',
      // left: '50%!important',
      // top: '50%!important',
      // transform: 'translate(-50%, -50%)',
      '& > .ReactCrop__rule-of-thirds-hz': {
        '&:before, &:after': {
          width: '95%',
          margin: '0 2.5%',
        },
      },
      '& > .ReactCrop__rule-of-thirds-vt': {
        '&:before, &:after': {
          height: '95%',
          margin: '2.5% 0',
        },
      },
    },
  },
  sliderTrack: {
    background: theme.palette.colors.textLight,
  },
  sliderThumb: {
    background: 'white',
  },
  rotating: {
    animation: '$rotate 1s linear infinite',
    '-webkit-animation': '$rotate 1s linear infinite',
    'transform-origin': '8px 11px',
    '-webkit-transform-origin': '8px 11px',
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)',
      '-webkit-transform': 'rotate(0deg)',
    },
    '25%': {
      transform: 'rotate(-90deg)',
      '-webkit-transform': 'rotate(-90deg)',
    },
    '50%': {
      transform: 'rotate(-180deg)',
      '-webkit-transform': 'rotate(-180deg)',
    },
    '75%': {
      transform: 'rotate(-270deg)',
      '-webkit-transform': 'rotate(-270deg)',
    },
    '100%': {
      transform: 'rotate(-360deg)',
      '-webkit-transform': 'rotate(-360deg)',
    },
  },
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ImageEditor = ({
  editorImage, open, closeModal, onComplete, labels, setEditorImage,
  // videoRef, handleGenerateAvatar, canvasRef,
}, ref) => {
  // const isWebApp = sessionStorage.getItem('isWebApp') && !isMobile();
  const classes = useStyles({ isMobile });
  const [imgRef, setImgRef] = useState();
  const [crop, setCrop] = useState({ unit: '%', width: 100, aspect: 1 });
  const [zoom, setZoom] = useState(100);
  const [rotating, setRotating] = useState(false);
  const [controlDims, setControlDims] = useState({ width: '', height: '' });
  const [canvasDims, setCanvasDims] = useState({ width: '', height: '' });
  const ActWidth = window.innerWidth - 10;
  // eslint-disable-next-line
  const ActHeight = parseInt(ActWidth * 0.73, 0);


  const getCroppedImg = (image) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX, crop.y * scaleY,
      crop.width * scaleX, crop.height * scaleY,
      0, 0,
      crop.width, crop.height,
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        const res = blob;
        res.name = 'testName';
        resolve(res);
      }, 'image/jpeg', 1);
    });
  };

  const onImageLoaded = (image) => {
    setRotating(false);
    setImgRef(image);
  };

  const getBoundingHeight = useCallback((node) => {
    if (node) {
      const { height, width } = node.getBoundingClientRect();
      if (isMobile()) {
        setControlDims({
          height: ActHeight,
          width: ActWidth,
        });
      } else if (controlDims.height !== height || controlDims.width !== width) {
        setControlDims({ height, width });
      }
    }
  }, []);

  const getCanvasHeight = useCallback((node) => {
    if (node) {
      const { height, width } = node.getBoundingClientRect();
      if (isMobile()) {
        setCanvasDims({
          height: ActHeight,
          width: ActWidth,
        });
      } else if (canvasDims.height !== height || canvasDims.width !== width) {
        setCanvasDims({ height, width });
      }
    }
  }, []);

  const handleConfirm = () => {
    if (imgRef) {
      getCroppedImg(imgRef).then(onComplete).then(closeModal);
      setImgRef();
      setEditorImage();
    }
  };

  const handleCancel = () => {
    setImgRef();
    setEditorImage();
    closeModal();
  };

  useImperativeHandle(ref, () => ({
    handleConfirm: () => {
      if (imgRef) {
        getCroppedImg(imgRef).then(onComplete).then(closeModal);
      }
    },
  }));

  const rotate = () => {
    setRotating(true);
    loadImage(editorImage, (img) => {
      setEditorImage(img.toDataURL());
    }, { orientation: 8 });
  };

  const handleZoomChange = (event, value) => {
    setTimeout(() => setCrop(getCrop(value, imgRef, crop, 1)), 0);
    setZoom(value);
  };

  const handleZoomChangeWeb = (type) => {
    let zoomCopy = zoom;
    if (type === 'plus') {
      if (zoomCopy >= 100) {
        return;
      }
      zoomCopy += 10;
    } else {
      if (zoomCopy <= 10) {
        return;
      }
      zoomCopy -= 10;
    }
    setTimeout(() => setCrop(getCrop(zoomCopy, imgRef, crop, 1)), 0);
    setZoom(zoomCopy);
  };

  useEffect(() => {
    // setCrop({ unit: '%', aspect: 1, width: 100 });
    if (imgRef) {
      let { height, width } = imgRef;
      if (height > width) {
        width -= 10;
        setCrop({
          unit: 'px', aspect: 1, width, x: 5, y: (height - width) / 2,
        });
      } else {
        height -= 10;
        setCrop({
          unit: 'px', aspect: 1, height, x: (width - height) / 2, y: 5,
        });
      }
      setZoom(100);
    }
  }, [editorImage, imgRef]);

  const TransitionProp = window.navigator.userAgent.match(/iPad/i) ? {} : { TransitionComponent: Transition };

  return (
    <>
      {
        !isMobile() ? (
          <>
            {editorImage && (
              <DialogContent className={classes.canvas}>
                <Box className={classes.cropContainer} height="100%" ref={getCanvasHeight} position="relative">
                  <Box className={classes.imageContainer}>
                    <ReactCrop
                      id="prpman-edito-crop"
                      circularCrop
                      src={editorImage}
                      crop={crop}
                      onChange={(newCrop) => {
                        setCrop(newCrop);
                      }}
                      imageStyle={{ maxHeight: '469px' }}
                      ruleOfThirds
                      onImageLoaded={onImageLoaded}
                      className={classes.imageSelector}
                      imageAlt="editor-image"
                      locked
                    />
                  </Box>
                  <Box style={{
                    position: 'absolute',
                    top: 'calc(50% - 37px)',
                    right: 12,
                  }}
                  >
                    <Box
                      style={{
                        width: 32,
                        height: 32,
                        borderRadius: '50%',
                        background: '#ffffff',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: 10,
                        cursor: 'pointer',
                      }}
                      onClick={() => handleZoomChangeWeb('plus')}
                    >
                      <AddRounded />
                    </Box>
                    <Box
                      style={{
                        width: 32,
                        height: 32,
                        borderRadius: '50%',
                        background: '#ffffff',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleZoomChangeWeb('reduce')}
                    >
                      <RemoveRounded />
                    </Box>
                  </Box>
                </Box>
              </DialogContent>
            )}
          </>
        ) : (
          <Dialog
            open={open}
            fullScreen
            aria-labelledby="profile-image-upload"
            classes={{
              paper: classes.dialogPaper,
            }}
            data-testid="image-upload-modal"
            id="prpman-image-upload-modal"
            {...TransitionProp}
          >
            <AppBar className={classes.appBar}>
              <Toolbar className={classes.toolbar}>
                <Typography
                  className={classes.toolbarButtons}
                  variant="body2"
                  weight="bold"
                  color="primary"
                  onClick={() => handleConfirm()}
                >
                  {labels.save}
                </Typography>
                <Typography
                  className={classes.toolbarButtons}
                  variant="body2"
                  weight="bold"
                  color="textPrimary"
                  onClick={() => handleCancel()}
                >
                  {labels.cancel}
                </Typography>
              </Toolbar>
            </AppBar>
            {
              editorImage ? (
                <DialogContent className={classes.canvas}>
                  <Box className={classes.cropContainer} ref={getCanvasHeight}>
                    <Box className={classes.imageContainer}>
                      <ReactCrop
                        id="prpman-edito-crop"
                        circularCrop
                        src={editorImage}
                        crop={crop}
                        onChange={(newCrop) => {
                          setCrop(newCrop);
                        }}
                        imageStyle={{ maxHeight: `${window.innerHeight - 160}px` }}
                        ruleOfThirds
                        onImageLoaded={onImageLoaded}
                        className={classes.imageSelector}
                        imageAlt="editor-image"
                        locked
                      />
                    </Box>
                    <Box py={4} ref={getBoundingHeight}>
                      <Box pb={4}>
                        <Typography variant="body1" weight="regular" color="secondary">{labels.zoom}</Typography>
                      </Box>
                      <Grid container>
                        <Grid item xs={10} style={{ paddingLeft: '10px' }}>
                          <Slider id="editor-zoom-slider" data-testid="editor-zoom-slider" classes={{ track: classes.sliderTrack, thumb: classes.sliderThumb, activated: classes.sliderActivated }} value={zoom} onChange={handleZoomChange} min={50} max={100} aria-labelledby="continuous-slider" />
                        </Grid>
                        <Grid item xs={2}>
                          <Box style={{ textAlign: 'center', margin: 10 }} onClick={rotate}>
                            <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg" className={rotating ? classes.rotating : undefined}>
                              <path fillRule="evenodd" clipRule="evenodd" d="M9.00001 0V3.07C12.95 3.56 16 6.92 16 11C16 15.08 12.95 18.44 9.00001 18.93V16.91C11.84 16.43 14 13.97 14 11C14 8.03 11.84 5.57 9.00001 5.09V9L4.45001 4.55L9.00001 0ZM1.70001 6.11L3.11001 7.53C2.58001 8.28 2.23001 9.13 2.09001 10H0.0700073C0.240007 8.61 0.800007 7.27 1.70001 6.11ZM0.0700073 12H2.09001C2.23001 12.88 2.58001 13.72 3.10001 14.47L1.69001 15.89C0.790007 14.73 0.240007 13.39 0.0700073 12ZM3.10001 17.32C4.26001 18.22 5.61001 18.76 7.00001 18.93V16.9C6.13001 16.75 5.29001 16.41 4.54001 15.87L3.10001 17.32Z" fill="rgba(255,255,255,0.8)" />
                            </svg>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </DialogContent>
              )
                : null
            }

            {/* <Box className={classes.progressContainer}>
              <video
                ref={videoRef}
                id="video"
                width={`${ActWidth}px`}
                height={`${ActHeight}px`}
                playsInline
                webkit-playsinline="true"
                autoPlay
              />
              <canvas
                ref={canvasRef}
                id="canvas-mobile"
                width={`${ActWidth}px`}
                height={`${ActHeight}px`}
                style={{ display: 'none' }}
              />
              <Box
                style={{
                  position: 'absolute',
                  bottom: 22,
                  left: 'calc(50% - 38px)',
                  width: 76,
                  height: 76,
                  borderRadius: '50%',
                  background: '#7F28C4',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={handleGenerateAvatar}
              >
                <img style={{ width: 24, height: 24 }} src={cameraIcon} alt="camera-icon" />
              </Box>
            </Box> */}
          </Dialog>
        )
      }
    </>
  );
};


ImageEditor.propTypes = {
  editorImage: string.isRequired,
  open: bool.isRequired,
  closeModal: func.isRequired,
  onComplete: func.isRequired,
  setEditorImage: func.isRequired,
  labels: exact({
    zoom: string,
    title: string,
    cancel: string,
    save: string,
  }).isRequired,
  // handleGenerateAvatar: func,
  // videoRef: object,
  // canvasRef: object,
};

export default forwardRef(ImageEditor);
