import React, { useState, useEffect } from 'react';
import {
  func, exact, string, bool, number, oneOfType, arrayOf,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { DatePicker } from '@avon/component-library';
import isMobile from '../../../utils/isMobile/index';

const DateRange = ({
  onChange, dateRange, error = [], limitMax, limitMin, language, labels, disableFuture, market,
}) => {
  const [internalRange, setInternalRange] = useState({
    from: new Date().getTime(),
    to: new Date().getTime(),
  });

  const localeTemp = {
    MA: 'arMA',
    EG: 'arEG',
  };

  const dateOptions = {
    disabled: false,
    required: false,
    format: 'dd/MM/yyyy',
    locale: language === 'ar' ? localeTemp[market] : language,
    placeholder: 'dd/mm/yyyy',
  };

  let timer = null;

  if (disableFuture) {
    dateOptions.disableFuture = true;
  }

  const activeRange = dateRange || internalRange;
  const handleChange = newRange => (
    onChange ? onChange(newRange) : setInternalRange(newRange)
  );

  const updateFrom = (updatedFrom) => {
    if (updatedFrom && updatedFrom !== 'Invalid Date') {
      updatedFrom.setHours(0);
      handleChange({ ...activeRange, from: updatedFrom.getTime() });
    }
  };

  const updateTo = (updatedTo) => {
    if (updatedTo && updatedTo !== 'Invalid Date') {
      updatedTo.setHours(23, 59, 59);
      handleChange({ ...activeRange, to: updatedTo.getTime() });
    }
  };

  const handleFocus = () => {
    const $dom = document.querySelector('.MuiDialog-paperScrollPaper');
    timer = setTimeout(() => {
      $dom.scrollTop = $dom.clientHeight;
    }, 400);
  };

  useEffect(() => () => {
    clearTimeout(timer);
  });


  return (
    <Grid container spacing={6} style={{ overflow: 'hidden', paddingBottom: isMobile() ? '74px' : 'inherit' }}>
      <Grid item xs={12} md={6}>
        <DatePicker cancelLabel={labels.cancel} okLabel={labels.confirm} {...dateOptions} {...(limitMax ? { maxDate: new Date(activeRange.to) } : {})} label={labels.from} id="Date From" name="Date From" onChange={updateFrom} value={new Date(activeRange.from)} error={error[0]} onFocus={handleFocus} />
      </Grid>
      <Grid item xs={12} md={6}>
        <DatePicker cancelLabel={labels.cancel} okLabel={labels.confirm} {...dateOptions} {...(limitMin ? { minDate: new Date(activeRange.from) } : {})} label={labels.to} id="Date To" name="Date To" onChange={updateTo} value={new Date(activeRange.to)} error={error[1]} onFocus={handleFocus} />
      </Grid>
    </Grid>
  );
};

DateRange.propTypes = {
  onChange: func,
  dateRange: oneOfType(
    [exact({
      from: string,
      to: string,
    }),
    exact({
      from: number,
      to: number,
    })],
  ),
  labels: exact({
    from: string,
    to: string,
    cancel: string,
    confirm: string,
  }).isRequired,
  language: string.isRequired,
  error: arrayOf(string),
  limitMax: bool,
  limitMin: bool,
  disableFuture: bool,
  market: string,
};

export default DateRange;
