const setHasNextPage = (state, action) => {
  switch (action.hasNextPage) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return state;
  }
};

export default setHasNextPage;
