import React from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { Typography } from '@avon/component-library';
import {
  array, string, bool,
} from 'prop-types';


export const CustomTooltip = ({
  active, payload, label, language,
}) => {
  const tipPayload = payload[0] && payload[0].payload.tooltip;
  let tip = '';
  if (tipPayload) {
    // hardcode hu date format, for all markets need backend adpater
    if (language === 'hu') {
      const tipSplit = tipPayload.split(' ');
      if (tipSplit.length === 3) {
        tip = tipSplit.slice(2).concat(`${tipSplit[1]}.`).concat(tipSplit[0])
          .join(' ');
      } else if (tipSplit.length === 7) {
        tip = tipSplit.slice(2, 3).concat(`${tipSplit[1]}.`).concat(tipSplit[0]).concat(tipSplit[3])
          .concat(tipSplit[6])
          .concat(`${tipSplit[5]}.`)
          .concat(tipSplit[4])
          .join(' ');
      } else {
        tip = tipPayload;
      }
    } else {
      tip = tipPayload;
    }
  }
  if (active) {
    return (
      <Card
        data-testid="tooltip-container"
        style={{
          border: 'solid 1px #ececec',
          maxWidth: '400px',
          minWidth: '250px',
          backgroundColor: 'rgba(255,255,255,0.97)',
          direction: language === 'ar' ? 'rtl' : 'ltr',
        }}
      >
        <Box p={5}>
          <Typography variant="body1" weight="bold">
            {tipPayload ? tip : label}
          </Typography>
          {
            payload.map(({ name, value }) => (
              <Box data-testid="legend-item" width="100%" key={name} display="flex" alignItems="center" justifyContent="space-between" mt={3}>
                <Box
                  pl={language === 'ar' ? 4 : 0}
                  pr={language === 'ar' ? 0 : 4}
                >
                  <Typography style={{ wordBreak: 'break-word' }} variant="body1">{`${name}:`}</Typography>
                </Box>
                <Typography variant="body1" color="primary">{`${value}`}</Typography>
              </Box>
            ))
          }
        </Box>
      </Card>
    );
  }

  return null;
};


const tooltipProps = () => ({ content: CustomTooltip });

CustomTooltip.propTypes = {
  active: bool.isRequired,
  payload: array.isRequired,
  label: string.isRequired,
  language: string,
};

export default tooltipProps;
