// eslint-disable-next-line no-unused-vars
import {
  useState, useEffect,
} from 'react';

import { gaPageView, gaFunnelStep } from './AnalyticsEvents';

export const useTracking = () => {
  const [tracking, setTracking] = useState({});

  useEffect(
    () => {
      switch (tracking.eventType) {
        case 'pageview':
          // console.log('tracking pageview ', tracking.eventName, tracking.funnelStep);
          gaPageView(tracking);
          gaFunnelStep(tracking.funnelStep);
          break;
        default:
          break;
      }
    },
    [tracking],
  );
  // Return enabled state and setter for tracking
  return [tracking, setTracking];
};
