import React, { useContext, useRef, useState } from 'react';
import { object, func } from 'prop-types';
import { Button, Typography, useError } from '@avon/component-library';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import { Formik, Field } from 'formik';

import LmaContext from '../../LmaContext';
import { sendNotes } from '../../../utils/api';
import { InputField } from '../../../prpMan/components/Fields';
import { validateValues } from '../../../prpMan/utils/formValidation';
import { isMobile } from '../../../utils/getDeviceType';

const initialValues = { notesContent: '' };

const useStyles = makeStyles(() => ({
  input: {
    height: '22px',
    transition: 'height 0.5s',
    transitionTimingFunction: 'cubic-bezier(0.45,0,0,1)',
    '&:focus': {
      height: '100px',
    },
  },
}));
const AddNote = ({
  fields,
  populateNotes,
}) => {
  const isWebApp = sessionStorage.getItem('isWebApp') && !isMobile();
  const classes = useStyles();
  const inputRef = useRef({});
  const { setError, cleanToastBanner } = useError();
  const [isSubmitting, setSubmitting] = useState(false);
  const [{
    callApi, apptId, language,
  }] = useContext(LmaContext);
  const {
    notesContent: {
      notesHeading,
      notesSubheading,
      notesPlaceholderText,
      notesButtonLabel,
      cancelButtonLabel,
      notesMaxChar,
    },
  } = fields;

  const clearInput = () => {
    inputRef.current.value = '';
  };

  const sendNote = async ({ notesContent }) => {
    try {
      setSubmitting(true);
      await callApi(sendNotes({ apptId, notesContent }));
      clearInput();
      populateNotes();
      cleanToastBanner();
    } catch ({ error }) {
      error.reCall = sendNote;
      error.args = [notesContent];
      setError(error);
    }
    setSubmitting(false);
  };

  return (
    <Box>
      <Box pb={2}>
        <Typography style={{ fontSize: 16, color: '#181818' }} weight="bold">
          {notesHeading}
        </Typography>
      </Box>
      <Box style={{ paddingBottom: isMobile() ? 30 : 0 }}>
        <Formik
          initialValues={initialValues}
          validate={values => validateValues(values, {
            validators: { notesContent: fields.notesContent.errors },
          })}
          onSubmit={async (values, { resetForm }) => {
            if (!isSubmitting) {
              await sendNote(values);
              resetForm();
            }
          }}
        >
          {({
            values, submitForm, resetForm,
          }) => (
            <form id="admin-lma-add-notes">
              <Field
                component={InputField}
                priority="secondary"
                name="notesContent"
                placeholder={notesPlaceholderText}
                label={notesSubheading}
                id="add-note-input"
                rows="5"
                inputProps={{ 'data-testid': 'add-note-input', id: 'add-note-input', className: classes.input }}
                inputRef={inputRef}
                maxLength={notesMaxChar}
                disabled={isSubmitting}
                language={language}
                multiline
                fullWidth
              />
              <Box
                py={5}
                style={isWebApp ? {
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  alignItems: 'center',
                } : {}}
              >
                <Box style={isWebApp ? {
                  width: 210,
                } : {}}
                >
                  <Button
                    onClick={() => {
                      submitForm(values);
                    }}
                    loading={isSubmitting}
                    type="submit"
                    priority="secondary"
                    name="add-note-button"
                    id="add-note-button"
                    disabled={values.notesContent.length === 0}
                  >
                    {notesButtonLabel}
                  </Button>
                </Box>
                <Box
                  pt={2}
                  style={isWebApp ? {
                    width: 210,
                    paddingTop: 0,
                    paddingRight: 12,
                  } : {}}
                >
                  <Button
                    onClick={() => {
                      resetForm();
                      clearInput();
                    }}
                    name="cancel-note-button"
                    id="cancel-note-button"
                    priority="tertiary"
                    disabled={values.notesContent.length === 0}
                    type="reset"
                  >
                    {cancelButtonLabel}
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

AddNote.propTypes = {
  fields: object,
  populateNotes: func,
};


export default AddNote;
