import React, { useCallback } from 'react';
import { Button } from '@avon/component-library';
import {
  object, func, string, shape, bool,
} from 'prop-types';
import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// import Avatar from '@material-ui/core/Avatar';
// import successIcon from '../../styles/assets/icons/success.svg';
// import warnIcon from '../../styles/assets/icons/warn.svg';
import { isMobile } from '../../../utils/getDeviceType';

const DialogBody = ({
  heading,
  description,
  submitButton,
  closeButton: { label: closeLabel, priority: closePriority },
  handleSubmit,
  closeModal,
  isSubmitting,
  icon,
  iconType,
}) => {
  const isWebApp = sessionStorage.getItem('isWebApp') && !isMobile();

  const onClick = useCallback(() => {
    if (!isSubmitting) handleSubmit();
  }, [isSubmitting, handleSubmit]);

  const icons = {
    success:
  <Box textAlign="center" mt={6}>
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0ZM32 2C15.4315 2 2 15.4315 2 32C2 48.5685 15.4315 62 32 62C48.5685 62 62 48.5685 62 32C62 15.4315 48.5685 2 32 2ZM43.6925 24.2786C44.0603 24.6317 44.0996 25.1982 43.8026 25.5966L43.7214 25.6925L30.2814 39.6925C29.9181 40.071 29.3315 40.1001 28.9338 39.7799L28.8386 39.6925L21.2786 31.8175C20.8961 31.4191 20.9091 30.7861 21.3075 30.4036C21.6752 30.0506 22.2429 30.0344 22.6289 30.3474L22.7214 30.4325L29.56 37.555L42.2786 24.3075C42.6611 23.9091 43.2941 23.8961 43.6925 24.2786Z" fill="#1A8282" />
    </svg>

  </Box>,
    warn:
  <Box textAlign="center" mt={6}>
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0ZM32 2C15.4315 2 2 15.4315 2 32C2 48.5685 15.4315 62 32 62C48.5685 62 62 48.5685 62 32C62 15.4315 48.5685 2 32 2ZM32 39C32.5523 39 33 39.4477 33 40C33 40.5523 32.5523 41 32 41C31.4477 41 31 40.5523 31 40C31 39.4477 31.4477 39 32 39ZM32 22C32.5128 22 32.9355 22.386 32.9933 22.8834L33 23V36C33 36.5523 32.5523 37 32 37C31.4872 37 31.0645 36.614 31.0067 36.1166L31 36V23C31 22.4477 31.4477 22 32 22Z" fill="#F09F87" />
    </svg>
  </Box>,
  };

  const iconColors = {
    success: '#1a8282',
    warn: '#fa6400',
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">
        {icon && icons[iconType]}
        <Box textAlign="center" mx={6} mt={3} mb={0} style={{ fontSize: 22, fontFamily: 'Montserrat-Light', color: icon ? iconColors[iconType] : '#181818' }}>
          {heading}
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box textAlign="center">{description}</Box>
        </DialogContentText>
      </DialogContent>
      <Box style={isWebApp ? {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: submitButton ? 'space-between' : 'center',
        alignItems: 'center',
        padding: 20,
      } : {}}
      >
        {submitButton && (
          <DialogActions style={isWebApp ? { flex: 1 } : {}}>
            <Button name="lma-dialog-continue" id="lma-dialog-continue" onClick={onClick} loading={isSubmitting} priority={submitButton.priority} fullwidth>
              {submitButton.label}
            </Button>
          </DialogActions>
        )}
        <DialogActions style={isWebApp ? {
          flex: submitButton ? 1 : 'unset',
          width: submitButton ? 'unset' : 220,
        } : {}}
        >
          <Button name="lma-dialog-close-modal" id="lma-dialog-close-modal" onClick={closeModal} priority={closePriority} fullwidth>
            {closeLabel}
          </Button>
        </DialogActions>
      </Box>
    </>
  );
};

DialogBody.propTypes = {
  heading: string,
  description: string,
  submitButton: object,
  closeButton: shape({
    closeLabel: string,
    priority: string,
  }),
  handleSubmit: func,
  closeModal: func,
  isSubmitting: bool,
  icon: bool,
  iconType: string,
};

export default DialogBody;
