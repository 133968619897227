
// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';
import TagManager from '@avon/react-gtm-module';
import { getStringItem } from '../utils/session-storage';

const className = window._env_.REACT_APP_GTM_CLASSNAME || '';
const path = window.location.pathname;
const getSource = () => {
  switch (path) {
    case '/admin/lma':
      return 'lma';
    case '/admin/prpman':
      return 'prpman';
    case '/admin/prospect':
      return 'prospect';
    case '/admin/stats':
      return 'stats';
    case '/admin/pma':
      return 'pma';
    default:
      return '';
  }
};

const GoogleTagManager = ({
  apptId, market, gtmId, language, channel, device, domain,
}) => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId,
      className,
      dataLayer: {
        user_id: getStringItem('user_id'),
        user_category: getSource(),
        // TODO: track logins where applicable
        login_status: getStringItem('login_status') || 'false', // "Logged in" Logged out
        apptId,
        market,
        language,
        channel,
        device,
        domain,
      },
    };
    TagManager.initialize(tagManagerArgs);
  }, [apptId, market, gtmId, language, channel, device, domain]);

  return null;
};

export default GoogleTagManager;
