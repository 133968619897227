import React from 'react';
import {
  ResponsiveContainer, BarChart, Bar, Legend, XAxis, YAxis, Tooltip, CartesianGrid,
} from 'recharts';
import {
  arrayOf, object, number, shape, string, bool, array,
} from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  legendProps, tooltipProps, xAxisProps, useYAxisProps, gridProps,
} from './utils';

const useStyles = makeStyles(() => ({
  bars: {
    '@media (max-width: 360px)': {
      height: '310px',
    },
  },
  warp: {
    direction: 'ltr',
    '& > svg': {
      direction: styleProps => (styleProps.language === 'ar' ? 'rtl' : 'ltr'),
    },
    '& div > ul > li': {
      marginLeft: styleProps => (styleProps.language === 'ar' ? '10px' : '0px !important'),
      marginRight: styleProps => (styleProps.language === 'ar' ? '0px !important' : '10px'),
    },
    '& div > ul > li > svg': {
      marginLeft: styleProps => (styleProps.language === 'ar' ? '4px' : '0px !important'),
      marginRight: styleProps => (styleProps.language === 'ar' ? '0px !important' : '4px'),
    },
    '& [class*="recharts-tooltip-wrapper"]': {
      '@media (max-width: 450px)': {
        left: styleProps => (styleProps.language === 'fi' ? '-50px' : '-30px'),
      },
      '@media (max-width: 420px)': {
        left: styleProps => (styleProps.language === 'fi' ? '-80px' : '-30px'),
      },
      '@media (max-width: 375px)': {
        maxWidth: styleProps => (styleProps.language === 'fi' ? '280px' : 'inherit'),
        left: styleProps => (styleProps.language === 'fi' ? '-90px' : '-50px'),
      },
      '@media (max-width: 345px)': {
        maxWidth: styleProps => (styleProps.language === 'fi' ? '260px' : 'inherit'),
        left: styleProps => (styleProps.language === 'fi' ? '-100px' : '-70px'),
      },
      '@media (max-width: 320px)': {
        maxWidth: styleProps => (styleProps.language === 'fi' ? '260px' : 'inherit'),
        left: styleProps => (styleProps.language === 'fi' ? '-108px' : '-80px'),
      },
    },
  },
}));

const Chart = ({
  data, dataKeys, height, margin, stack, colors, loading,
  animationDuration, activeAnimation = true, language,
}) => {
  const classes = useStyles({ language });
  const tipProps = tooltipProps();
  tipProps.language = language;
  const bars = dataKeys.map((key, index) => (
    <Bar
      isAnimationActive={activeAnimation}
      animationDuration={animationDuration}
      key={index}
      dataKey={key}
      stackId={stack ? 0 : index}
      fill={colors[index]}
      className={classes.bars}
    />
  ));

  return (
    <ResponsiveContainer height={Number(height)} width="100%">
      <BarChart
        layout="vertical"
        data={data}
        margin={margin}
        className={classes.warp}
      >
        {!loading && <CartesianGrid {...gridProps()} />}
        {!loading && <Legend {...legendProps(language)} />}
        {!loading && <Tooltip {...tipProps} />}
        <XAxis {...xAxisProps('vertical', language)} hide={loading} domain={loading ? [0, 10] : [0, 'auto']} />
        <YAxis {...useYAxisProps('vertical', language)} hide={loading} />
        {bars}
      </BarChart>
    </ResponsiveContainer>
  );
};

Chart.propTypes = {
  data: arrayOf(object),
  dataKeys: arrayOf(string),
  height: number,
  margin: shape({
    top: number, right: number, left: number, bottom: number,
  }),
  stack: bool,
  colors: array.isRequired,
  animationDuration: number,
  loading: bool,
  activeAnimation: bool,
  language: string,
};

Chart.defaultProps = {
  margin: {
    top: 40, right: 10, left: 10, bottom: 5,
  },
  loading: false,
  animationDuration: 500,
};

export default Chart;
