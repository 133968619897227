import React, { useEffect, useContext, useState } from 'react';
import {
  string, object, bool,
} from 'prop-types';
import Box from '@material-ui/core/Box';
import { AvonContainer, Alert, useError } from '@avon/component-library';
import makeStyles from '@material-ui/styles/makeStyles';
import PullToRefresh from 'pulltorefreshjs';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactDOMServer from 'react-dom/server';
import LmaContext from '../../LmaContext';
import { useTracking } from '../../../analytics/Analytics';
import { getPrpManData } from '../../../utils/api';
import { getStringItem } from '../../../utils/session-storage';
import { accessTokenKey } from '../../../utils/config';
import FileterBar from '../FileterAndSortBar';
import { isMobile } from '../../../utils/getDeviceType';

const useStyles = makeStyles(() => ({
  container: e => (e.isWebApp ? {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    background: '#ffffff',
    overflow: 'hidden',
  } : {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  alertContainer: {
    '& > div': {
      display: 'unset',
    },
  },
}));

const NoLeads = ({
  heading, subheading, shareButton, createButton, noLeadsIcon,
  noProfileLink, noProfileNeedToken, disableProfileLink, disableProfileNeedToken,
}) => {
  const isWebApp = sessionStorage.getItem('isWebApp') && !isMobile();
  const classes = useStyles({ isWebApp });
  const { setError } = useError();
  const [profile, setProfile] = useState();
  const progressProps = {
    size: '2em',
    color: 'grey',
  };
  const getAccessToken = () => getStringItem(accessTokenKey) || window._env_.REACT_APP_ACCESS_TOKEN;

  const navigate = (profile1) => {
    if ((profile1 && profile1.vanityName === '') || (profile1 && profile1.vanityName && profile1.status)) {
      window.location.href = noProfileNeedToken ? `${noProfileLink}?accessToken=${getAccessToken()}` : `${noProfileLink}`;
    } else {
      window.location.href = disableProfileNeedToken ? `${disableProfileLink}&accessToken=${getAccessToken()}` : `${disableProfileLink}`;
    }
  };

  const [{ callApi }] = useContext(LmaContext);
  // Analytics
  const [, setTracking] = useTracking();
  useEffect(() => {
    setTracking({ eventType: 'pageview', eventName: 'lmaViewAllLeads' });
    // eslint-disable-next-line

    const fetchLeadContent = async () => {
      try {
        const response = await callApi(getPrpManData());
        setProfile(response);
      } catch ({ error }) {
        setError(error);
      }
    };
    fetchLeadContent();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    PullToRefresh.destroyAll();
    PullToRefresh.init({
      mainElement: '#root',
      triggerElement: '#root',
      instructionsPullToRefresh: ' ',
      instructionsReleaseToRefresh: ' ',
      instructionsRefreshing: ' ',
      onRefresh: () => {
        window.location.reload();
      },
      iconArrow: ReactDOMServer.renderToString(<CircularProgress {...progressProps} />),
      iconRefreshing: ReactDOMServer.renderToString(<CircularProgress {...progressProps} />),
    });
  }, []);

  return (
    <Box bgcolor="colors.lightZircon" className={classes.container}>
      {
        isWebApp && (
          <FileterBar page="noLeads" />
        )
      }
      <AvonContainer>
        <Box className={classes.alertContainer}>
          <Alert
            id="lma-no-lead-Alert"
            heading={heading}
            body={subheading}
            buttonLabel={profile && !!profile.vanityName ? shareButton.label : createButton.label}
            iconType={noLeadsIcon}
            handleClick={() => navigate(profile)}
          />
        </Box>
      </AvonContainer>
    </Box>
  );
};

NoLeads.propTypes = {
  heading: string.isRequired,
  subheading: string.isRequired,
  shareButton: object.isRequired,
  createButton: object.isRequired,
  noLeadsIcon: string,
  noProfileLink: string,
  noProfileNeedToken: bool,
  disableProfileLink: string,
  disableProfileNeedToken: bool,
};

export default NoLeads;
