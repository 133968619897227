import React, {
  useContext, useState, useCallback, useEffect, useMemo, Fragment, useReducer, useRef,
} from 'react';

import Box from '@material-ui/core/Box';
import { object, string } from 'prop-types';
import {
  Button, Typography, useError,
} from '@avon/component-library';
import useTheme from '@material-ui/styles/useTheme';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import { getNotes, resendEmail, resendSMS } from '../../../utils/api';
import DialogBody from '../DialogBody';
import LmaContext from '../../LmaContext';
import RejectModalBody from '../RejectModalBody';
import AddNote from '../AddNote';
import ViewNotes from '../ViewNotes';
import Fullpage from '../../../prpMan/components/Fullpage/Fullpage';
import { delegateAppt as delegateApi } from '../../../utils/api/endpoints';
import { isMobile } from '../../../utils/getDeviceType';
import mailIcon from '../../../pma/styles/assets/icons/mail.svg';
import phoneIcon from '../../../pma/styles/assets/icons/phone.svg';

const contentWrapper = children => (
  <Grid item xs={12}>
    <Box px={6} py={5} bgcolor="colors.white">
      {children}
    </Box>
  </Grid>
);

const ActionTab = ({
  heading,
  resendSmsButton: { label: resendSMSLabel, priority: smsPriority, showOn: smsShowOn },
  resendEmailButton: { label: resendEmailLabel, priority: emailPriority, showOn: emailShowOn },
  rejectButton: {
    label: rejectLabel, priority: rejectPriority, showOn: rejectShowOn, pendingOn: showPending,
  },
  delegateButton: {
    label: delegateLabel,
    priority: delegatePriority,
    showOn: delegateShowOn,
    slNrLabel,
    slNrPlaceholder,
    saveLabel: delegateSaveLabel,
    cancelLabel: delegateCancelLabel,
    delegateModalTitle,
    delegateSlNrLabel,
    delegateSuccessHeader,
    delegateSuccessBody,
    warningSaveLabel,
    delegateWarnHeader,
    delegateWarnBody,
    slNrRegex,
    slNrRegexErrMsg,
  },
  fields,
  emailLeftLabel,
  smsLeftLabel,
  status,
  subStus,
  resendSMSBySL: { attemptsLeft: attemptsLeftSMS, canRetry: canRetrySMS },
  resendVerifyEmail: { attemptsLeft: attemptsLeftEmail, canRetry: canRetryEmail },
  smsModal,
  emailModal,
  rejectModal,
  link,
  market,
  currentUserId,
  selectActionLabel1 = 'Select an action',
  selectActionLabel2 = 'Take an action over this lead',
}) => {
  const isWebApp = sessionStorage.getItem('isWebApp') && !isMobile();

  const [{
    callApi, apptId, apiBody,
  }, dispatch] = useContext(LmaContext);

  const theme = useTheme();

  const [notes, setNotes] = useState();

  const [currentModalType, setCurrentModalType] = useState('sms');
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  // delegate related
  const [delegatePageOpen, setDelegatePageOpen] = useState(false);
  const [showSlLabel, setShowSlLabel] = useState(false);
  // eslint-disable-next-line max-len
  const [slInputUnderlineColor, setSlInputUnderlineColor] = useState(theme.palette.colors.textLight);
  // eslint-disable-next-line max-len
  const [slLabelColor, setSlLabelColor] = useState(theme.palette.colors.corePink);
  const [delegateSuccessOpen, setDelegateSuccessOpen] = useState(false);
  const [delegateWarnOpen, setDelegateWarnOpen] = useState(false);
  const [slNr, setSlNr] = useState();
  const [slNrError, setSlNrError] = useState(false);
  const [slInputBgColor, setSlInputBgColor] = useState();
  const slInputRef = useRef();

  const errorIcon = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM10 5C10.5128 5 10.9355 5.38604 10.9933 5.88338L11 6V11C11 11.5523 10.5523 12 10 12C9.48716 12 9.06449 11.614 9.00673 11.1166L9 11V6C9 5.44772 9.44771 5 10 5ZM10 15C10.5523 15 11 14.5523 11 14C11 13.4477 10.5523 13 10 13C9.44771 13 9 13.4477 9 14C9 14.5523 9.44771 15 10 15Z" fill={theme.palette.colors.error} />
    </svg>
  );

  const initialArgs = {
    sms: {
      disabled: false,
      showOn: smsShowOn || ['awaiting_acceptance'],
      remaining: attemptsLeftSMS,
      updatePath: 'resendSMSBySL',
      action: () => callApi(resendSMS(apptId)),
    },
    email: {
      disabled: false,
      showOn: emailShowOn || ['awaiting_acceptance'],
      remaining: attemptsLeftEmail,
      updatePath: 'resendVerifyEmail',
      action: () => callApi(resendEmail(link)),
    },
    reject: {
      disabled: false,
      showOn: rejectShowOn || ['awaiting_acceptance', 'new', 'in_progress'],
      pendingOn: showPending,
    },
    delegate: {
      disabled: false,
      showOn: delegateShowOn || ['new', 'in_progress'],
    },
  };

  const reducer = (state, { type, remaining }) => {
    const newState = state;

    newState[type].disabled = true;
    newState[type].remaining = remaining;

    return newState;
  };

  const [buttonState, updateButtonState] = useReducer(reducer, initialArgs);


  const { setError, cleanToastBanner } = useError();

  const populateNotes = useCallback(async () => {
    const response = await callApi(getNotes(apptId));
    setNotes(response.notes);
  }, [apptId, callApi]);

  useEffect(() => {
    populateNotes();
  }, [populateNotes]);

  const openModal = (type) => {
    setCurrentModalType(type);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleRejectClose = () => {
    const {
      pagination: { pageSize },
    } = apiBody;

    dispatch({
      apiBody: {
        ...apiBody, pagination: { pageSize, pageIndex: 0 },
      },
      page: 'rep card',
      apptId: 0,
    });
  };

  const handleSubmit = async () => {
    try {
      const { action, updatePath } = buttonState[currentModalType];

      setSubmitting(true);

      const remaining = action ? await action().then(res => ({
        remaining: res[updatePath].attemptsLeft,
      })) : {};

      updateButtonState({ type: currentModalType, ...remaining });
      setConfirmationOpen(true);
      cleanToastBanner();
    } catch ({ error }) {
      error.reCall = handleSubmit;
      error.args = [];
      setError(error);
      closeModal();
    }
    setSubmitting(false);
  };

  const modalElement = useMemo(() => {
    const modals = {
      sms: smsModal,
      email: emailModal,
      reject: rejectModal,
    };

    const isReject = currentModalType === 'reject';

    const Element = (isReject && !confirmationOpen) ? RejectModalBody : DialogBody;
    const continueFunc = isReject ? handleRejectClose : closeModal;

    const props = confirmationOpen ? ({
      closeModal: continueFunc,
      ...modals[currentModalType].properties.secondModal.properties,
    }) : ({
      isSubmitting,
      handleSubmit,
      closeModal,
      ...modals[currentModalType].properties,
    });

    return (
      <Element
        {...props}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentModalType, confirmationOpen, isSubmitting]);

  const resendButtonProps = [
    {
      type: 'sms',
      canRetry: canRetrySMS,
      labels: {
        resend: resendSMSLabel,
        remLabel: smsLeftLabel,
      },
      icon: sessionStorage.getItem('isWebApp') ? <img alt="phone" src={phoneIcon} /> : null,
      priority: sessionStorage.getItem('isWebApp') ? 'secondary' : (smsPriority || 'primary'),
    },
    {
      type: 'email',
      canRetry: canRetryEmail,
      labels: {
        resend: resendEmailLabel,
        remLabel: emailLeftLabel,
      },
      icon: sessionStorage.getItem('isWebApp') ? <img alt="mail" src={mailIcon} /> : null,
      priority: sessionStorage.getItem('isWebApp') ? 'secondary' : (emailPriority || 'primary'),
    },
    {
      type: 'delegate',
      labels: {
        resend: delegateLabel || 'Delegate',
      },
      priority: delegatePriority || 'secondary',
    },
    {
      type: 'reject',
      labels: {
        resend: rejectLabel,
      },
      priority: rejectPriority || 'primary',
    },
  ];

  const resendButtons = resendButtonProps.reduce((previous, {
    type, canRetry = true, labels: { resend, remLabel }, priority, icon,
  }) => {
    const updated = previous;

    const {
      disabled, remaining, showOn, pendingOn,
    } = buttonState[type];

    if (showOn.includes(status) && resend) {
      if (status === 'pending' && pendingOn && !pendingOn.includes(subStus)) {
        return updated;
      }
      if (type !== 'delegate') {
        updated.push(
          <Fragment key={`lma-open-${type}-modal-button`}>
            <Box style={isWebApp ? {
              width: 210,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginLeft: 12,
              marginBottom: 12,
            } : {}}
            >
              <Button
                onClick={() => openModal(type)}
                priority={priority}
                name={`lma-open-${type}-modal`}
                id={`lma-open-${type}-modal`}
                disabled={disabled || !canRetry}
                startIcon={icon}
              >
                {resend}
              </Button>

              {!!remLabel && (
                <Box pt={2} pb={5}>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                  >
                    {`${remaining} ${remLabel}`}
                  </Typography>
                </Box>
              )}
            </Box>
          </Fragment>,
        );
      } else if (market === 'UK') {
        updated.push(
          <Fragment key={`lma-open-${type}-modal-button`}>
            <Box style={isWebApp ? {
              width: '210px',
              height: 58,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginLeft: 12,
              marginBottom: 12,
            } : { marginBottom: 12 }}
            >
              <Button
                onClick={() => setDelegatePageOpen(true)}
                priority={priority}
                name={`lma-open-${type}-modal`}
                id={`lma-open-${type}-modal`}
                disabled={disabled || !canRetry}
                startIcon={icon}
              >
                {resend}
              </Button>
            </Box>
          </Fragment>,
        );
      }
    }
    return updated;
  }, []);

  const delegateAppt = async () => {
    if (isSubmitting || slNrError) return;
    try {
      if (slInputRef.current.value === undefined || slInputRef.current.value.trim() === '') {
        setSlInputUnderlineColor(theme.palette.colors.error);
        setSlLabelColor(theme.palette.colors.error);
        setSlNrError(true);
        setSlInputBgColor(theme.palette.colors.errorBackground);
      } else {
        setSubmitting(true);
        await callApi(delegateApi(apptId, parseInt(slNr, 10)));
        setDelegateSuccessOpen(true);
      }
    } catch (ex) {
      setError(ex.error);
      if (ex.error.response.data.type === 'Fullpage') {
        setDelegateWarnOpen(true);
        setDelegatePageOpen(false);
      } else {
        setSlInputUnderlineColor(theme.palette.colors.error);
        setSlLabelColor(theme.palette.colors.error);
        setSlNrError(true);
        setSlInputBgColor(theme.palette.colors.errorBackground);
      }
    }
    setSubmitting(false);
  };

  const resetDelegatePage = () => {
    setSlNrError(false);
    setShowSlLabel(false);
    setSlInputUnderlineColor(theme.palette.colors.textLight);
    setSlLabelColor(theme.palette.colors.textLight);
    setSlInputBgColor(theme.palette.colors.white);
  };

  const closeDelegate = () => {
    setDelegatePageOpen(false);
    resetDelegatePage();
  };

  const onChange = (e) => {
    if ((e.target.value.length > 0
      && new RegExp(slNrRegex, 'i').test(e.target.value)
      && e.target.value !== currentUserId)) {
      // eslint-disable-next-line max-len
      setSlInputUnderlineColor(e.target.value.length > 0 ? theme.palette.colors.textLight : theme.palette.colors.corePink);
      // eslint-disable-next-line max-len
      setSlLabelColor(e.target.value.length > 0 ? theme.palette.colors.textLight : theme.palette.colors.corePink);
      setSlNr(e.target.value);
      setSlNrError(false);
      setSlInputBgColor(theme.palette.colors.white);
    } else {
      setSlInputUnderlineColor(theme.palette.colors.error);
      setSlLabelColor(theme.palette.colors.error);
      setSlNrError(true);
      setSlInputBgColor(theme.palette.colors.errorBackground);
    }
  };

  return (
    <>
      <Grid container spacing={3} id="actionTabContainer">
        {!!resendButtons.length && contentWrapper(
          <Box style={isWebApp ? {
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: 24,
            borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
          } : {}}
          >
            {!isWebApp ? (
              <Typography variant="h2" weight="light">
                {`${heading}:`}
              </Typography>
            ) : (
              <Box style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',

              }}
              >
                <Box style={{
                  fontFamily: 'Montserrat',
                  fontWeight: 700,
                  fontSize: 20,
                  lineHeight: '28px',
                  color: '#181818',
                  marginBottom: 6,
                }}
                >
                  {selectActionLabel1}
                </Box>
                <Box style={{
                  fontFamily: 'Montserrat',
                  fontSize: 16,
                  lineHeight: '24px',
                  color: '#000000',
                }}
                >
                  {selectActionLabel2}
                </Box>
              </Box>
            )}
            <Box
              py={5}
              style={isWebApp ? {
                width: '50%',
                display: 'flex',
                justifyContent: 'flex-end',
                flexWrap: 'wrap',
                padding: 0,
              } : {}}
            >
              {resendButtons}
            </Box>
          </Box>,
        )}

        {contentWrapper(<AddNote fields={fields} populateNotes={populateNotes} />)}

        {!!notes && contentWrapper(
          <ViewNotes dateFormat={fields.notesContent.dateFormat} notes={notes} />,
        )}

      </Grid>

      <Dialog
        open={modalOpen}
        onExited={() => setConfirmationOpen(false)}
        aria-labelledby="form-dialog-title"
        id="form-dialog-title-dialog"
      >
        <Box style={isWebApp ? {
          width: 568,
        } : {}}
        >
          {modalElement}
        </Box>
      </Dialog>

      <Fullpage
        saveLabel={delegateSaveLabel}
        cancelLabel={delegateCancelLabel}
        cancelButtonPriority="secondary"
        submitData={delegateAppt}
        isSubmitting={isSubmitting}
        open={!!delegatePageOpen}
        closeModal={closeDelegate}
        backHome={isWebApp ? null : closeDelegate}
        title={isWebApp ? (delegateModalTitle || 'Delegate Lead') : null}
        titleAlign="left"
        hideToolbarShadow
        bodyPadding="24px 16px 14px 16px"
        modalName="delegate"
        body={
          (
            <Box>
              <Box
                textAlign="left"
                style={{
                  margin: '24px 16px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'left',
                }}
              >
                {isWebApp && (
                  <Box mb={showSlLabel ? '8px' : '24px'}>
                    <Typography weight="bold" style={{ fontSize: 20 }}>
                      {delegateSlNrLabel || 'Select a New Sales Leader'}
                    </Typography>
                  </Box>
                )}
                <Typography
                  style={{
                    display: showSlLabel ? 'block' : 'none',
                    color: slLabelColor,
                    fontSize: 12,
                    fontFamily: 'Montserrat',
                    fontWeight: 600,
                    marginLeft: 12,
                    paddingLeft: 6,
                    lineHeight: '16px',
                  }}
                >
                  {slNrLabel}
                </Typography>
                <Box
                  style={{
                    borderBottom: `1px solid ${slInputUnderlineColor}`,
                    marginLeft: 6,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundColor: slInputBgColor,
                  }}
                >
                  <input
                    ref={slInputRef}
                    placeholder={slNrPlaceholder}
                    onFocus={() => {
                      setSlInputUnderlineColor(theme.palette.colors.corePink);
                      setSlLabelColor(theme.palette.colors.corePink);
                      setShowSlLabel(true);
                    }}
                    onChange={onChange}
                    onBlur={onChange}
                    style={{
                      border: 0,
                      padding: '2px 6px',
                      flexGrow: 1,
                      height: 32,
                      outline: 'none',
                      fontFamily: 'Montserrat',
                      fontWeight: 400,
                      fontSize: 16,
                      backgroundColor: slInputBgColor,
                    }}
                  />
                  <Box
                    style={{
                      display: slNrError ? 'block' : 'none',
                      backgroundColor: slInputBgColor,
                      padding: '4px 14px',
                    }}
                  >
                    {errorIcon}
                  </Box>
                </Box>

                <Box
                  style={{
                    display: slNrError ? 'block' : 'none',
                    margin: '4px 8px',
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: 'Montserrat',
                      fontSize: 12,
                      fontWeight: 400,
                      lineHeight: '16px',
                      letter: '-0.1px',
                      color: theme.palette.colors.error,
                    }}
                  >
                    {slNrRegexErrMsg}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )
        }
      />

      <Fullpage
        cancelLabel={delegateCancelLabel}
        cancelButtonPriority="secondary"
        open={!!delegateSuccessOpen}
        title={delegateModalTitle || 'Delegate Lead'}
        titleAlign="left"
        hideToolbarShadow
        buttonWidth={320}
        icon="success"
        closeModal={handleRejectClose}
        bodyPadding="30px 16px 0 16px"
        body={
          (
            <Box>
              <Typography
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: 22,
                  weight: 500,
                  textAlign: 'center',
                  lineHeight: '30px',
                }}
              >
                {delegateSuccessHeader}
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: 14,
                  weight: 400,
                  textAlign: 'center',
                  marginTop: 12,
                  lineHeight: '20px',
                  whiteSpace: 'pre-line',
                }}
              >
                {delegateSuccessBody}
              </Typography>
            </Box>
          )
        }
      />

      <Fullpage
        saveLabel={warningSaveLabel}
        cancelLabel={delegateCancelLabel}
        cancelButtonPriority="secondary"
        submitData={() => {
          setDelegateWarnOpen(false);
          setDelegatePageOpen(true);
          resetDelegatePage();
        }}
        open={!!delegateWarnOpen}
        icon="warn"
        closeModal={() => setDelegateWarnOpen(false)}
        bodyPadding="30px 16px 0 16px"
        body={
          (
            <Box>
              <Typography
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: 22,
                  weight: 500,
                  textAlign: 'center',
                  lineHeight: '30px',
                }}
              >
                {delegateWarnHeader}
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: 14,
                  weight: 400,
                  textAlign: 'center',
                  marginTop: 12,
                  lineHeight: '20px',
                  whiteSpace: 'pre-line',
                }}
              >
                {delegateWarnBody}
              </Typography>
            </Box>
          )
        }
      />
    </>
  );
};

ActionTab.propTypes = {
  heading: string.isRequired,
  resendSmsButton: object,
  smsLeftLabel: string,
  resendSMSBySL: object,
  smsModal: object,
  resendEmailButton: object,
  emailLeftLabel: string,
  emailModal: object,
  resendVerifyEmail: object,
  rejectButton: object,
  delegateButton: object,
  delegatePage: object,
  status: string,
  subStus: string,
  rejectModal: object,
  link: string,
  fields: object,
  market: string,
  currentUserId: object,
  selectActionLabel1: string,
  selectActionLabel2: string,
};

ActionTab.defaultProps = {
  delegateButton: {
    label: 'Delegate',
    priority: 'tertiary',
    showOn: ['new', 'in_progress'],
    slNrLabel: 'NEW SL ACCOUNT NUMBER',
    slNrPlaceholder: 'Enter new SL account number',
    saveLabel: 'DELEGATE',
    cancelLabel: 'CLOSE',
    delegateModalTitle: 'Delegate Lead',
    delegateSlNrLabel: 'Select a New Sales Leader',
    delegateSuccessHeader: 'Lead was delegated successfully',
    delegateSuccessBody: 'You have delagated the lead to the new SL.\nThe new SL will receive a notification.',
    warningSaveLabel: 'START AGAIN',
    delegateWarnHeader: 'Lead delegation has failed',
    delegateWarnBody: 'You cannot delegate this lead to the selected Sales Leader.\n\nYou can try to delegate this lead to another Sales Leader.',
    slNrRegex: '^[0-9]+$',
    slNrRegexErrMsg: 'You entered an invalid account number',
  },
};


export default ActionTab;
