import {
  string, shape,
} from 'prop-types';
import PrpMan from './PrpMan';
import withCallApi from '../utils/withCallApi';
import onComplete from './utils/onComplete';

const props = {
  onComplete,
};

const PrpManPage = ({
  location: { search },
}) => withCallApi(PrpMan, search, { ...props });

PrpManPage.propTypes = {
  location: shape({
    search: string.isRequired,
  }).isRequired,
};

export default PrpManPage;
