import React, { useState, useCallback } from 'react';
import {
  number, func, string, shape, oneOfType,
} from 'prop-types';


export const useClientRect = () => {
  const [width, setWidth] = useState(0);

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      const newWidth = node.getBoundingClientRect().width;
      if (newWidth > width) {
        setWidth(newWidth);
      }
    }
  }, [width]);
  return [width, measuredRef];
};

export const CustomTick = ({
  x, y, measuredRef, payload: { value }, language, variant,
}) => (
  <g className="recharts-layer recharts-cartesian-axis-tick">
    <text
      stroke="none"
      fill="#a2acae"
      x={x}
      y={y}
      className="recharts-text recharts-cartesian-axis-tick-value"
      textAnchor="end"
    >
      <tspan ref={measuredRef} dy="0.355em">
        {
        language === 'hu' && variant === 'vertical' && value.toString().split(' ').length > 1
          ? (`${value.toString().split(' ').reverse().join(' ')
            .replace('.', '')}.`) : value
      }
      </tspan>
    </text>
  </g>
);


function useYAxisProps(type, language) {
  const [width, measuredRef] = useClientRect();

  const horizontal = (
    {
      tickLine: false,
      axisLine: false,
      allowDecimals: false,
      tick: (<CustomTick measuredRef={measuredRef} language={language} variant="horizontal" />),
      orientation: language === 'ar' ? 'right' : 'left',
    }
  );

  const vertical = (
    {
      dataKey: 'name',
      type: 'category',
      tickLine: false,
      axisLine: false,
      allowDecimals: false,
      // hardcode hu date format, for all markets need backend adpater
      tick: (<CustomTick measuredRef={measuredRef} language={language} variant="vertical" />),
      orientation: language === 'ar' ? 'right' : 'left',
    }
  );

  if (type === 'vertical') {
    return { ...vertical, width: width ? width + 5 : 60 };
  }
  return { ...horizontal, width: width ? width + 5 : 60 };
}

CustomTick.propTypes = {
  x: number,
  y: number,
  measuredRef: func.isRequired,
  value: string,
  language: string,
  payload: shape({
    value: oneOfType([string, number]).isRequired,
  }),
  variant: string,
};

export default useYAxisProps;
