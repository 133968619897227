import React, { useMemo } from 'react';
import MuiButton from '@material-ui/core/Button';
import {
  bool,
  oneOf,
  node,
  func,
  string,
} from 'prop-types';

const priorities = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
};

const BarButton = ({
  priority,
  disabled,
  children,
  onClick,
  ...props
}) => {
  const variant = useMemo(() => (
    (priority === priorities.primary && 'contained')
    || (priority === priorities.secondary && 'outlined')
    || (priority === priorities.tertiary && undefined)
  ), [priority]);

  return (
    <MuiButton
      {...props}
      color={priorities.primary}
      variant={variant}
      id={`button-${variant}`}
      name={`button-${variant}`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </MuiButton>

  );
};

BarButton.propTypes = {
  priority: oneOf(Object.keys(priorities)),
  children: node,
  disabled: bool,
  error: string,
  onClick: func,
};

BarButton.defaultProps = {
  priority: priorities.primary,
  disabled: false,
};

export default BarButton;
