import React, {
  useState, useEffect, useCallback, useRef, useMemo,
} from 'react';
import Helmet from 'react-helmet';
import {
  Button, AvonContainer, useError, Checkbox as MuiCheckbox, InputField as AvonInputField,
} from '@avon/component-library';
import {
  func, bool, node, string,
} from 'prop-types';
import Box from '@material-ui/core/Box';
import { Formik, Field } from 'formik';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/styles/useTheme';
import MuiButton from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '../prpMan/components/Checkbox/Dialog';

import { mapInitialValues, mapSubmitValues } from './utils/mapInitialValues';
import { validateValues, getValidators } from './utils/formValidation';
import isEmpty from '../utils/isEmpty';
import {
  getProspectContent, setProspectData, setProspectDataSaveExit,
} from '../utils/api';
import noop from '../utils/noop';
import debounce from '../utils/debounce';
import { isDesktop, isMobile } from '../utils/getDeviceType';
import {
  InputField, Heading, AvonRadioGroup, AvonCheckGroup, Dropdown,
} from '../prpMan/components/Fields';
import {
  confirmIcon, errorIcon, infoIcon, warningIcon, lockIcon, prospectSuccessIcon,
} from '../prpMan/components/svgs';
import Modal from '../prpMan/components/Modal';
import Checkbox from '../prpMan/components/Checkbox';
import Fullpage from '../prpMan/components/Fullpage/Fullpage';

import ProspectContext from './ProspectContext';

const ContentWrapper = ({ children, fill, ...props }) => (
  <Box py={7} bgcolor={`colors.${fill ? 'lightZircon' : 'white'}`} {...props}>
    <AvonContainer maxWidth="lg">
      {children}
    </AvonContainer>
  </Box>
);

const components = {
  AvonInputField,
  InputField,
  Heading,
  AvonRadioGroup,
  AvonCheckGroup,
  Checkbox,
  Dropdown,
};

const useStyles = makeStyles(theme => ({
  topbar: {
    zIndex: 102,
    top: 0,
    position: 'sticky',
  },
  toolbar: {
    display: 'flex',
    zIndex: 102,
    width: '100%',
    textAlign: 'justify',
    textAlignLast: 'justify',
    paddingTop: theme.spacing(2.5),
    paddingLeft: styleProps => (styleProps.language === 'ar' ? theme.spacing(3) : theme.spacing(1)),
    paddingRight: styleProps => (styleProps.language === 'ar' ? theme.spacing(1) : theme.spacing(3)),
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.colors.white,
    '& span': {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 16,
    },
  },
  switchWarp: {
    width: '100%',
    paddingTop: theme.spacing(3.5),
    backgroundColor: theme.palette.colors.white,
  },
  switchLabel1: {
    paddingLeft: styleProps => (styleProps.language === 'ar' ? theme.spacing(2) : theme.spacing(3)),
    paddingRight: styleProps => (styleProps.language === 'ar' ? theme.spacing(3) : theme.spacing(2)),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > div': {
      fontWeight: 'normal',
      fontFamily: 'Montserrat',
      fontSize: 16,
      wordBreak: 'break-word',
    },
    '& svg': {
      cursor: 'pointer',
    },
  },
  customTooltip: {
    '& div .MuiTooltip-tooltip': {
      boxShadow: '0 4px 12px 0 rgba(0, 0, 0, 0.1)',
      border: 'solid 1px #e5eeee',
      backgroundColor: 'white !important',
    },
  },
  gridButton1: {
    minHeight: theme.spacing(3),
    width: 'auto',
    paddingTop: 0,
    paddingBottom: 0,
    wordBreak: 'break-word',
    color: theme.palette.colors.textDark,
  },
  gridButton2: {
    minHeight: theme.spacing(3),
    width: 'auto',
    paddingTop: 0,
    paddingBottom: 0,
    wordBreak: 'break-word',
    color: theme.palette.colors.corePink,
    '&:hover': {
      backgroundColor: 'rgba(24, 24, 24, 0.08)',
    },
  },
  gridButton3: {
    minHeight: theme.spacing(3),
    width: 'auto',
    paddingTop: 0,
    paddingBottom: 0,
    wordBreak: 'break-word',
    color: theme.palette.colors.corePink,
  },
  preViewLabel: {
    textDecoration: 'underline',
  },
  bannerWarp: {
    width: '100%',
    backgroundColor: theme.palette.colors.white,
    padding: theme.spacing(1.5),
  },
  banner: {
    width: '100%',
    height: '100%',
    backgroundColor: '#1a8282',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    '& p': {
      fontWeight: 'normal',
      color: theme.palette.colors.white,
      fontFamily: 'Montserrat-Medium',
      lineHeight: 'normal',
      fontSize: 14,
    },
  },
  bannerIcon: {
    height: '100%',
    lineHeight: `${theme.spacing(9)}px`,
    paddingLeft: styleProps => (styleProps.language === 'ar' ? 'unset' : theme.spacing(3)),
    paddingRight: styleProps => (styleProps.language === 'ar' ? theme.spacing(3) : 'unset'),
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(0.5),
  },
  titleWarp: {
    height: '100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3.5),
    paddingBottom: theme.spacing(2),
  },
  closeIcon: {
    textAlign: styleProps => (styleProps.language === 'ar' ? 'left' : 'right'),
    height: '100%',
    lineHeight: `${theme.spacing(9)}px`,
    paddingLeft: styleProps => (styleProps.language === 'ar' ? theme.spacing(3) : 'unset'),
    paddingRight: styleProps => (styleProps.language === 'ar' ? 'unset' : theme.spacing(3)),
    paddingTop: theme.spacing(0.5),
  },
  fieldBgColor: {
    backgroundColor: theme.palette.colors.lightZircon,
  },
  fieldWarp: {
    backgroundColor: theme.palette.colors.white,
  },
  divider: {
    marginLeft: '-12px',
    marginRight: '-12px',
  },
  formGroup: {
    display: 'inline',
    paddingRight: styleProps => (styleProps.language === 'ar' ? 'unset' : theme.spacing(2)),
    paddingLeft: styleProps => (styleProps.language === 'ar' ? theme.spacing(2) : 'unset'),
    justifyContent: 'space-between',
    color: theme.palette.colors.textMedium,
  },
  radioGroup: {
    display: '-webkit-inline-box',
  },
  submitButton: {
    position: 'sticky',
    bottom: 0,
    boxShadow: '0 -2px 16px 0 rgba(88, 100, 102, 0.15)',
  },
  clickableText: {
    color: theme.palette.primary.main,
    fontFamily: 'Montserrat',
    fontWeight: 700,
  },
}));

const Prospect = ({
  callApi, onComplete, language, market,
}) => {
  const theme = useTheme();
  const isMobileMedia = useMediaQuery(theme.breakpoints.down('sm'));
  const [content, setContent] = useState({
    fields: {},
    validators: {},
    types: {},
    labels: {},
    modals: {},
    privacy: {
      dialog: {
        labels: {
          checkLabel1: '',
          checkLabel2: '',
          checkError: 'Please read and accept the Terms and Condition',
        },
        contents: { aPP: { tcText: '' } },
      },
    },
    showHideMap: {},
  });
  const inputRef = useRef(null);
  const privacyRef = useRef(null);
  const styleProps = { language };
  const classes = useStyles(styleProps);
  const [initialData, setInitialData] = useState();
  const [modalSubmit, setSubmitModal] = useState(false);
  const [modalBack, setModalBack] = useState(false);
  const [dateErrorModal, setDataErrorModal] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const [checkEmail, setCheckEmail] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [toolBarObject, setToolBar] = useState({});
  const [emailValue, setEmailValue] = useState();
  // const [fakePrivacyChecked, setFakePrivacyChecked] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [apptId, setApptId] = useState();
  // eslint-disable-next-line max-len
  // const isSubmitDisabled = useMemo(() => !privacyChecked, [privacyChecked]);
  // const isSaveDisabled = useMemo(() => !privacyChecked, [privacyChecked]);
  const [open, setOpen] = useState(false);
  const [isSendMail, setIsSendMail] = useState(false);
  // const [saveDisabled, setSaveDisabled] = useState(false);
  // const [submitDisabled, setSubmitDisabled] = useState(false);
  const handleClose = () => setOpen(false);
  const [privacyErrorShow, setPrivacyErrorShow] = useState(false);

  const [formFields, setFormFields] = useState();
  const [mobileErrorsParent, setMobileErrorsParent] = useState();

  const getChangeContent = (val, handleBlur) => {
    handleBlur(val);
  };
  const iPhone = (method, paramsObject = {}) => {
    const msg = `{"method": "${method}", "params": "${JSON.stringify(paramsObject).replace(/"/g, '\\"')}"}`;
    window.webkit.messageHandlers.avonRec.postMessage(msg);
  };
  const Android = (method, andriodObject = {}) => {
    if (window.Android) {
      window.Android.dispatcher(method, JSON.stringify(andriodObject));
    } else {
      noop();
    }
  };
  const backHome = () => {
    if (sessionStorage.getItem('isWebApp')) {
      window.parent.postMessage('backHome', '*');
    } else if (window.navigator.userAgent.match(/(iPhone)|(iPad)|(Macintosh)/i)) {
      iPhone('backHome', {});
    } else {
      Android('backHome', {});
    }
  };

  const renderPrivacyDialog = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const navigateToProfile = () => {
    if (sessionStorage.getItem('isWebApp')) {
      window.parent.postMessage({ menu: 'prospectPage', subMenu: 'saved', params: `apptId=${apptId}` }, '*');
    } else if (window.navigator.userAgent.match(/(iPhone)|(iPad)|(Macintosh)/i)) {
      iPhone('navigateToProfile', { apptId });
    } else {
      Android('navigateToProfile', { apptId });
    }
  };

  const closeSubmitModal = () => {
    setSubmitModal(false);
  };

  const emailFocus = () => {
    const emailInput = inputRef.current.querySelector(`#prpman-AvonInputField-${content.labels.emailRequireFields}`);
    setTimeout(() => {
      emailInput.focus();
    }, 500);
  };

  const closeEmailModal = () => {
    setEmailModal(false);
  };

  const closeSuccessModal = () => {
    setSuccessModal(false);
    window.location.reload();
  };

  const { setError, cleanToastBanner } = useError();

  useEffect(() => {
    const getStatic = async () => {
      try {
        const res = await callApi(getProspectContent());
        const {
          modals, fields, labels, toolBar, showHideMap, privacy,
        } = res.jsonObject;

        setFormFields(fields);
        setToolBar(toolBar);
        const { validators, types } = getValidators(fields);
        setContent({
          fields,
          labels,
          modals,
          validators,
          types,
          showHideMap,
          privacy,
        });
        cleanToastBanner();
      } catch ({ error }) {
        error.reCall = getStatic;
        error.args = [];
        setError(error);
      }
    };
    getStatic();
  }, [callApi]);

  useEffect(() => {
    // eslint-disable-next-line
    const { validators, types } = mobileErrorsParent ? getValidators(formFields, mobileErrorsParent) : getValidators(formFields);
    const {
      fields, labels, modals, showHideMap, privacy,
    } = content;
    setContent({
      fields,
      labels,
      modals,
      validators,
      types,
      showHideMap,
      privacy,
    });
  }, [formFields, mobileErrorsParent]);

  const getData = useCallback(async () => {
    try {
      setSubmitting(true);
      const res = {};
      const {
        status, ...rest
      } = res;

      setContent({ ...content });
      setInitialData(mapInitialValues(rest, content.fields));
      setDataErrorModal(false);
    } catch (error) {
      setDataErrorModal(true);
    }
    setSubmitting(false);
  }, [content.fields]);

  useEffect(() => {
    if (!isEmpty(content.fields)) getData();
  }, [callApi, content.fields]);

  const submitData = async (values) => {
    if (isSubmitting) return false;
    if (checkEmail && !emailValue) {
      setEmailModal(true);
      return true;
    }
    setEmailModal(false);
    const prospect = mapSubmitValues(values, content.types);

    // if (fakePrivacyChecked) {
    //   delete prospect[toolBarObject.saveInvalidError];
    // }

    const body = isSendMail
      ? {
        rep: {
          prospect: {
            ...prospect, privacyPolicyChecked: true,
          },
        },
      }
      : {
        rep: { prospect },
      };
    cleanToastBanner();

    try {
      setSubmitting(true);
      await callApi(checkEmail
        ? setProspectData(body)
        : setProspectDataSaveExit(body)).then((res) => {
        setApptId(res.apptId);
        onComplete(res);
      });

      cleanToastBanner();

      setSuccessModal(true);

      setSubmitting(false);
      return true;
    } catch ({ error }) {
      closeSubmitModal();
      error.slReCall = submitData;
      error.slArgs = values;
      error.reCall = submitData;
      error.args = [values];
      setError(error);

      setSubmitting(false);
      return false;
    }
  };

  const openSubmitModal = debounce((values) => {
    submitData(values);
  }, 500);

  const runValidation = (values) => {
    const validated = validateValues(values, content);
    if (validated && Object.keys(validated).length > 0) {
      // setSaveDisabled(false);
      // setSubmitDisabled(false);
    }
    return validated;
  };

  const iconMap = {
    Information: infoIcon,
    Confirmation: confirmIcon,
    Warning: warningIcon,
    Error: errorIcon,
    Lock: lockIcon,
    prospectSuccessIcon,
  };
  switch (language) {
    case 'ar':
      // set direction to rtl
      document.body.dir = 'rtl';
      break;

    case 'ru':
    case 'tr':
    case 'tl':
      // set direction to ltr
      document.body.dir = 'ltr';
      break;

    default:
      // Do nothing
      break;
  }

  const getOffset = (element) => {
    try {
      const ele = element.offsetParent.offsetParent;
      return ele.offsetTop;
    } catch (e) {
      return 0;
    }
  };

  const locateError = (errors) => {
    if (errors && Object.keys(errors).length > 0) {
      // setSaveDisabled(false);
      // setSubmitDisabled(false);
      const firstErrorKey = Object.keys(errors)[0];
      const inputRefTop = inputRef.current.querySelector(`#prpman-AvonInputField-${firstErrorKey}`)
        || inputRef.current.querySelector(`#prpman-AvonInputField-${firstErrorKey}-input`)
        || inputRef.current.querySelector(`#${firstErrorKey}-checkbox`);

      if (inputRefTop) {
        const inputParentTop = getOffset(inputRefTop) - 90;
        const increment = window.scrollY / 20;
        const createScroll = () => setTimeout(() => {
          if (inputParentTop > 0 && window.scrollY > inputParentTop) {
            window.scrollTo({
              top: window.scrollY - increment,
              left: 0,
            });
            createScroll();
          }
        }, 20);
        if (window.scrollY) {
          createScroll();
        }
        setTimeout(() => {
          inputRefTop.focus();
        }, 500);
      }
    }
  };

  const toggleChecked = () => {
    setPrivacyChecked(!privacyChecked);
    setPrivacyErrorShow(!privacyErrorShow);
  };

  const progressProps = {
    size: '1em',
    color: 'primary',
  };

  const dialogContents = useMemo(() => {
    const { dialog } = content.privacy;
    if (dialog) {
      const { wrapper, close } = dialog.labels;
      const { aPP: { tcText: { tcText: aPP } } } = dialog.contents;

      const dialogOptions = (
        <Box py={7} component={AvonContainer}>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: aPP }} />
        </Box>
      );

      return {
        labels: { wrapper, close },
        contents: dialogOptions,
      };
    }
    return false;
  }, [content.privacy]);

  const privacyEle = (
    <Grid key="prospect-privacy-check" item xs={12}>
      <Box p={2} ml={3}>
        <MuiCheckbox
          name="prospect-privacy-check-Checkbox"
          id="prospect-privacy-check-Checkbox"
          onChange={toggleChecked}
          checked={privacyChecked || false}
          label={(
            <Typography>
              {content.privacy.dialog.labels.checkLabel1 || 'I have read and agreed to the'}
              <Box
                component="span"
                className={classes.clickableText}
                id="prospect-privacy-check-box-label2"
                // eslint-disable-next-line max-len
                onClick={renderPrivacyDialog}
              >
                {content.privacy.dialog.labels.checkLabel2 || ' Avon Privacy Policy'}
              </Box>
            </Typography>
          )}
        />
      </Box>
      <Typography ref={privacyRef} style={{ display: (!privacyChecked && privacyErrorShow) ? 'block' : 'none', color: theme.palette.colors.error }}>
        <Box component="span" ml={6}>
          {content.privacy.dialog.labels.checkError}
        </Box>
      </Typography>
    </Grid>
  );

  return (
    <>
      <Helmet htmlAttributes={{ lang: language || 'en' }} />
      <ProspectContext.Provider value={{
        inputRef,
        setMobileErrorsParent,
        content,
      }}
      >
        {!!initialData && (
          <Formik
            initialValues={initialData}
            validate={runValidation}
            onSubmit={openSubmitModal}
          >
            {({
              submitForm, values, errors, handleBlur,
            }) => (
              <form id="admin-prospect-form-1">
                <Box className={classes.topbar}>
                  <Box
                    data-testid="toolbar"
                    id="admin-prospect-toolbar"
                    component="div"
                    className={classes.toolbar}
                  >
                    {!isDesktop() && (
                      <Box
                        className={classes.gridButton1}
                        id="back-home-button-prospect"
                        onClick={backHome}
                      >
                        <svg width="60" height="44" viewBox="0 0 60 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M23.3872 15.2096C23.7795 14.9047 24.3467 14.9324 24.7072 15.293L24.7904 15.3872C25.0953 15.7795 25.0676 16.3467 24.707 16.7072L20.4132 21H32C32.5523 21 33 21.4477 33 22C33 22.5128 32.614 22.9355 32.1166 22.9933L32 23H20.4167L24.7071 27.2916L24.7903 27.3858C25.0953 27.7781 25.0676 28.3453 24.7071 28.7058C24.3166 29.0963 23.6834 29.0963 23.2929 28.7058L17.3241 22.737C17.1249 22.5542 17 22.2917 17 22C17 21.9992 17 21.9983 17 21.9975C17.0003 21.7419 17.098 21.4865 17.293 21.2915L23.293 15.2928L23.3872 15.2096Z" fill="#181818" />
                        </svg>

                        {/* {toolBarObject.close} */}
                      </Box>
                    )}
                    <Typography
                      variant="h4"
                      style={{
                        alignSelf: (isMobile() || isMobileMedia) ? 'center' : 'unset',
                        flexGrow: 1,
                        textAlignLast: (isMobile() || isMobileMedia) ? 'center' : 'left',
                        fontSize: (isMobile() || isMobileMedia) ? '16px' : '24px',
                        lineHeight: (isMobile() || isMobileMedia) ? '24px' : '32px',
                        margin: (isMobile() || isMobileMedia) ? '10px' : 24,
                        fontWeight: 500,
                        fontFamily: 'Montserrat-Medium',
                      }}
                    >
                      {content.labels.pageTitle}
                    </Typography>
                    <MuiButton
                      color="secondary"
                      variant="contained"
                      className={classes.gridButton2}
                      // disabled={saveDisabled}
                      onClick={
                        async () => {
                          if (privacyChecked) {
                            if (errors && Object.keys(errors).length === 0) {
                              // setSaveDisabled(true);
                              setCheckEmail(false);
                              setEmailValue(values[content.labels.emailRequireFields]);
                              submitForm(values);
                            } else {
                              locateError(errors);
                            }
                          } else {
                            await setPrivacyErrorShow(true);
                            privacyRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
                          }
                        }
                      }
                      id="prospect-save-button"
                    >
                      {!isSubmitting ? (
                        <>
                          <Box ml={language === 'ar' ? 'unset' : 1.5} mr={language === 'ar' ? 1.5 : 'unset'}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M16 3L20.4142 7.41421C20.7893 7.78929 21 8.29799 21 8.82843V19C21 20.1046 20.1046 21 19 21H15H9H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3H9H13H16ZM19 19H17V13C17 11.8954 16.1046 11 15 11H9C7.89543 11 7 11.8954 7 13V19H5V5H7V7C7 8.10457 7.89543 9 9 9H13C14.1046 9 15 8.10457 15 7V5H15.171L19 8.82843V19ZM15 17V19H9V17H15ZM15 15V13H9V15H15ZM13 5H9V7H13V5Z" fill="#181818" />
                            </svg>
                          </Box>
                        </>
                      ) : (
                        <CircularProgress {...progressProps} />
                      )}
                    </MuiButton>
                  </Box>
                </Box>

                <Box pt={3.5} ref={inputRef}>
                  {content.fields.map((section, i) => (
                    <>
                      <ContentWrapper
                        key={`form-section-${i}`}
                        id={`form-section-${i}`}
                        style={{ paddingBottom: i === 1 ? '0px' : 'inherit' }}
                      >
                        <Grid container spacing={7}>
                          {section.map(({ type, properties }, item) => (
                            <Grid
                              item
                              xs={12}
                              sm={(type === 'Heading' || properties.name === 'note') ? 12 : 6}
                              md={(type === 'Heading' || properties.name === 'note') ? 12 : 4}
                              key={`${item}-${properties.name}`}
                              id={`${item}-${properties.name}`}
                              className={properties.showBgColor && isMobile()
                                ? classes.fieldBgColor : classes.fieldWarp}
                            >
                              <Field
                                onBlur={e => getChangeContent(e, handleBlur)}
                                callApi={callApi}
                                component={components[type]}
                                setBannerShow1={noop}
                                language={language}
                                rows={properties.rows || 1}
                                fullWidth
                                priority={properties.priority || 'primary'}
                                multiline={properties.multiline}
                                maxLength={properties.maxLength}
                                showHideMap={content.showHideMap}
                                initialData={initialData}
                                {...properties}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </ContentWrapper>
                      {!isMobile() && (
                        <ContentWrapper
                          key={`form-section-divide-${i}`}
                          id={`form-section-divide-${i}`}
                          style={{ paddingBottom: i === 1 ? '0px' : 'inherit' }}
                        >
                          <Box id={`divde-${i}`} style={{ marginTop: 24, height: 1, borderBottom: '1px solid rgba(0, 0, 0, 0.0784314)' }} />
                        </ContentWrapper>
                      )}
                    </>
                  ))}

                  {
                    isMobile() ? privacyEle
                      : (
                        <ContentWrapper
                          key="prospect-privacy-check-wrapper"
                          id="prospect-privacy-check-wrapper"
                        >
                          {privacyEle}
                        </ContentWrapper>
                      )
                  }

                  <Grid item xs={12}>
                    <ContentWrapper key="form-submit" id="form-submit">
                      <Box
                        mb={3}
                        display="block"
                        style={{
                          textAlign: 'center',
                          width: 341,
                          height: 58,
                          margin: '0 auto',
                        }}
                      >
                        <Button
                          id="admin-prospect-submit-form"
                          name="admin-prospect-submit-form"
                          loading={isSubmitting}
                          // disabled={submitDisabled}
                          style={{ width: isMobile() ? '100%' : '30%' }}
                          onClick={() => {
                            if (privacyChecked) {
                              if (errors && Object.keys(errors).length === 0) {
                                // setSubmitDisabled(true);
                                setCheckEmail(true);
                                setEmailValue(values[content.labels.emailRequireFields]);
                                setIsSendMail(true);
                                submitForm(values);
                              } else {
                                locateError(errors);
                              }
                            } else {
                              setPrivacyErrorShow(true);
                            }
                          }}
                          priority={isMobile() ? 'gradient' : 'primary'}
                        >
                          {content.labels.saveLabel}
                        </Button>
                      </Box>
                    </ContentWrapper>
                  </Grid>

                  <Fullpage
                    saveLabel={toolBarObject.reuse || 'USE AGAIN'}
                    cancelLabel={toolBarObject.close}
                    needReload
                    market={market}
                    body={
                      checkEmail && emailValue
                        ? (
                          <>
                            <Box mt={5} textAlign="left" style={{ display: 'flex', alignItems: 'center', height: 32 }}>
                              {iconMap.prospectSuccessIcon()}
                              <Typography style={{ fontSize: 16, fontFamily: 'Montserrat', margin: language === 'ar' ? '0 10px 0 0' : '0 0 0 10px' }}>
                                {toolBarObject.saveSuccessInfo}
                              </Typography>
                            </Box>
                            <Box
                              mt={5}
                              textAlign="left"
                              style={{
                                display: 'flex', alignItems: 'center', height: 32, marginTop: 0,
                              }}
                            >
                              <Typography style={{ fontSize: 16, fontFamily: 'Montserrat', marginLeft: 42 }}>
                                {toolBarObject.viewHereLabel || 'You can view it'}
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: 16,
                                  fontFamily: 'Montserrat',
                                  marginLeft: 4,
                                  color: theme.palette.colors.corePink,
                                  cursor: 'pointer',
                                }}
                                onClick={() => navigateToProfile(apptId)}
                              >
                                {toolBarObject.viewHereLink || 'Here'}
                              </Typography>
                            </Box>

                            <Box mt={5} textAlign="left" style={{ display: 'flex', alignItems: 'center', height: 32 }}>
                              {iconMap.prospectSuccessIcon()}
                              <Typography style={{
                                fontSize: 16, fontFamily: 'Montserrat', margin: language === 'ar' ? '0 10px 0 0' : '0 0 0 10px',
                              }}
                              >
                                {toolBarObject.emailSuccessInfo}
                              </Typography>
                            </Box>
                            <Box
                              mt={5}
                              textAlign="left"
                              style={{
                                display: 'flex', alignItems: 'center', marginTop: 0,
                              }}
                            >
                              <Typography style={{
                                fontSize: 16, fontFamily: 'Montserrat', marginLeft: 42, wordBreak: 'break-all',
                              }}
                              >
                                {emailValue}
                              </Typography>
                            </Box>
                          </>
                        )
                        : (
                          <>
                            <Box mt={5} textAlign="left" style={{ display: 'flex', alignItems: 'center', height: 32 }}>
                              {iconMap.prospectSuccessIcon()}
                              <Typography style={{ fontSize: 16, fontFamily: 'Montserrat', margin: language === 'ar' ? '0 10px 0 0' : '0 0 0 10px' }}>
                                {toolBarObject.saveSuccessInfo}
                              </Typography>
                            </Box>
                            <Box
                              mt={5}
                              textAlign="left"
                              style={{
                                display: 'flex', alignItems: 'center', height: 32, marginTop: 0,
                              }}
                            >
                              <Typography style={{ fontSize: 16, fontFamily: 'Montserrat', marginLeft: 42 }}>
                                {toolBarObject.viewHereLabel || 'You can view it'}
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: 16,
                                  fontFamily: 'Montserrat',
                                  marginLeft: 4,
                                  color: theme.palette.colors.corePink,
                                  cursor: 'pointer',
                                }}
                                onClick={() => navigateToProfile(apptId)}
                              >
                                {toolBarObject.viewHereLink || 'Here'}
                              </Typography>
                            </Box>
                          </>
                        )
                    }
                    title={toolBarObject.success || 'Success'}
                    submitData={closeSuccessModal}
                    isSubmitting={isSubmitting}
                    open={!!successModal}
                    icon={iconMap.prospectSuccessIcon()}
                    closeModal={backHome}
                    backHome={backHome}
                  />

                  <Modal
                    {...content.modals.emailModal}
                    submitData={() => {
                      setCheckEmail(false);
                      setEmailValue(values[content.labels.emailRequireFields]);
                      submitForm(values);
                    }}
                    isSubmitting={isSubmitting}
                    open={!!emailModal}
                    icon
                    fnInCloseModal={emailFocus}
                    closeModal={closeEmailModal}
                  />
                  <Modal
                    {...content.modals.submitModal}
                    submitData={async () => submitData(values)}
                    isSubmitting={isSubmitting}
                    open={!!modalSubmit}
                    closeModal={closeSubmitModal}
                  />
                  <Modal
                    {...content.modals.backModal}
                    submitData={async () => {
                      if (errors && Object.keys(errors).length === 0) {
                        const sendRes = await submitData(values);
                        if (sendRes) {
                          backHome();
                        } else {
                          setModalBack(false);
                        }
                      } else {
                        setModalBack(false);
                        locateError(errors);
                      }
                    }}
                    isSubmitting={isSubmitting}
                    open={!!modalBack}
                    closeModal={backHome}
                    icon
                  />
                </Box>

              </form>
            )}
          </Formik>
        )}
        {content.modals.errorModal && (
          <Modal
            {...content.modals.errorModal}
            submitData={getData}
            isSubmitting={isSubmitting}
            open={!!dateErrorModal}
          />
        )}
        <Dialog fullScreen open={open} handleClose={handleClose} {...dialogContents} id="prospect-privacy-dialog" />
      </ProspectContext.Provider>
    </>
  );
};

Prospect.propTypes = {
  callApi: func.isRequired,
  onComplete: func.isRequired,
  language: string,
  market: string,
};

ContentWrapper.propTypes = {
  children: node.isRequired,
  fill: bool,
};

export default Prospect;
