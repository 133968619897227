import React, { useRef, useState, useEffect } from 'react';
import {
  object, func, string, node, bool,
} from 'prop-types';
import { Button, Typography, AvonContainer } from '@avon/component-library';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { Formik, Field } from 'formik';
import { validateValues, getValidators } from '../../utils/formValidation';
import { InputField } from '../Fields';
import { addTestimonial, editTestimonial } from '../../../utils/api';
import { isMobile } from '../../../utils/getDeviceType';

const ContentWrapper = ({ children, fill, ...props }) => (
  <Box bgcolor="colors.lightZircon" {...props}>
    <AvonContainer maxWidth="lg">
      {children}
    </AvonContainer>
  </Box>
);

ContentWrapper.propTypes = {
  children: node.isRequired,
  fill: bool,
};

const components = {
  InputField,
};

const useStyles = makeStyles(theme => ({
  formBox: {
    marginTop: e => (e.isWebApp ? 0 : 24),
    padding: '24px 0 6px 0',
    border: '1px solid #A2ACAE',
    boxSizing: 'border-box',
    borderRadius: 4,
    // eslint-disable-next-line
    backgroundColor: e => (e.isWebApp ? theme.palette.colors.white : theme.palette.colors.lightZircon),
  },
  formFields: {
    // eslint-disable-next-line
    backgroundColor: e => (e.isWebApp ? theme.palette.colors.white : theme.palette.colors.lightZircon),
  },
}));

const TestimonialForm = ({
  content,
  // refreshTestimonials,
  callApi,
  language,
  initData,
  isSubmitting,
  toggleForm,
  setBannerShow,
  refresh,
  onSaveClick,
}) => {
  const isWebApp = sessionStorage.getItem('isWebApp') && !isMobile();
  const classes = useStyles({ isWebApp });
  const inputRef = useRef({});
  const [formContent, setFormContent] = useState({ fields: {}, validators: {}, types: {} });
  const [formData, setFormData] = useState({
    name: initData.name || '',
    location: initData.location || '',
    testimony: initData.testimony || '',
  });

  // eslint-disable-next-line
  const getFormData = () => (initData ? { name: initData.name, location: initData.location, testimony: initData.testimony } : { name: '', location: '', testimony: '' });

  useEffect(() => {
    const { validators, types } = getValidators(content.fields);
    setFormContent({ fields: content.fields, validators, types });
  }, []);

  useEffect(() => {
    setFormData(getFormData());
  }, [initData]);

  useEffect(() => {
    const inputEles = inputRef.current.parentElement.parentElement.parentElement.parentElement.querySelectorAll('[id^="input-"]');
    if (inputEles.length > 0) {
      inputEles.forEach(
        // eslint-disable-next-line
        e => {
          e.value = initData[e.id.substr(e.id.indexOf('-') + 1, e.id.length)];
        },
      );
    }
  }, [formData]);

  const {
    formNewHeading,
    formEditHeading,
    saveLabel,
    cancelLabel,
    saveSuccessLabel,
    editSuccessLabel,
  } = content;

  const runValidation = values => validateValues(values, formContent);

  const submitData = async (values) => {
    try {
      if (initData && initData.id) {
        // eslint-disable-next-line
        values = { ...values, id: initData.id };
      }
      await callApi(initData && initData.id ? editTestimonial(values) : addTestimonial(values));
      setBannerShow({
        open: true,
        content: initData && initData.id ? editSuccessLabel : saveSuccessLabel,
      });
      toggleForm(false);
      refresh();
      return true;
    } catch ({ error }) {
      return false;
    }
  };

  const clearInputs = (clearFn) => {
    // clear the form
    // eslint-disable-next-line
    let inputEles = inputRef.current.parentElement.parentElement.parentElement.parentElement.querySelectorAll('[id^="input-"]');
    if (inputEles.length > 0) {
      inputEles.forEach(
        // eslint-disable-next-line
        e => {
          e.value = '';
          clearFn(e.name, '');
        },
      );
    }
  };

  return (
    <Box className={classes.formBox}>
      <Box pb={8} ml={1}>
        <Typography
          style={{
            paddingLeft: language === 'ar' ? 'unset' : 16,
            paddingRight: language === 'ar' ? 16 : 'unset',
            fontSize: 14,
            color: '#000000',
          }}
          weight="bold"
        >
          {initData && initData.id ? formEditHeading : formNewHeading}
        </Typography>
      </Box>
      <Box className={classes.formFields}>
        <Formik
          initialValues={formData}
          validate={runValidation}
          onSubmit={async (values, actions) => {
            await submitData(values);
            actions.resetForm();
            clearInputs(actions.resetForm);
          }}
        >
          {({
            submitForm, values, errors, handleBlur, resetForm,
          }) => (
            <form
              id="add-testimonial"
              style={isWebApp ? {
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'flex-end',
              } : {}}
            >
              {content.fields.map((section, i) => (
                <ContentWrapper
                  key={`form-testimonial-${i}`}
                  id={`form-testimonial-${i}`}
                  pb={6}
                  style={isWebApp ? {
                    backgroundColor: '#ffffff',
                    flexBasis: i < 2 ? '50%' : '100%',
                  } : {}}
                >
                  <Grid container spacing={7}>
                    {section.map(({ type, properties }, item) => (
                      <Grid
                        item
                        xs={12}
                        key={`${item}-${properties.name}`}
                        id={`${item}-${properties.name}`}
                        ref={inputRef}
                      >
                        <Field
                          id={`input-${properties.name}`}
                          onBlur={handleBlur}
                          component={components[type]}
                          language={language}
                          {...properties}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </ContentWrapper>
              ))}
              <Box
                m={5}
                style={isWebApp ? {
                  flexBasis: '50%',
                  display: 'flex',
                } : { display: 'flex' }}
              >
                <Box mr={language === 'ar' ? 'unset' : 3} ml={language === 'ar' ? 3 : 'unset'} flex={1}>
                  <Button
                    onClick={() => {
                      toggleForm(false);
                      resetForm();
                      clearInputs(resetForm);
                      refresh();
                    }}
                    name="cancel-testimonial-button"
                    id="cancel-testimonial-button"
                    priority="tertiary"
                    type="reset"
                  >
                    {cancelLabel}
                  </Button>
                </Box>
                <Box flex={1}>
                  <Button
                    onClick={() => {
                      // submitForm(values);
                      onSaveClick(submitForm, values, !!initData.id);
                      // clearInputs(setFieldValue);
                    }}
                    loading={isSubmitting}
                    type="submit"
                    priority="secondary"
                    name="add-testimonial-button"
                    id="add-testimonial-button"
                    disabled={Object.values(values).some(e => e === '') || (errors && Object.keys(errors).length > 0)}
                  >
                    {saveLabel}
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

TestimonialForm.propTypes = {
  // refreshTestimonials: func,
  callApi: func,
  language: string,
  initData: object,
  content: object,
  isSubmitting: bool,
  toggleForm: func,
  setBannerShow: func,
  refresh: func,
  onSaveClick: func,
};


export default TestimonialForm;
