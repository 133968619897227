import React, { useContext } from 'react';
import { Typography } from '@avon/component-library';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import { object, func, bool } from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import LmaContext from '../../LmaContext';

const useStyles = makeStyles(theme => ({

  filterHeading: {
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formGroup: {
    display: 'flex',
    width: '100%',
    paddingLeft: styleProps => (styleProps.language === 'ar' ? theme.spacing(5) : 'unset'),
    paddingRight: styleProps => (styleProps.language === 'ar' ? 'unset' : theme.spacing(5)),
    marginLeft: styleProps => (styleProps.language === 'ar' ? '-11px' : '16px'),
    marginRight: styleProps => (styleProps.language === 'ar' ? '16px' : '-11px'),
    justifyContent: 'space-between',
    color: theme.palette.colors.textMedium,
    fontWeight: '700',
  },
  actionButton: {
    borderRadius: 0,
    margin: 0,
    '&:first-child': {
      color: theme.palette.colors.textLight,
    },
    '&:not(:first-child)': {
      borderLeft: styleProps => (styleProps.language === 'ar' ? 'unset' : `1px solid ${theme.palette.colors.zircon}`),
      borderRight: styleProps => (styleProps.language === 'ar' ? `1px solid ${theme.palette.colors.zircon}` : 'unset'),
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  actions: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: theme.palette.colors.lightZircon,
    boxShadow: '0 -2px 4px 0 rgba(225, 225, 225, 0.5)',
  },
  divider: {
    margin: `0 -${theme.spacing(3)}px`,
  },
  expandIcon: {
    color: theme.palette.colors.textLight,
    display: 'block',
  },
  activeFilter: {
    color: theme.palette.colors.corePink,
    margin: styleProps => (
      styleProps.language === 'ar'
        ? `auto auto auto ${theme.spacing(5)}px`
        : `auto ${theme.spacing(5)}px auto auto`
    ),
    height: '8px',
    width: '8px',
  },
  iconContainer: {
    color: theme.palette.colors.textLight,
  },
}));

const activeFilter = (filterValue, radioOptions) => {
  const activeFilters = Object.entries(filterValue).reduce((previous, [key, value]) => {
    const updated = previous;
    if (value) updated.push(key);
    return updated;
  }, []);


  const radioValues = radioOptions.map(option => option.value);
  return activeFilters.some(key => radioValues.indexOf(key) !== -1);
};

const Filter = ({
  typeExpanded,
  setTypeExpanded,
  statusExpanded,
  setStatusExpanded,
  sourceExpanded,
  setSourceExpanded,
  setDisable,
  filterValue,
  setFilter,
}) => {
  const [{
    leadsContent:
    { sortFilter: { sortFilterTabInfo: { filter } } },
    language,
  }] = useContext(LmaContext);
  const classes = useStyles({ language });
  const handleExpandClick = (type) => {
    if (type === 'source') {
      setSourceExpanded(!sourceExpanded);
    } else if (type === 'type') {
      setTypeExpanded(!typeExpanded);
    } else {
      setStatusExpanded(!statusExpanded);
    }
  };
  const expanded = (type) => {
    if (type === 'source') {
      return sourceExpanded;
    }
    if (type === 'type') {
      return typeExpanded;
    }
    return statusExpanded;
  };
  const handleChange = name => (event) => {
    setFilter({ ...filterValue, [name]: event.target.checked });
    setDisable(false);
  };

  return (
    <>
      <FormControl component="fieldset" fullWidth>
        <FormGroup id="lma-filter-formgroup">
          {
            filter.filterOptions.map(field => (
              <Box key={field.type}>
                <Box pt={4} pb={4}>
                  <Box
                    id={`lma-expand-${field.type}`}
                    onClick={() => handleExpandClick(field.type)}
                    className={classes.filterHeading}
                  >
                    <Typography weight="bold" id={`lma-typograph-${field.type}`}>
                      {field.title}
                    </Typography>
                    {activeFilter(filterValue, field.radioOptions) ? (

                      <Brightness1Icon id={`lma-briicon-${field.type}`} className={classes.activeFilter} />

                    ) : null}
                    {expanded(field.type)
                      ? <RemoveIcon id={`lma-removeicon-${field.type}`} className={classes.expandIcon} />
                      : <AddIcon id={`lma-addicon-${field.type}`} className={classes.expandIcon} />}
                  </Box>
                  <Collapse in={expanded(field.type)} timeout="auto" unmountOnExit>
                    {
                      field.radioOptions.map(radio => (
                        <Box key={radio.value}>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                color="primary"
                                id={`lma-filter-checkbox-${radio.value}`}
                                name={`lma-filter-checkbox-${radio.value}`}
                                checked={filterValue[radio.value] || false}
                                onChange={handleChange(radio.value)}
                                value={radio.value}
                              />
                            )}
                            data-testid={`lma-filter-option-${radio.value}`}
                            id={`lma-filter-option-${radio.value}`}
                            name="lma-filter-option"
                            label={radio.radioName}
                            labelPlacement="start"
                            className={classes.formGroup}
                          />
                        </Box>
                      ))
                    }
                  </Collapse>
                </Box>
                <Divider className={classes.divider} />
              </Box>
            ))
          }
        </FormGroup>
      </FormControl>
    </>
  );
};

export default Filter;

Filter.propTypes = {
  typeExpanded: bool,
  setTypeExpanded: func,
  sourceExpanded: bool,
  setSourceExpanded: func,
  setDisable: func,
  filterValue: object,
  setFilter: func,
  setStatusExpanded: func,
  statusExpanded: bool,
};
