import React from 'react';
import {
  ResponsiveContainer, BarChart, Bar, Legend, CartesianGrid, Tooltip, XAxis, YAxis,
} from 'recharts';
import {
  arrayOf, object, number, string, shape, bool, array,
} from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  legendProps, tooltipProps, gridProps, xAxisProps, useYAxisProps,
} from './utils';

const useStyles = makeStyles(() => ({
  bars: {
    '@media (max-width: 360px)': {
      height: '310px',
    },
  },
  warp: {
    direction: 'ltr',
    '& > svg': {
      direction: styleProps => (styleProps.language === 'ar' ? 'rtl' : 'ltr'),
    },
    '& div > ul > li': {
      marginLeft: styleProps => (styleProps.language === 'ar' ? '10px' : '0px !important'),
      marginRight: styleProps => (styleProps.language === 'ar' ? '0px !important' : '10px'),
    },
    '& div > ul > li > svg': {
      marginLeft: styleProps => (styleProps.language === 'ar' ? '4px' : '0px !important'),
      marginRight: styleProps => (styleProps.language === 'ar' ? '0px !important' : '4px'),
    },
    '& [class*="recharts-tooltip-wrapper"]': {
      '@media (max-width: 345px)': {
        left: '-30px',
      },
      '@media (max-width: 320px)': {
        left: '-40px',
      },
    },
  },
}));

const AvonBarChart = ({
  data, dataKeys, height, margin, stack, loading,
  colors, activeAnimation = true, animationDuration, language,
}) => {
  const classes = useStyles({ language });
  const tipProps = tooltipProps();
  tipProps.language = language;
  const bars = dataKeys.map(
    (key, index) => (
      <Bar
        isAnimationActive={activeAnimation}
        animationDuration={animationDuration}
        key={index}
        dataKey={key}
        stackId={stack ? 0 : index}
        fill={colors[index]}
        className={classes.bars}
      />
    ),
  );

  return (
    <ResponsiveContainer height={Number(height)} width="100%">
      <BarChart data={data} margin={margin} barCategoryGap="25%" className={classes.warp}>
        {!loading && <Legend {...legendProps(language)} />}
        {!loading && <CartesianGrid {...gridProps()} />}
        {!loading && <Tooltip {...tipProps} />}
        <XAxis {...xAxisProps('horizontal', language)} hide={loading} />
        <YAxis {...useYAxisProps('horizontal', language)} hide={loading} domain={loading ? [0, 10] : [0, 'auto']} />
        {bars}
      </BarChart>
    </ResponsiveContainer>
  );
};

AvonBarChart.propTypes = {
  data: arrayOf(object).isRequired,
  dataKeys: arrayOf(string).isRequired,
  height: number,
  margin: shape({
    top: number, right: number, left: number, bottom: number,
  }),
  stack: bool,
  colors: array.isRequired,
  animationDuration: number,
  activeAnimation: bool,
  loading: bool,
  language: string,
};

AvonBarChart.defaultProps = {
  margin: {
    top: 40, right: 10, left: 10, bottom: 5,
  },
  stack: true,
  loading: false,
  animationDuration: 500,
};
export default AvonBarChart;
