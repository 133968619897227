import React, { useState, useReducer } from 'react';
import {
  func, arrayOf, string, shape, bool, oneOfType, number, exact,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import useTheme from '@material-ui/styles/useTheme';
import { Dropdown, Typography, Button } from '@avon/component-library';
import makeStyles from '@material-ui/styles/makeStyles';
import DateRange from '../../DateRange';

const useStyles = makeStyles(() => ({
  actions: {
    '& > * + *': {
      marginLeft: styleProps => (styleProps.language === 'ar' ? '0px' : '8px'),
      marginRight: styleProps => (styleProps.language === 'ar' ? '8px' : '0px'),
    },
  },
}));

const MobileDateSelector = ({
  options, onChange, onExit, onEnter, language, displayDate, labels, market,
}) => {
  const classes = useStyles({ language });
  const [selection, setSelection] = useState(0);
  const [placeholder, setPlaceholder] = useState(labels.wrapper);
  const [modalIndex, setModalIndex] = useState();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const updateOptionState = (state, { index, updateOptions }) => {
    const updatedState = [...state];
    updatedState[index] = { ...updatedState[index], ...updateOptions };
    return updatedState;
  };

  const [optionState, optionStateDispatch] = useReducer(updateOptionState, options);

  const tomorrow = new Date();
  tomorrow.setHours(24, 0, 0);

  let dateVerification;

  const handleVerificationDate = () => {
    if (selection !== 4) {
      return;
    }
    // eslint-disable-next-line
    if (new Date(optionState[selection].range.from) > new Date(optionState[selection].range.to)) {
      dateVerification = [undefined, labels.rangeError];
    }
    if (new Date(optionState[selection].range.to) > tomorrow) {
      // eslint-disable-next-line
      dateVerification = [dateVerification ? dateVerification[1] : undefined, labels.rangeScopeError];
    }
    // eslint-disable-next-line
    if (isNaN(optionState[selection].range.from)) {
      // eslint-disable-next-line
      dateVerification = [labels.invalidDateError, dateVerification ? dateVerification[1] : undefined];
    }
    // eslint-disable-next-line
    if (isNaN(optionState[selection].range.to)) {
      // eslint-disable-next-line
      dateVerification = [dateVerification ? dateVerification[0] : undefined, labels.invalidDateError];
    }
  };

  const handleChange = (index) => {
    const opt = optionState[index];
    if (opt.modalChild) {
      if (dateVerification) {
        return;
      }
      setSelection(undefined);
      setPlaceholder(`${displayDate(opt.range.from)} ${labels.dateConnector} ${displayDate(opt.range.to)}`);
    } else {
      setSelection(index);
      setPlaceholder(labels.wrapper);
    }
    setOpen(false);
    onChange(opt.range);
  };

  const openModal = (index) => {
    setOpen(true);
    setModalIndex(index);
    setSelection(index);
  };

  const closeModal = () => (setOpen(false));

  const selectOptions = optionState.map(({ label }, index) => ({ label, value: index }));

  handleVerificationDate();

  return (
    <>
      <Grid container spacing={theme.spacing(1)} data-testid="webDateSelector" id="admin-webDateSelector">
        <Grid item>
          <Typography variant="body2" weight="regular" color="primary">{labels.wrapper}</Typography>
          <Dropdown id="admin-webDateSelecto--1r" hideSelected fullWidth language={language} selectOptions={selectOptions} name="Name" placeholder={placeholder} defaultValue={selection} onChange={value => (value === 4 ? openModal(value) : handleChange(value))} />
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onEnter={onEnter}
        onExit={onExit}
        aria-labelledby="custom-date-range-selector"
        id="custom-date-range-selector"
      >
        {modalIndex
          ? (
            <>
              <DialogTitle id="custom-date-range-selector">{optionState[modalIndex].label}</DialogTitle>
              <DialogContent>
                <DateRange
                  onChange={dateRange => optionStateDispatch({
                    updateOptions: {
                      range: dateRange,
                    },
                    index: modalIndex,
                  })}
                  limitMax
                  limitMin
                  dateRange={optionState[modalIndex].range}
                  language={language}
                  market={market}
                  labels={{
                    from: labels.from,
                    to: labels.to,
                    cancel: labels.cancel,
                    confirm: labels.confirm,
                  }}
                  error={dateVerification}
                  disableFuture
                />

              </DialogContent>
            </>
          ) : null}
        <DialogActions className={classes.actions}>
          <Button
            name="stats-web-date-selector-close"
            id="stats-web-date-selector-close"
            onClick={closeModal}
            priority="secondary"
          >
            {labels.cancel}
          </Button>
          <Button
            name="stats-web-date-selector-confirm"
            id="stats-web-date-selector-confirm"
            onClick={() => handleChange(modalIndex)}
            priority="primary"
            autoFocus
          >
            {labels.confirm}
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

MobileDateSelector.propTypes = {
  options: arrayOf(shape({
    label: string.isRequired,
    range: oneOfType(
      [shape({
        from: number,
        to: number,
      }),
      shape({
        from: string,
        to: string,
      })],
    ),
    modalChild: bool,
  })),
  labels: exact({
    wrapper: string,
    confirm: string,
    cancel: string,
    to: string,
    from: string,
    dateConnector: string,
    rangeError: string,
    rangeScopeError: string,
  }).isRequired,
  language: string.isRequired,
  displayDate: func.isRequired,
  onChange: func.isRequired,
  onExit: func,
  onEnter: func,
  market: string,
};

export default MobileDateSelector;
