import dayjs from 'dayjs';
import en from 'dayjs/locale/en';
import ru from 'dayjs/locale/ru';
import es from 'dayjs/locale/es';
import uk from 'dayjs/locale/uk';
import tr from 'dayjs/locale/tr';
import sl from 'dayjs/locale/sl';
import zh from 'dayjs/locale/zh-cn';
import zhTW from 'dayjs/locale/zh-tw';
import zhHK from 'dayjs/locale/zh-hk';
import pt from 'dayjs/locale/pt';
import ar from 'dayjs/locale/ar';
import fr from 'dayjs/locale/fr';
import mk from 'dayjs/locale/mk';
import ro from 'dayjs/locale/ro';
import sr from 'dayjs/locale/sr';
import lv from 'dayjs/locale/lv';
import kk from 'dayjs/locale/kk';
import ky from 'dayjs/locale/ky';
import el from 'dayjs/locale/el';
import hu from 'dayjs/locale/hu';
import ka from 'dayjs/locale/ka';
import de from 'dayjs/locale/de';
import fi from 'dayjs/locale/fi';
import et from 'dayjs/locale/et';
import hr from 'dayjs/locale/hr';
import sq from 'dayjs/locale/sq';
import lt from 'dayjs/locale/lt';
import cs from 'dayjs/locale/cs';
import sk from 'dayjs/locale/sk';
import pl from 'dayjs/locale/pl';
import bs from 'dayjs/locale/bs';
import bg from 'dayjs/locale/bg';
import it from 'dayjs/locale/it';

const localeMap = {
  en,
  es,
  ru,
  tr,
  uk,
  sl,
  zh,
  zhTW,
  zhHK,
  pt,
  ar,
  fr,
  mk,
  ro,
  sr,
  lv,
  kk,
  ky,
  el,
  hu,
  ka,
  de,
  fi,
  et,
  hr,
  sq,
  lt,
  cs,
  sk,
  pl,
  bs,
  bg,
  it,
};

export default (date = new Date(), language = 'en', format = 'DD MMMM YYYY') => dayjs(date)
  .locale(localeMap[language] || en)
  .format(format);
