/* eslint-disable import/no-unresolved */
import React from 'react';
import {
  bool, string, func, exact, node,
} from 'prop-types';
import {
  makeStyles, Dialog, DialogActions, AppBar, Toolbar, Slide, DialogContent,
} from '@material-ui/core';
import { Typography } from '@avon/component-library';
import BarButton from './BarButton';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.colors.lightZircon,
    boxShadow: 'none',
  },
  toolbar: { justifyContent: 'center' },
  actionButton: {
    borderRadius: 0,
    margin: 0,

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  actions: {
    display: 'block',
    width: '100%',
    backgroundColor: theme.palette.colors.lightZircon,
    boxShadow: '0 -2px 4px 0 rgba(225, 225, 225, 0.5)',
  },
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const AvonDialog = ({
  open, onExit, onEnter, handleClose, labels, contents,
}) => {
  const classes = useStyles();
  return (
    <Dialog data-testid="modal-window" id="modal-window" fullScreen open={open} onExit={onExit} onEnter={onEnter} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="body2" weight="bold" color="textPrimary">
            {labels.wrapper}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {contents}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <BarButton data-testid="selector-confirm" id="selector-confirm" name="selector-confirm" priority="tertiary" onClick={handleClose} className={classes.actionButton}>{labels.close}</BarButton>
      </DialogActions>
    </Dialog>
  );
};

AvonDialog.propTypes = {
  labels: exact({
    wrapper: string,
    close: string,
  }).isRequired,
  onExit: func,
  onEnter: func,
  handleClose: func.isRequired,
  open: bool.isRequired,
  contents: node.isRequired,
};

export default AvonDialog;
