export const setItem = (key, value) => sessionStorage.setItem(key, JSON.stringify(value));

export const getItem = (key) => {
  try {
    const ret = JSON.parse(sessionStorage.getItem(key));
    return ret;
  } catch (e) {
    return undefined;
  }
};

export const getStringItem = (key) => {
  const ret = sessionStorage.getItem(key);
  return ret || undefined;
};

export const clear = () => sessionStorage.clear();
