import React, { useContext } from 'react';
import { Typography } from '@avon/component-library';
import { array, string } from 'prop-types';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import { makeStyles } from '@material-ui/styles';
import {
  List, Grid, ListItem, ListItemText,
} from '@material-ui/core';
import LmaContext from '../../LmaContext';
import formatDate from '../../../utils/formatDate';
import { isMobile } from '../../../utils/getDeviceType';


const useStyles = makeStyles(theme => ({
  list: {
    boxShadow: 'none',
  },
  divider: {
    margin: '0 -24px',
  },
  inline: {
    color: theme.palette.colors.textLight,
    fontSize: '14px',
  },
  listItem: {
    lineHeight: '22px',
    padding: '20px 0',
    textAlign: styleProps => (styleProps.language === 'ar' ? 'right' : 'left'),
    wordBreak: 'break-word',
  },
}));


const ViewNotes = ({ notes, dateFormat }) => {
  const isWebApp = sessionStorage.getItem('isWebApp') && !isMobile();
  const [{ language }] = useContext(LmaContext);
  const classes = useStyles({ language });
  return (

    <List className={classes.list}>
      {notes.map(leadNotes => (
        <Box key={leadNotes.createTime}>
          {
            isWebApp ? (
              <ListItem style={{
                minHeight: 60,
                fontFamily: 'Montserrat',
                fontSize: 16,
                color: '#000000',
                border: '1px solid #E4EDED',
                borderRadius: 6,
                padding: '0 24px',
                marginBottom: 10,
                wordBreak: 'break-word',
              }}
              >
                <Grid container>
                  <Grid item sm={9} xs={12} style={{ wordBreak: 'break-word', paddingRight: '10px' }}>
                    {leadNotes.note}
                  </Grid>
                  <Grid item sm={3} xs={12} style={{ color: '#A2ACAE' }}>
                    {formatDate(leadNotes.createTime, language, dateFormat)}
                  </Grid>
                </Grid>
              </ListItem>
            ) : (
              <>
                <ListItem className={classes.listItem}>
                  <ListItemText
                    primary={(
                      <React.Fragment>
                        <Typography
                          component="span"
                          color="textPrimary"
                          weight="regular"
                        >
                          {leadNotes.note}
                        </Typography>
                        <Box className={classes.inline}>
                          <Typography
                            weight="regular"
                          >
                            <span style={{ color: '#a2acae' }}>
                              {formatDate(leadNotes.createTime, language, dateFormat)}
                            </span>
                          </Typography>
                        </Box>
                      </React.Fragment>
                    )}
                  />
                </ListItem>
                <Divider className={classes.divider} component="li" />
              </>
            )
          }
        </Box>
      ))}
    </List>

  );
};

ViewNotes.propTypes = {
  dateFormat: string,
  notes: array,
};


export default ViewNotes;
