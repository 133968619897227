import React, { useState, useContext, useEffect } from 'react';
import { Box, Fade, Checkbox } from '@material-ui/core';
import { makeStyles, withStyles, useTheme } from '@material-ui/styles';
import {
  bool,
  func, number, object, string,
} from 'prop-types';
import {
  CheckCircle, RadioButtonUnchecked, CloseOutlined, CheckBoxOutlineBlank, CheckBox,
} from '@material-ui/icons';
import { Button as AvonButton } from '@avon/component-library';
import dropdownIcon from '../assets/dropdown.svg';
import LmaContext from '../../../LmaContext';
import { isTablet } from '../../../../utils/getDeviceType';

const CustomerCheckbox = withStyles(theme => ({
  root: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    marginRight: 0,
    '&$checked': {
      color: theme.palette.colors.corePink,
      padding: 0,
      marginRight: 0,
    },
  },
  checked: {},
}))(props => <Checkbox icon={<CheckBoxOutlineBlank />} checkedIcon={<CheckBox />} {...props} />);

const CustomerRadio = withStyles(theme => ({
  root: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    marginRight: 0,
    '&$checked': {
      color: theme.palette.colors.corePink,
      padding: 0,
      marginRight: 0,
    },
  },
  checked: {},
}))(props => <Checkbox icon={<RadioButtonUnchecked />} checkedIcon={<CheckCircle />} {...props} />);


const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    height: '100%',
    padding: '0 12px',
    flex: 1,
    cursor: 'pointer',
    border: '1px solid #E4EDED',
    borderRadius: 4,
  },
  contentWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },
  content: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Montserrat',
    fontSize: 14,
    lineHeight: '17px',
    color: '#181818',
  },
  dropdownContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 99,
    width: '100%',
    padding: e => (e.type === 'sort' ? '0 6px 6px' : 0),
    background: '#ffffff',
    boxShadow: '0px 2px 4px #E1E1E1',
    borderRadius: 4,
    borderTop: 'none',
    fontFamily: 'Montserrat',
    fontSize: 14,
    lineHeight: '17px',
    color: '#181818',
  },
  placeholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'Montserrat',
    fontWeight: 700,
    color: theme.palette.colors.corePink,
    borderBottom: '1px solid #E4EDED',
    cursor: 'pointer',
    marginBottom: e => (e.type === 'sort' ? 18 : 4),
    padding: e => (e.type === 'sort' ? '0 6px' : '0 12px'),
    '& img': {
      transform: 'rotate(180deg)',
    },
  },
  dropdownItem: {
    display: 'flex',
    alignItems: 'center',
    height: 34,
    padding: '0 6px',
    '&:hover': {
      background: '#F6F9F9',
    },
  },
  filterDropdownItem: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    color: '#181818',
    cursor: 'default',
  },
}));

const Select = ({
  height,
  placeholder,
  type,
  page,
  defaultValue,
  sortValue,
  setSort,
  filterValue,
  setFilter,
  collapsePanel,
  setCollapsePanel,
  onClear,
  setBadgeCount,
}) => {
  const [{
    apiBody,
    leadsContent: { sortFilter: { sortFilterTabInfo: { sort, filter }, button } },
    language,
  }, dispatch] = useContext(LmaContext);

  const classes = useStyles({ type, language });

  const [isCollapse, setIsCollapse] = useState(false);
  const [checkedValue, setCheckedValue] = useState({});
  const [fileterHeight, setFileterHeight] = useState(150);
  const theme = useTheme();

  useEffect(() => {
    if (window.screen.width <= 1280) {
      setFileterHeight(150);
    } else if (window.screen.width <= 1366) {
      setFileterHeight(260);
    } else if (window.screen.width <= 1440) {
      setFileterHeight(360);
    } else {
      setFileterHeight(420);
    }
  }, []);

  useEffect(() => {
    if (!sortValue) {
      setCheckedValue({});
    }
  }, [sortValue]);

  const apply = (sortName = sortValue, filterObject = filterValue) => {
    setSort(sortName);
    setFilter({ ...filterObject });
    const keys = Object.keys(filterObject);
    const values = keys.filter(key => filterObject[key]);
    const source = values.filter(value => !!value.match(/asa|mra|cca|web|prp|Get a call Back/i));
    const typeTemp = values.filter(value => !!value.match(/agl|fgl/i));
    const status = values.filter(value => !!value.match(/in_progress|new|awaiting_acceptance|pending/i));

    const leadType = typeTemp.length ? typeTemp : undefined;
    const leadSource = source.length ? source : undefined;
    const statusType = status.length ? status : undefined;

    const { pagination: { pageSize } } = apiBody;
    setBadgeCount(Object.values(filterValue).filter(item => item).length);
    dispatch({
      filters: { ...filterObject },
      sort: sortName,
      sortOrFilter: true,
      apiBody: {
        ...apiBody,
        pagination: { pageSize, pageIndex: 0 },
        sortName,
        filters: {
          'Lead Type': leadType,
          'Lead Source': leadSource,
          Status: statusType,
        },
      },
    });
    if (setCollapsePanel) {
      setCollapsePanel(false);
    } else {
      setIsCollapse(false);
    }
  };

  const handleClear = () => {
    setBadgeCount(0);
    onClear();
  };

  const handleClickItem = (o, t) => {
    if (page === 'repCard') {
      if (sortValue === o.value) {
        return;
      }
      setSort(o.value);
      if (t === 'desktop') {
        apply(o.value, filterValue);
      }
      setCheckedValue(o);
    }
    setIsCollapse(false);
  };

  const toggleShow = () => {
    setIsCollapse(false);
  };

  useEffect(() => {
    document.addEventListener('click', toggleShow, false);

    return () => {
      document.removeEventListener('click', toggleShow);
    };
  });

  const buttonGroup = () => (
    <Box style={isTablet() ? {
      display: 'flex',
    } : {
      display: 'flex',
      padding: '12px 0',
      // boxShadow: '0px 2px 24px #e1e1e1',
    }}
    >
      <AvonButton
        priority="tertiary"
        onClick={() => {
          setIsCollapse(false);
          handleClear();
        }}
        disabled={isTablet()
          ? (!sortValue && Object.values(filterValue).filter(item => item).length === 0)
          : Object.values(filterValue).filter(item => item).length === 0
        }
      >
        {button.clear}
      </AvonButton>
      <AvonButton
        priority="secondary"
        onClick={() => apply()}
        disabled={isTablet()
          ? (!sortValue && Object.values(filterValue).filter(item => item).length === 0)
          : Object.values(filterValue).filter(item => item).length === 0
        }
      >
        {button.apply}
      </AvonButton>
    </Box>
  );

  return (
    <>
      {' '}
      {
        isTablet() ? (
          <Box style={{
            position: 'absolute',
            right: -4,
            top: -10,
            zIndex: 100,
            padding: '24px 12px 15px',
            height: 526,
            overflow: 'auto',
            minWidth: 320,
            boxShadow: '0px 2px 4px #E1E1E1',
            borderRadius: 8,
            background: '#ffffff',
            display: collapsePanel ? 'unset' : 'none',
          }}
          >
            <Box style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              lineHeight: '28px',
              paddingBottom: 25,
            }}
            >
              <Box style={{
                fontFamily: 'Montserrat',
                fontWeight: 700,
                fontSize: 20,
                lineHeight: '28px',
                color: '#181818',
              }}
              >
                {`${sort.title} & ${filter.title}`}
              </Box>
              <Box onClick={() => setCollapsePanel(false)}>
                <CloseOutlined />
              </Box>
            </Box>
            <Box style={{
              height: 'calc(100% - 60px)',
              overflow: 'auto',
              paddingBottom: 12,
              paddingRight: 6,
            }}
            >
              {
                sort.sortOptions.map(item => (
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      lineHeight: '44px',
                      padding: '0 6px',
                      fontFamily: 'Montserrat',
                      fontSize: 16,
                    }}
                    bgcolor={sortValue === item.value ? '#F6F9F9' : 'unset'}
                    key={`sort-condition-item-key-${item.radioName}`}
                  >
                    <Box>{item.radioName}</Box>
                    <CustomerRadio
                      checked={sortValue === item.value}
                      onChange={() => {
                        if (page === 'repCard') {
                          handleClickItem(item, 'tablet');
                        }
                      }}
                    />
                  </Box>
                ))
              }
              <Box style={{
                marginTop: 12,
                fontFamily: 'Montserrat',
                fontSize: 16,
                lineHeight: '38px',
                color: theme.palette.colors.corePink,
              }}
              >
                {filter.title}
              </Box>
              {
                filter.filterOptions.map(({ title, type: copyType, radioOptions }) => (
                  <Box
                    className={classes.filterDropdownItem}
                    key={`filter-condition-item-key-${copyType}`}
                  >
                    <Box fontFamily="Montserrat" fontWeight={700} mt={0.5} mb={0.5} height={44} lineHeight="44px">{title}</Box>
                    {
                      radioOptions.map(({ value, radioName }) => (
                        <Box
                          key={`filter-condition-item-lit-item-key-${value}`}
                          height={44}
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          px="6px"
                        >
                          <Box>{radioName}</Box>
                          <CustomerCheckbox
                            checked={filterValue[value] || false}
                            onChange={() => {
                              if (page === 'repCard') {
                                setFilter({ ...filterValue, [value]: !filterValue[value] });
                              }
                            }}
                          />
                        </Box>
                      ))
                    }
                  </Box>
                ))
              }
              {buttonGroup()}
            </Box>
          </Box>
        ) : (
          <Box className={classes.container}>
            <Box
              className={classes.contentWrapper}
              onClick={() => {
                setIsCollapse(true);
              }}
            >
              <Box className={classes.content}>
                <Box>{defaultValue}</Box>
                {
                  type === 'sort' && (
                    <Box fontFamily="Montserrat" fontWeight={700} ml={1.5}>{checkedValue.radioName}</Box>
                  )
                }
              </Box>
              <Box width={16} height={16}>
                <img src={dropdownIcon} alt="dropdown-icon" />
              </Box>
            </Box>
            <Fade in={isCollapse}>
              <Box className={classes.dropdownContainer}>
                <Box
                  className={classes.placeholder}
                  height={height}
                  onClick={() => setIsCollapse(false)}
                >
                  {placeholder}
                  <img src={dropdownIcon} alt="dropdown-icon" />
                </Box>
                {
                  type === 'sort'
                    ? (
                      <>
                        {
                        sort.sortOptions.map(item => (
                          <Box
                            className={classes.dropdownItem}
                            bgcolor={sortValue === item.value ? '#F6F9F9' : 'unset'}
                            key={`sort-condition-item-key-${item.radioName}`}
                            onClick={() => handleClickItem(item, 'desktop')}
                          >
                            {item.radioName}
                          </Box>
                        ))
                      }
                      </>
                    )
                    : (
                      <>
                        <Box height={fileterHeight} overflow="auto" pl={3} pr={3}>
                          {
                        filter.filterOptions.map(({ title, type: copyType, radioOptions }) => (
                          <Box
                            className={classes.filterDropdownItem}
                            key={`filter-condition-item-key-${copyType}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.nativeEvent.stopImmediatePropagation();
                            }}
                          >
                            <Box fontFamily="Montserrat" fontWeight={700} mt={0.5} mb={0.5} height={44} lineHeight="44px">{title}</Box>
                            {
                              radioOptions.map(({ value, radioName }) => (
                                <Box
                                  key={`filter-condition-item-lit-item-key-${value}`}
                                  height={44}
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Box>{radioName}</Box>
                                  <CustomerCheckbox
                                    checked={filterValue[value] || false}
                                    onChange={() => {
                                      if (page === 'repCard') {
                                        setFilter({ ...filterValue, [value]: !filterValue[value] });
                                      }
                                    }}
                                  />
                                </Box>
                              ))
                            }
                          </Box>
                        ))
                      }
                          {buttonGroup()}
                        </Box>
                      </>
                    )
                }
              </Box>
            </Fade>
          </Box>
        )
      }
    </>
  );
};

Select.propTypes = {
  height: number,
  placeholder: string,
  type: string,
  page: string,
  defaultValue: string,
  setSort: func,
  sortValue: string,
  filterValue: object,
  setFilter: func,
  setCollapsePanel: func,
  collapsePanel: bool,
  onClear: func,
  setBadgeCount: func,
};

Select.defaultProps = {
  filterValue: {},
};

export default Select;
