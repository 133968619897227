

const setApptId = (state, action) => {
  if (action.apptId === 0) {
    return action.apptId;
  }
  return action.apptId || state;
};

export default setApptId;
