import React, {
  useContext, useEffect, useState, useRef,
} from 'react';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';
import {
  array, object, func, number,
} from 'prop-types';
import { Alert } from '@avon/component-library';
import LmaContext from '../../LmaContext';
import RepCard from '../RepCard';
import { isMobile } from '../../../utils/getDeviceType';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));


const ListComponent = ({
  items, loadMore, inputRef,
}) => {
  const isWebApp = sessionStorage.getItem('isWebApp') && !isMobile();
  const [height, setHeight] = useState(window.innerHeight);
  const [fontLoaded, setLoaded] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [rowSizes, setRowSizes] = useState(
    new Array(items.length).fill(true).reduce((acc, item, i) => {
      acc[i] = 185;
      return acc;
    }, {}),
  );
  const classes = useStyles();
  const listRef = useRef(true);
  const wrapRef = useRef();

  useEffect(() => {
    document.fonts.ready.then(() => {
      setLoaded(true);
    });
    const handleResize = () => {
      setHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => { window.removeEventListener('resize', handleResize); };
  }, []);

  useEffect(() => {
    if (wrapRef.current && fontLoaded && !isWebApp) {
      const rowSizeTemp = Object.assign({}, rowSizes);
      const cardEle = wrapRef.current.querySelectorAll('div[id^="lma-card-"]');
      if (cardEle.length > 0) {
        // eslint-disable-next-line no-plusplus
        for (let x = 0; x < cardEle.length; x++) {
          listRef.current._listRef.resetAfterIndex(x + startIndex);
          rowSizeTemp[x + startIndex] = cardEle[x].children[0].clientHeight;
        }
      }
      setRowSizes(rowSizeTemp);
    }
  }, [items, fontLoaded, startIndex]);

  const Row = ({ index, style }) => {
    const [fadeGrey, setFadeGrey] = useState(false);
    const [{ editAppointment }, dispatch] = useContext(LmaContext);
    const record = items[index];

    /* istanbul ignore next */
    if (!(record)) {
      return (<Box />);
    }

    const handleRedirect = (appt) => {
      window.parent.postMessage('showBackBtn', '*');
      if (appt.sl) {
        if (appt.sl.acceptLegalCert) {
          dispatch({ apptId: appt.apptId, page: 'profile' });
        } else {
          editAppointment(appt.apptId, 'acceptLegalCert');
        }
      } else {
        dispatch({ apptId: appt.apptId, page: 'profile' });
      }
    };

    return (
      <>
        {
          record.rep.contactDetails
          && (
            <Box
              id={`lma-card-${record.apptId}`}
              onClick={fadeGrey ? () => { } : () => handleRedirect(record)}
              style={isWebApp ? {
                marginBottom: 6,
              } : style}
            >
              <RepCard
                {...record}
                setFadeGrey={setFadeGrey}
              />
            </Box>
          )
        }
      </>
    );
  };

  Row.propTypes = {
    index: number,
    style: object,
  };
  const [{
    pageCount, hasNextPage, leadsContent: {
      noResultsHeading,
      noResultsBody,
    },
  }] = useContext(LmaContext);
  const itemCount = hasNextPage && !isWebApp ? items.length + 10 : items.length;

  // eslint-disable-next-line
  const onListScroll = (e) => {
    inputRef.current.blur();
  };

  const getSize = i => rowSizes[i];

  const afterRenderd = (onItemsRendered, data) => {
    onItemsRendered(data);
    setStartIndex(data.overscanStartIndex);
  };

  return (
    <>
      {!pageCount ? (
        <Box ref={wrapRef}>
          {isWebApp ? items.map((item, index) => <Row index={index} />)
            : (
              <InfiniteLoader
                isItemLoaded={index => index < items.length}
                itemCount={itemCount}
                loadMoreItems={loadMore}
                ref={listRef}
              >
                {({ onItemsRendered, ref }) => (
                  <VariableSizeList
                    height={isWebApp ? height : height - 145}
                    width="100%"
                    itemCount={itemCount}
                    itemSize={i => getSize(i)}
                    overscanCount={5}
                    onItemsRendered={data => afterRenderd(onItemsRendered, data)}
                    // onScroll={onListScroll}
                    ref={ref}
                    data
                  >
                    {Row}
                  </VariableSizeList>
                )}
              </InfiniteLoader>
            )}
        </Box>
      ) : (
        <Box height={window.innerHeight - 125}>
          <Box className={classes.container}>
            <Alert
              heading={noResultsHeading}
              body={noResultsBody}
              iconType="cross"
            />
          </Box>
        </Box>
      )
      }
    </>
  );
};

ListComponent.propTypes = {
  items: array,
  loadMore: func,
  inputRef: object,
};


export default ListComponent;
