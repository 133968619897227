/* eslint-disable import/no-unresolved */
import React, { useState, useMemo } from 'react';
import {
  string, func, shape, exact, object, array, oneOfType,
} from 'prop-types';
import { makeStyles, Box } from '@material-ui/core';
import { Checkbox as AvonCheckbox, AvonContainer, Typography } from '@avon/component-library';
import Dialog from './Dialog';

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,
    fontFamily: 'Montserrat',
    fontWeight: 700,
  },
}));

const Checkbox = ({
  field: { name },
  form: {
    errors, touched, values, setFieldValue,
  },
  language,
  label, dialog, ...props
}) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  const closeDialog = () => setDialogOpen(false);

  const openDialog = (event) => {
    event.preventDefault();
    setDialogOpen(true);
  };

  const displayLabel = useMemo(() => {
    if (label[1] && dialog && Array.isArray(label)) {
      const link = (
        <Box
          component="span"
          key="privacyPolicyLink"
          className={classes.link}
          onClick={openDialog}
          data-testid="checkbox-link"
        >
          {label[1]}
        </Box>
      );
      return <Typography variant="body1">{[label[0], link, label[2]]}</Typography>;
    }
    return label;
  }, [label]);

  const dialogContents = useMemo(() => {
    if (dialog && label[1]) {
      const { labels: { wrapper, close }, contents } = dialog;
      const { aPP: { tcText: { tcText: aPP } } } = contents;

      const dialogOptions = (
        <Box py={7} component={AvonContainer}>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: aPP }} />
        </Box>
      );

      return {
        labels: { wrapper, close },
        contents: dialogOptions,
      };
    }
    return false;
  }, [dialog]);

  const handleChange = () => {
    setFieldValue(name, !values[name]);
  };

  return (
    (values[name] !== undefined) && (
      <>
        <AvonCheckbox
          label={displayLabel}
          name={name}
          onChange={handleChange}
          error={touched[name] && errors[name]}
          checked={values[name] || false}
          value={!values[name]}
          language={language}
          {...props}
        />
        {!!dialogContents && (
          <Dialog
            open={dialogOpen}
            handleClose={closeDialog}
            {...dialogContents}
          />
        )}
      </>
    )
  );
};

Checkbox.propTypes = {
  label: oneOfType([array, string]).isRequired,
  dialog: exact({
    labels: exact({
      wrapper: string,
      close: string,
    }).isRequired,
    contents: shape({
      aPP: shape({
        tcText: shape({
          shape: shape({
            tcText: string.isRequired,
            tcType: string.isRequired,
            tcVersion: string.isRequired,
          }),
        }),
      }),
    }),
  }),
  field: shape({
    name: string.isRequired,
    onChange: func.isRequired,
  }).isRequired,
  form: shape({
    errors: object.isRequired,
    touched: object.isRequired,
  }).isRequired,
  language: string,
};

export default Checkbox;
